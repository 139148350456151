/**
 * Adding new sides here will automatically add new cells into UploadRevisionModal on the Manage Products Page.
 * It will also add new side options into ML Training Product Sides.
 */
export const RevisionSides: { [key: string]: RevisionSide } = {
  primary: {
    label: "Primary",
    value: 0,
    mask: 1,
  },
  top: {
    label: "Top",
    value: 3,
    mask: null,
  },
  bottom: {
    label: "Bottom",
    value: 9,
    mask: null,
  },
  left: {
    label: "Left",
    value: 2,
    mask: null,
  },
  right: {
    label: "Right",
    value: 8,
    mask: null,
  },
  back: {
    label: "Back",
    value: 7,
    mask: null,
  },
};

interface RevisionSide {
  /**
   * Label used when presenting the name of the side in UI
   */
  label: string;
  /**
   * Value of the side used when dealing with APIs, etc.
   */
  value: number;
  /**
   * (Optional) Value of the side when shown in UI. This will not affect endpoints.
   */
  mask: number | null;
}

/** Extracts the right label for a given side number if it exists. */
export function GetLabelBySideNumber(value: number): string {
  for (const key in RevisionSides) {
    if (RevisionSides[key].value === value) {
      return RevisionSides[key].label;
    }
  }

  // Fallback for older/incorrectly configured sides.
  return "Unknown/Legacy";
}

/**
 * Generates nice user-readable labels that include the side number and possible `mask` value.
 */
export const GetRevisionSideLabels = () => {
  let labels: string[] = [];

  for (const key in RevisionSides) {
    labels.push(
      `${RevisionSides[key].label} (Side ${
        RevisionSides[key].mask ?? RevisionSides[key].value
      })`
    );
  }

  return labels;
};

/**
 * Generates possible sides that can be assigned as values. Do not use `side.mask`!
 */
export const GetRevisionSideValues = () => {
  let sideValues: number[] = [];

  for (const key in RevisionSides) {
    sideValues.push(RevisionSides[key].value);
  }

  return sideValues;
};
