import {
  Box,
  Button,
  Fade,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Switch,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import getAdminProductImage from "../../../api/planograms/getAdminProductImages";
import postAdminAddProductImage from "../../../api/planograms/postAdminAddProductImage";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { ColDef } from "ag-grid-community";
import { LoadingButton } from "@mui/lab";
import { ListProduct } from "../../../api/products/getProducts";
import { ProductImg } from "../../products/components/ProductImg";
import {
  AddCircleOutline,
  CheckOutlined,
  CloseOutlined,
  OpenInBrowserOutlined,
  Photo,
  RemoveCircleOutline,
} from "@mui/icons-material";
import getAdminProductTrainingImages, {
  TrainingImage,
} from "../../../api/products/getAdminProductTrainingImages";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import getProductEmbeddings, {
  ProductEmbedding,
} from "../../../api/products/getProductEmbeddings";
import { useSnackbar } from "notistack";
import postCreateEmbedding from "../../../api/products/postCreateEmbedding";
import postDeleteProductEmbedding from "../../../api/products/postDeleteEmbedding";
import { ProductImage } from "../planograms/types";
import { updateProductTrainingModel } from "../../../api/planograms/product-comparison/updateProductTrainingModel";
import UploadImageRevisionModal from "./components/UploadImageRevisionModal";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import ViewImagesModal from "./components/ViewImagesModal";
import { RevisionSides } from "./RevisionSideUtils";
import { urStorePalette } from "../../../themes/urStoreTheme";
import ProductSideSelector from "./components/ProductSideSelector";

interface ProductEmbeddingColumn extends ProductEmbedding {
  Actions?: null;
}

interface ProductImageColumn extends ProductImage {
  Active?: boolean;
  Image?: null;
  Actions?: null;
  NumberOfImages?: number;
}

interface TrainingImageColumn extends TrainingImage {
  Actions?: null;
}

interface TransformedImageObject {
  ProductId: string;
  RevisionId: string;
  sides: number[];
  GroupCreatedAt: Date;
}

const AdminProductEditPage = () => {
  // Util
  const navigate = useNavigate();
  const location = useLocation();

  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { setSubRoute } = useLayoutStore();

  // Constants
  const PRODUCT_IMAGE_HEIGHT = 108;
  const ROW_WITH_IMAGE_HEIGHT = PRODUCT_IMAGE_HEIGHT + 14;

  // State
  const [productID, setProductID] = useState<string | null>(null);
  const [navigatedPlanogram, setNavigatedPlanogram] = useState<string | null>(
    null
  );
  const [localUpc, setLocalUpc] = useState<string | null>(null);
  const [uploadImage, setUploadImage] = useState<any | null>(null);
  const [pageError, setPageError] = useState<string>("");
  const [params, setParams] = useSearchParams();
  const [tab, setTab] = useState<string>("1");
  const [addImageDialogOpen, setAddImageDialogOpen] = useState<boolean>(false);
  const [isViewImagesModalOpen, setIsViewImagesModalOpen] =
    useState<boolean>(false);
  const [uploadProcessing, setUploadProcessing] = useState<boolean>(false);
  const [revisionIdToEdit, setRevisionIdToEdit] = useState<string | null>(null);
  const [viewRevisionId, setViewRevisionId] = useState<string | null>(null);

  // Product State
  const [productImages, setProductImages] = useState<ProductImage[]>();
  const [transformedImageData, setTransformedData] = useState<
    TransformedImageObject[]
  >([]);
  const [loadingProductImages, setLoadingProductImages] =
    useState<boolean>(true);
  const [sides, setSides] = useState<number[]>([]);

  // Embedding State/Ref
  const embeddingRef = useRef<HTMLInputElement | null>(null);
  const [embeddings, setEmbeddings] = useState<ProductEmbedding[]>([]);
  const [embeddingsLoading, setEmbeddingsLoading] = useState<boolean>(false);
  const [embeddingUploadProcessing, setEmbeddingUploadProcessing] =
    useState<boolean>(false);
  const [embeddingDeleteProcessing, setEmbeddingDeleteProcessing] =
    useState<boolean>(false);

  // Training Image State
  const [trainingImages, setTrainingImages] = useState<TrainingImage[]>([]);
  const [trainingImagesLoading, setTrainingImagesLoading] =
    useState<boolean>(false);

  // Grid Columns
  const [imageColDefs, setImageColDefs] = useState<ColDef<any>[]>([
    {
      field: "Image",
      autoHeight: true,
      flex: 0.5,
      resizable: false,
      cellRenderer: (row: any) => {
        const rs: ListProduct = row.data;
        return (
          <ProductImg
            productId={rs.ProductId}
            revisionId={rs.RevisionId}
            defaultSize={PRODUCT_IMAGE_HEIGHT}
            growSize={PRODUCT_IMAGE_HEIGHT}
            sideNumber={row.data.sides.sort((a: number, b: number) => a - b)[0]}
            clickable
          />
        );
      },
    },
    { field: "RevisionId", flex: 1 },
    {
      field: "sides",
      headerName: "No. of Images",
      flex: 0.5,
      cellRenderer: (row: any) => row.data.sides.length,
    },
    {
      field: "CreatedAt",
      flex: 1,
      sort: "desc",
      cellRenderer: (_data: any) =>
        formatDisplayDate(_data.data.GroupCreatedAt),
    },
    {
      field: "Active",
      flex: 0.5,
      cellRenderer: (_data: any) =>
        _data.rowIndex === 0 ? (
          <CheckOutlined color="success" />
        ) : (
          <CloseOutlined />
        ),
    },
    {
      field: "Actions",
      flex: 2,
      cellRenderer: (_data: any) =>
        _data.rowIndex === 0 ? (
          <Stack direction="row" gap={2}>
            <Tooltip
              title={
                _data.data.sides.length === 5
                  ? "This revision already has 5 images."
                  : ""
              }
            >
              <span>
                <Button
                  startIcon={<Photo />}
                  variant="outlined"
                  disabled={uploadProcessing || _data.data.sides.length === 5}
                  onClick={async () => {
                    setRevisionIdToEdit(_data.data.RevisionId);
                    setAddImageDialogOpen(true);
                    setSides(_data.data.sides);
                  }}
                  size="small"
                >
                  Add Image to Revision
                </Button>
              </span>
            </Tooltip>
            <Tooltip title="View images">
              <span>
                <Button
                  size="small"
                  onClick={async () => {
                    setIsViewImagesModalOpen(true);
                    setViewRevisionId(_data.data.RevisionId);
                    setSides(_data.data.sides);
                  }}
                  variant="outlined"
                  startIcon={<OpenInBrowserOutlined />}
                >
                  Open
                </Button>
              </span>
            </Tooltip>
          </Stack>
        ) : null,
    },
  ]);

  const [embeddingColDefs] = useState<ColDef<ProductEmbeddingColumn>[]>([
    {
      field: "ProductEmbeddingId",
    },
    {
      field: "Tag",
    },
    {
      field: "CreatedAt",
      cellRenderer: (_data: any) => formatDisplayDate(_data.value),
    },
    {
      field: "Actions",
      cellRenderer: (_data: any) => (
        <Button
          color="error"
          startIcon={<CloseOutlined />}
          variant="outlined"
          disabled={embeddingDeleteProcessing}
          onClick={async () =>
            await deleteEmbedding(_data.data.ProductEmbeddingId)
          }
          size="small"
        >
          Delete
        </Button>
      ),
    },
  ]);

  useEffect(() => {
    let productID = params.get("product");
    if (productID) {
      setProductID(productID);
    } else {
      navigate("../admin/products");
    }

    let fromPlanogram = params.get("fromPlanogram");
    if (fromPlanogram) {
      setNavigatedPlanogram(fromPlanogram);
    } else {
      setNavigatedPlanogram(null);
    }
  }, []);

  useEffect(() => {
    switch (tab) {
      case "1":
        fetchAdminProductImages();
        break;
      case "2":
        fetchAdminTrainingImages();
        break;
      case "3":
        fetchEmbeddings();
        break;
    }
  }, [productID, tab]);

  useEffect(() => {
    // Set errorMessage only if text is equal or bigger than MAX_LENGTH
    if (
      uploadImage !== null &&
      uploadImage !== undefined &&
      !uploadImage.type.startsWith("image/")
    ) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "You must submit an image.",
      });
    }
  }, [uploadImage]);

  useEffect(() => {
    if (productImages) {
      const groupedByRevisionId: Record<string, ProductImage[]> = {};

      // Group objects by ProductId and RevisionId
      productImages.forEach((obj) => {
        const { ProductId, RevisionId } = obj;
        const key = `${ProductId}_${RevisionId}`;
        if (!groupedByRevisionId[key]) {
          groupedByRevisionId[key] = [];
        }
        groupedByRevisionId[key].push(obj);
      });

      const transformedObjects = Object.values(groupedByRevisionId).map(
        (group) => {
          const uniqueSideNumbers = Array.from(
            new Set(group.map((obj) => obj.SideNumber))
          );
          return {
            ProductId: group[0].ProductId,
            RevisionId: group[0].RevisionId,
            sides: uniqueSideNumbers,
            GroupCreatedAt: new Date(group[0].CreatedAt),
          };
        }
      );
      setTransformedData(transformedObjects);
    }
  }, [productImages]);

  const fetchEmbeddings = async () => {
    try {
      setEmbeddingsLoading(true);
      const resp = await getProductEmbeddings(params.get("product") ?? "");
      setEmbeddings(resp);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load Embeddings.",
      });
    } finally {
      setEmbeddingsLoading(false);
    }
  };

  const deleteEmbedding = async (embeddingId: string) => {
    try {
      setEmbeddingDeleteProcessing(true);
      await postDeleteProductEmbedding(embeddingId);
      setEmbeddingDeleteProcessing(false);
      enqueueSnackbar("Success", {
        variant: "success",
        cta: "Deleted Successfully.",
      });
      await fetchEmbeddings();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Embedding could not be deleted.",
      });
    } finally {
      setEmbeddingDeleteProcessing(false);
    }
  };

  const updateTrainingImageStatus = async (
    _imageId: string,
    _status: boolean
  ) => {
    try {
      await updateProductTrainingModel({
        ImageId: _imageId,
        Enabled: !_status,
      });

      enqueueSnackbar("Success", {
        variant: "success",
        cta: "Status updated.",
      });
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to change training image status.",
      });
    }
  };

  const fetchAdminTrainingImages = async () => {
    try {
      setTrainingImagesLoading(true);

      const resp = await getAdminProductTrainingImages(params.get("upc") ?? "");
      setTrainingImages(resp);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to retrieve Training Images.",
      });
    } finally {
      setTrainingImagesLoading(false);
    }
  };

  const fetchAdminProductImages = async () => {
    if (!productID) {
      return;
    }

    try {
      setLoadingProductImages(true);
      const data = await getAdminProductImage(productID);

      const sortedImages = data.ProductImages;

      if (sortedImages) {
        sortedImages.sort((a: any, b: any) =>
          a.CreatedAt > b.CreatedAt ? -1 : 1
        );

        const taggedImages: ProductImageColumn[] = sortedImages.map(
          (image: any, index: number) => {
            return {
              ...image,
              Active: index === 0 ? true : false,
            };
          }
        );

        console.log("upc:", data.UPC);
        setLocalUpc(data.UPC);
        setSubRoute({ title: data.UPC });

        // Add UPC into page params. This is a workaround for some quirks with grid state snapshotting.
        const url = new URLSearchParams(location.search);
        if (!params.get("upc")) {
          url.append("upc", data.UPC);
        } else {
          url.set("upc", data.UPC);
        }

        navigate({ search: url.toString() });

        setProductImages(taggedImages);
      }
    } catch (err: any) {
      setPageError(err.message);
      console.log(err.message);
    } finally {
      setLoadingProductImages(false);
    }
  };

  const cancelEdit = () => {
    if (navigatedPlanogram) {
      navigate({
        pathname: "../admin/planograms/edit",
        search: "?planogram=" + navigatedPlanogram,
      });
    } else {
      navigate("../admin/products");
    }
  };

  const uploadMultipleImagesToServer = async (images: File[]) => {
    setUploadProcessing(true);
    // Which index should map to which revision side
    // Accurate as of 31/05/24
    // ---
    // Primary (Front) = 0
    // Top =  1
    // Bottom = 2
    // Left Side = 3
    // Right Side = 4

    const validImagesArray = images.filter(Boolean);
    const validImageCount = validImagesArray.length;

    let successfulRequests = 0;
    // When uploading multiple images at once, we need to keep track of the revisionId.
    // First image uploaded will return a revisionId, which will be used for the rest of the images.
    // If we want to add to the most recent revision, we can pass in the `revisionIdToEdit` as the revisionId
    // in the request.

    let requestRevisionId: string | null = null;

    const uploadImage = async (index: number) => {
      try {
        const requestData = new FormData();
        requestData.append("file", images[index]);

        const data = await postAdminAddProductImage(
          productID!,
          requestData,
          requestRevisionId ?? revisionIdToEdit ?? undefined,
          // TODO: This way of determining the side number is crappy and coupled. Consider refactor and pass side number into this func directly.
          index === 0
            ? RevisionSides.primary.value
            : index === 1
            ? RevisionSides.top.value
            : index === 2
            ? RevisionSides.bottom.value
            : index === 3
            ? RevisionSides.left.value
            : RevisionSides.right.value
        );

        if (!data) {
          throw new Error("Failed to upload image");
        }
        if (data) {
          requestRevisionId = data.revisionId;
        }

        successfulRequests++;

        if (successfulRequests === validImageCount) {
          requestRevisionId = null;
          fetchAdminProductImages();
          setAddImageDialogOpen(false);
          setRevisionIdToEdit(null);
          fetchAdminProductImages();
          setSides([]);
          enqueueSnackbar("Success", {
            variant: "success",
            cta: "Images uploaded.",
          });
          setUploadProcessing(false);
        }
      } catch (error) {
        // Cleanup/reset
        setAddImageDialogOpen(false);
        setUploadProcessing(false);

        // Partial success warn
        if (successfulRequests > 0) {
          enqueueSnackbar("Warning", {
            variant: "warning",
            cta: "Partial revision uploaded. Please confirm your results are correct.",
          });
        } else {
          // Total failure error
          enqueueSnackbar("Error", {
            variant: "error",
            cta: "Failed to upload image.",
          });
        }

        fetchAdminProductImages();
      }
    };

    for (let i = 0; i < images.length; i++) {
      if (images[i]) await uploadImage(i);
    }
  };

  async function handleEmbeddingFileChange(
    e: React.ChangeEvent<HTMLInputElement>
  ) {
    if (e.target.files && e.target.files[0]) {
      try {
        // Golden Path
        setEmbeddingUploadProcessing(true);
        await postCreateEmbedding(localUpc!, e.target.files[0]);
        enqueueSnackbar("Success", {
          variant: "success",
          cta: "Embedding Uploaded.",
        });
        await fetchEmbeddings();
      } catch {
        // Err Snack
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Embedding failed to upload.",
        });
      } finally {
        // Cleanup
        setEmbeddingUploadProcessing(false);
        embeddingRef.current!.value = "";
      }
    }
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      <Box
        gap={2}
        sx={{ display: "flex", mb: 2 }}
        justifyContent={"space-between"}
      >
        <Fade in={!loadingProductImages}>
          <Typography variant="h6">
            <b>UPC:</b> {localUpc}
          </Typography>
        </Fade>
      </Box>

      <Stack spacing={2}>
        <Tabs
          value={tab}
          sx={{ textDecoration: "none" }}
          onChange={(e, nv) => setTab(nv)}
        >
          <Tab label="Product Images" value={"1"} />
          <Tab label="Training Images" value={"2"} />
          <Tab label="Embeddings" value={"3"} />
        </Tabs>

        {/* Product Images */}
        {tab === "1" && (
          <AgGridWrapper
            id="admin-product-images"
            columnDefs={imageColDefs}
            rowData={transformedImageData ?? []}
            quickSearch
            height="calc(100vh - 254px)"
            loading={loadingProductImages}
            plugins={
              <Fade in={true}>
                <Button
                  startIcon={<AddCircleOutline />}
                  variant="contained"
                  onClick={() => setAddImageDialogOpen(true)}
                >
                  New Product Revision
                </Button>
              </Fade>
            }
          />
        )}

        {/* Training Images */}
        {tab === "2" && (
          <AgGridWrapper
            disableRowAnimation
            rowHeight={ROW_WITH_IMAGE_HEIGHT}
            id="admin-training-images"
            columnDefs={
              [
                {
                  field: "ImageId",
                  headerName: "Image",
                  autoHeight: true,
                  width: 140,

                  resizable: false,
                  cellRenderer: (_data: any) => (
                    <ProductImg
                      productId={_data.value}
                      revisionId={null}
                      defaultSize={PRODUCT_IMAGE_HEIGHT}
                      growSize={PRODUCT_IMAGE_HEIGHT}
                    />
                  ),
                },
                { field: "Category" },
                { field: "AddedRevision" },
                {
                  field: "CreatedAt",
                  sort: "desc",
                  cellRenderer: (_data: any) => formatDisplayDate(_data.value),
                },
                {
                  field: "SideNumber",
                  cellRenderer: (_data: any) => (
                    <ProductSideSelector
                      initialValue={_data.value}
                      onSideChange={async (newSide) => {
                        try {
                          await updateProductTrainingModel({
                            ImageId: _data.data.ImageId,
                            Enabled: _data.data.Enabled,
                            UseForTesting: _data.data.UseForTesting,
                            upc: _data.data.UPC,
                            side: newSide,
                          });
                        } catch {
                          enqueueSnackbar("Error", {
                            variant: "error",
                            cta: "Failed to update training image.",
                          });
                        }
                      }}
                    />
                  ),
                },
                {
                  field: "Actions",
                  headerName: "Include in Training",
                  cellRenderer: (_data: any) => (
                    <Switch
                      color="success"
                      checked={_data.data.Enabled}
                      onChange={async () => {
                        await updateTrainingImageStatus(
                          _data.data.ImageId,
                          _data.data.Enabled
                        );
                        await fetchAdminTrainingImages();
                      }}
                    />
                  ),
                },
              ] as ColDef<TrainingImageColumn>[]
            }
            rowData={trainingImages ?? []}
            quickSearch
            height="calc(100vh - 254px)"
            loading={trainingImagesLoading}
          />
        )}

        {/* Embeddings */}
        {tab === "3" && (
          <AgGridWrapper
            id="admin-embeddings"
            columnDefs={embeddingColDefs}
            rowData={embeddings ?? []}
            quickSearch
            height="calc(100vh - 254px)"
            loading={embeddingsLoading}
            plugins={
              <Fade in={true}>
                <LoadingButton
                  variant="contained"
                  startIcon={<AddCircleOutline />}
                  loading={embeddingUploadProcessing}
                  component={"label"}
                >
                  <input
                    ref={embeddingRef}
                    type="file"
                    id={"embedding-upload-input"}
                    hidden
                    accept={".json"}
                    onChange={handleEmbeddingFileChange}
                  />
                  Upload Embedding (.json)
                </LoadingButton>
              </Fade>
            }
          />
        )}
      </Stack>
      <UploadImageRevisionModal
        sides={sides}
        revisionId={revisionIdToEdit ?? ""}
        productId={productID ?? ""}
        open={addImageDialogOpen}
        onClose={() => {
          setAddImageDialogOpen(false);
          setSides([]);
        }}
        uploadMultipleImagesToServer={uploadMultipleImagesToServer}
        loading={uploadProcessing}
      />

      <ViewImagesModal
        open={isViewImagesModalOpen}
        revisionId={viewRevisionId ?? ""}
        onClose={() => {
          setIsViewImagesModalOpen(false);
          setSides([]);
        }}
        productId={productID ?? ""}
        sides={sides}
      />
    </Box>
  );
};

export default AdminProductEditPage;
