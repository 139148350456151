import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { ColDef } from "ag-grid-community";
import getFixtures, { FixtureType } from "../../../../api/fixtures/getFixtures";
import { AddCircleOutline, EditRounded } from "@mui/icons-material";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import FixtureFormPopover from "../components/FixtureFormPopover";
import { ResponsiveContainerStyle } from "../../../../helpers/generalUtilities";

export const ListFixturesPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [colDefs] = useState<ColDef<FixtureType>[]>([
    { field: "Name", headerName: "Display Name" },
    { field: "Type" },
    {
      field: "CreatedAt",
      sort: 'desc',
      cellRenderer: (_data: any) => formatDisplayDate(_data.value),
    },
    {
      field: "LastModified", // Field name basically does nothing here as its being overriden by cell renderer.
      headerName: "Actions",
      cellRenderer: (_data: any) => (
        <Button
          onClick={(e) => handleEdit(_data.data, e)}
          size="small"
          startIcon={<EditRounded />}
          variant="contained"
        >
          Edit
        </Button>
      ),
    },
  ]);

  const [fixtures, setFixtures] = useState<FixtureType[]>([]);
  const [fixturesLoading, setFixturesLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editingFixture, setEditingFixture] =
    useState<Partial<FixtureType> | null>(null);

  useEffect(() => {
    fetchFixtures();
  }, []);

  const fetchFixtures = async () => {
    try {
      setFixturesLoading(true);
      const data = await getFixtures();
      setFixtures(data);
    } catch {
      enqueueSnackbar("Failed to load Fixtures.", { variant: "error" });
    } finally {
      setFixturesLoading(false);
    }
  };

  const handleCreate = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setEditingFixture(null);
  };

  const handleEdit = (
    fixture: FixtureType,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setEditingFixture(fixture);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setEditingFixture(null);
  };

  const handleSuccess = () => {
    fetchFixtures();
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <FixtureFormPopover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        fixture={editingFixture}
        onSuccess={handleSuccess}
      />

      <AgGridWrapper
        quickSearch
        id="fixtures-list"
        rowData={fixtures}
        columnDefs={colDefs}
        loading={fixturesLoading}
        plugins={
          <Button
            startIcon={<AddCircleOutline />}
            variant="contained"
            color="primary"
            onClick={handleCreate}
            sx={{ mb: 2 }}
          >
            Add Fixture
          </Button>
        }
      />
    </Box>
  );
};
