import { useEffect } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Popover,
} from "@mui/material";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import { useMasterFilterStore } from "../../../../../stores/metrics/MasterFilterStore";
import { useMetricsFilterOptions } from "../../../../../stores/metrics/MetricsOptionsStore";
import { FilterInputStyle } from "../../../helpers/DashboardHelpers";
import { urStorePalette } from "../../../../../themes/urStoreTheme";
import MultiTag from "../../LocationFilter/MultiTag";

interface CategoryFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

interface Category {
  [key: string]: any;
}

interface TransformedBrand {
  name: string;
}

interface TransformedManufacturer {
  name: string;
  Brand: TransformedBrand[];
}

interface TransformedSubCategory {
  name: string;
  Manufacturer: TransformedManufacturer[];
}

interface TransformedCategory {
  name: string;
  SubCategory: TransformedSubCategory[];
}

const CategoryFilterModal = (props: CategoryFilterModalProps) => {
  const category = usePlanogramFilterStore((state) => state.category);
  const subcategory = usePlanogramFilterStore((state) => state.subcategory);
  const manufacturer = usePlanogramFilterStore((state) => state.manufacturer);
  const brand = usePlanogramFilterStore((state) => state.brand);
  const setCategory = usePlanogramFilterStore((state) => state.setCategory);
  const setBrand = usePlanogramFilterStore((state) => state.setBrand);
  const setSubCategory = usePlanogramFilterStore(
    (state) => state.setSubCategory
  );
  const setManufacturer = usePlanogramFilterStore(
    (state) => state.setManufacturer
  );

  const open = Boolean(props.anchorEl);
  const id = open ? "category-filter" : undefined;

  // Master
  const { syncedFilters, setSyncFilters } = useMasterFilterStore();
  const { data } = useMetricsFilterOptions();

  // Function for transforming the data from the API into a more usable format.
  const transformData = (data: Category) => {
    const transformedData: TransformedCategory[] = [];

    // transform each category into a new object and insert subcategories
    for (const categoryKey in data.Category) {
      const category: TransformedCategory = {
        name: categoryKey,
        SubCategory: [],
      };
      // transform each subcategory into a new object and insert manufacturers
      for (const subCategoryKey in data.Category[categoryKey]["SubCategory"]) {
        const subCategory: TransformedSubCategory = {
          name: subCategoryKey,
          Manufacturer: [],
        };
        // transform each manufacturer into a new object and insert brands
        for (const manufacturerKey in data.Category[categoryKey]["SubCategory"][
          subCategoryKey
        ]["Manufacturer"]) {
          const manufacturer: TransformedManufacturer = {
            name: manufacturerKey,
            Brand: [],
          };
          // transform each brand into a new object and insert into manufacturer
          for (const brand of data.Category[categoryKey]["SubCategory"][
            subCategoryKey
          ]["Manufacturer"][manufacturerKey].Brand) {
            manufacturer.Brand.push({ name: brand });
          }
          subCategory.Manufacturer.push(manufacturer);
        }
        category.SubCategory.push(subCategory);
      }
      transformedData.push(category);
    }
    return transformedData;
  };

  const transformedFilterData = data && transformData(data);

  useEffect(() => {
    // TODO: This is a workaround. We should centralise all the filters to run off masterstore.
    setCategory(syncedFilters?.category ?? []);
    setSubCategory(syncedFilters?.subcategory ?? []);
    setManufacturer(syncedFilters?.manufacturer ?? []);
    setBrand(syncedFilters?.brand ?? []);
  }, [syncedFilters]);

  return (
    <div>
      <Popover
        sx={{ mt: 2 }}
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid gap={4} container sx={{ p: 4, width: "400px" }}>
          {/* Category */}
          <FormControl fullWidth>
            <Autocomplete
              multiple
              options={transformedFilterData || []}
              getOptionLabel={(option) => option?.name || ""}
              value={
                Array.from(new Set(category))
                  .map(catName => transformedFilterData?.find(c => c.name === catName))
                  .filter(Boolean) || []
              }
              onChange={(event, values) => {
                const categoryNames = values.map(v => v?.name || "");
                setCategory(categoryNames);
                setSyncFilters(["category"], categoryNames);

                setSyncFilters(["subcategory", "manufacturer", "brand"], null);
                setSubCategory([]);
                setManufacturer([]);
                setBrand([]);
              }}
              renderTags={(tagValues, getTagProps) =>
                tagValues.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <MultiTag {...getTagProps({ index })} label={option?.name} />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
            />
          </FormControl>

          {/* Sub Category */}
          <FormControl fullWidth>
            <Autocomplete
              multiple
              options={
                Array.from(
                  new Set(
                    transformedFilterData
                      ?.filter((c) => category?.includes(c.name))
                      .flatMap((c) => c.SubCategory)
                      .map(s => s.name)
                  )
                ).map(name => ({name})) || []
              }
              getOptionLabel={(option) => option?.name || ""}
              value={
                Array.from(new Set(subcategory))
                  .map(subName => ({ name: subName })) || []
              }
              onChange={(event, values) => {
                const subCategoryNames = Array.from(new Set(values.map(v => v.name)));
                setSubCategory(subCategoryNames);
                setSyncFilters(["subcategory"], subCategoryNames);

                setSyncFilters(["manufacturer", "brand"], null);
                setManufacturer([]);
                setBrand([]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sub Category"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              renderTags={(tagValues, getTagProps) =>
                Array.from(new Set(tagValues.map(option => option.name))).map((name, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <MultiTag {...getTagProps({ index })} label={name} />
                ))
              }
              disabled={category?.length === 0}
            />
          </FormControl>

          {/* Manufacturer */}
          <FormControl fullWidth>
            <Autocomplete
              multiple
              options={
                Array.from(
                  new Set(
                    transformedFilterData
                      ?.filter((c) => category?.includes(c.name))
                      .flatMap((c) => c.SubCategory)
                      .filter((s) => subcategory?.includes(s.name))
                      .flatMap((s) => s.Manufacturer)
                      .map(m => m.name)
                  )
                ).map(name => ({name})) || []
              }
              getOptionLabel={(option) => option.name}
              value={
                Array.from(new Set(manufacturer))
                  .map(manuName => ({ name: manuName })) || []
              }
              onChange={(event, values) => {
                const manufacturerNames = values.map(v => v.name);
                setManufacturer(manufacturerNames);
                setSyncFilters(["manufacturer"], manufacturerNames);

                setSyncFilters(["brand"], null);
                setBrand([]);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Manufacturer"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              renderTags={(tagValues, getTagProps) =>
                Array.from(new Set(tagValues.map(option => option.name))).map((name, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <MultiTag {...getTagProps({ index })} label={name} />
                ))
              }
              disabled={subcategory?.length === 0}
            />
          </FormControl>

          {/* Brand */}
          <FormControl fullWidth>
            <Autocomplete
              multiple
              options={
                Array.from(
                  new Set(
                    transformedFilterData
                      ?.filter((c) => category?.includes(c.name))
                      .flatMap((c) => c.SubCategory)
                      .filter((s) => subcategory?.includes(s.name))
                      .flatMap((s) => s.Manufacturer)
                      .filter((m) => manufacturer?.includes(m.name))
                      .flatMap((m) => m.Brand)
                      .map(b => b.name)
                  )
                ).map(name => ({name})) || []
              }
              renderTags={(tagValues, getTagProps) =>
                Array.from(new Set(tagValues.map(option => option.name))).map((name, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <MultiTag {...getTagProps({ index })} label={name} />
                ))
              }
              getOptionLabel={(option) => option.name}
              value={
                Array.from(new Set(brand))
                  .map(brandName => ({ name: brandName })) || []
              }
              onChange={(event, values) => {
                const brandNames = values.map(v => v.name);
                setBrand(brandNames);
                setSyncFilters(["brand"], brandNames);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Brand"
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      ...FilterInputStyle,
                      backgroundColor: params.disabled
                        ? urStorePalette.greys.light
                        : urStorePalette.greys.lightest,
                    },
                  }}
                  required
                />
              )}
              disabled={manufacturer?.length === 0}
            />
          </FormControl>
        </Grid>
      </Popover>
    </div>
  );
};

export default CategoryFilterModal;
