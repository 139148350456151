import { StoreCategory } from "../../features/admin/stores/types/CategoryTypes";
import axiosInstance from "../axiosInstanceConfig";

const getStoreCategories = async (_storeId: string) => {
  const params = new URLSearchParams();

  params.append("storeId", _storeId);

  const response = await axiosInstance.get<StoreCategory[]>(
    `StoreCategory/AdminStoreCategoryByStoreIdList?${params.toString()}`
  );

  return response.data;
};

export default getStoreCategories;
