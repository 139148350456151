import axiosInstance from "../axiosInstanceConfig";

/**
 * Soft delete a task. This is irreversible for a user, but can be restored manually by an admin.
 * @param taskId - The ID of the task to soft delete.
 * @param teamId - The ID of the team the task belongs to.
 * @returns True if the task was soft deleted successfully, otherwise throws an error.
 */
const postSoftDeleteTask = async (
  taskId: string,
  teamId: string,
) => {
  const params = new URLSearchParams();

  params.append("taskId", taskId);
  params.append("teamId", teamId);

  const response = await axiosInstance.delete(
    `task/SoftDeleteTask?${params.toString()}`
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error(
      "There was an error deleting the task/job. Please contact support."
    );
  }
};

export default postSoftDeleteTask;
