import {
  useState,
  useEffect,
  Fragment,
  useContext,
  useMemo,
  useCallback,
} from "react";
import {
  Grid,
  CardMedia,
  Skeleton,
  Typography,
  TextField,
  Box,
  Button,
  Autocomplete,
  Stack,
  Fade,
  Chip,
  Modal,
  IconButton,
  MenuItem,
  Tooltip,
} from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import {
  ProductButtonType,
  productButtonStates,
} from "../../../../components/ProductButtons";
import CircularProgress from "@mui/material/CircularProgress";
import {
  CaptureProduct,
  ProductComparisonDialogTypes,
  ProductComparisonTrainingButtonsTypes,
  SelectedProductDataTypes,
  CaptureMLModel,
  CaptureProductNoInput,
} from "../../../../models/planograms/ProductComparisonTypes";
import { urStorePalette } from "../../../../themes/urStoreTheme";
import { ProductComparisonTrainingButtons } from "./ProductComparisonTrainingButtons";
import postUpdateCapturePositionStatus from "../../../../api/planograms/product-comparison/postUpdateCapturePositionStatus";
import { postNewProductTrainingModel } from "../../../../api/planograms/product-comparison/postNewProductTrainingModel";
import getAdminAllProducts from "../../../../api/planograms/getAdminAllProducts";
import { getProductImage } from "../../../../api/planograms/product-comparison/getProductImage";
import ProductComparisonHeader from "./ProductComparisonHeader";
import useImageCroppingHook from "./ImageCropping";
import { updateProductTrainingModel } from "../../../../api/planograms/product-comparison/updateProductTrainingModel";

/** icons */
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ArrowBack,
  ArrowForward,
  Cancel,
  CheckCircle,
  Close,
  ContentCopyOutlined,
  DeleteForever,
  DifferenceOutlined,
  InfoOutlined,
  JoinLeftRounded,
  Pending,
} from "@mui/icons-material";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import { useTeamStore } from "../../../../stores/teams/TeamStore";
import { useAuthContext } from "../../../authentication/contexts/AuthContext";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { RevisionContext } from "../../pages/PlanogramCapturesIndex";
import { useImageStore } from "../../../../stores/images/ImageStore";
import { CapturePositionAnnotation } from "../../../../models/planograms/AnnotationTypes";
import { usePlanogramStore } from "../../../../stores/snapshots/PlanogramStore";
import useKeyPress from "../../hooks/useKeyPress";
import { TrainingImageTypes } from "../../models/CaptureAnnotation";
import ComparisonCard from "./ComparisonCard";
import { RevisionSides } from "../../../admin/products/RevisionSideUtils";

export default function ProductComparisonDialog({
  openCompareProductComparisonDialog,
  closeCompareProductComparisonDialog,
  mainProductImage,
  captureId,
  bayIndex,
  fixtureCardinality,
  callUpdatedData,
}: ProductComparisonDialogTypes) {
  // Util
  const auth = useAuthContext();
  const imageCroppingHook = useImageCroppingHook();
  const { enqueueSnackbar } = useSnackbar();
  const { roleOnTeam: userCurrentRole } = useTeamStore();
  const { adminRole } = useAuthContext();
  const context = useContext(RevisionContext);
  const {
    cachedProducts,
    mostRecentRevision,
    setCachedProducts,
    setMostRecentRevision,
  } = useImageStore();
  const {
    activeCapturePositionAnnotation,
    setActiveCapturePositionAnnotation,
    bayItemsList,
  } = usePlanogramStore();

  // State
  const [activeProductButton, setActiveProductButton] =
    useState<ProductButtonType>(
      productButtonStates.find(
        (button) => button.status === activeCapturePositionAnnotation?.Status
      ) || productButtonStates[0]
    );

  useEffect(() => {
    setActiveProductButton(
      productButtonStates.find(
        (button) => button.status === activeCapturePositionAnnotation?.Status
      ) || productButtonStates[0]
    );
  }, [activeCapturePositionAnnotation]);

  const [savedCroppedImage, setSavedCroppedImage] =
    useState<string>(""); /** canvas cropped image used for capture box */
  const [loadingButtonId, setLoadingButtonId] = useState<number | null>(null);

  /** Detected Product States and logic
   *
   * FoundUPC: is from the capture position data - also called as CaptureAnnotations
   *  filteredDataForDetectedProduct: this is filtered data that has the foundUPC in the allProducts for getting Detected product image
   * filteredProductId: the id extracted from the filteredDataForDetectedProduct, to then call the getAdminImages api for the image of the Detected card
   * detectedProductId: first id to be used for getting the image of Detected product
   * detectedProductRevisionId: second id to be used for getting the image of Detected product
   *
   */
  const [allProducts, setAllProducts] = useState<CaptureProduct[]>([]);
  const foundUPC =
    openCompareProductComparisonDialog === true
      ? activeCapturePositionAnnotation?.FoundUpc
      : undefined;
  const filteredDataForDetectedProduct = allProducts.filter(
    (product) => product.UPC === foundUPC
  );
  const [productSide, setProductSide] = useState<number>(
    activeCapturePositionAnnotation?.TrainingImage?.SideNumber ?? 0
  );
  const [filteredProductId, setFilteredProductId] = useState<string>();
  const [detectedProductId, setDetectedProductId] = useState<string>();
  const [detectedProductRevisionId, setDetectedProductRevisionId] =
    useState<string>();

  /** for usedFor button state */
  const [capturedProductIsUsedFor, setCapturedProductIsUsedFor] = useState<
    "training" | "testing" | "removeImage"
  >();

  /** Training Mode states */
  const [trainingMode, setTrainingMode] = useState<boolean>(false);
  const [croppedCanvasImage, setCroppedCanvasImage] = useState<Blob | null>(
    null
  );
  const [activeTrainingModeButton, setActiveTrainingModeButton] = useState<
    ProductComparisonTrainingButtonsTypes | undefined
  >();
  const [apiRequestLoading, setApiRequestLoading] = useState<boolean>(false);

  /** Correction - AutoComplete component states */
  const [openAutoComplete, setOpenAutoComplete] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectOptionValue, setSelectOptionValue] =
    useState<CaptureProduct | null>();
  const [selectedProductId, setSelectedProductId] = useState<
    string | undefined
  >();
  const [selectedProductData, setSelectedProductData] =
    useState<SelectedProductDataTypes>(); /** has the image data used for the 'Reference' box */

  const [
    currentCapturePositionIndexOnBayList,
    setCurrentCapturePositionIndexOnBayList,
  ] = useState<number | null>(null);

  /** ⚠️ DO NOT TOUCH or Add dependencies. Hook handles image cropping and is a bit flimsy. **/
  useEffect(() => {
    if (openCompareProductComparisonDialog === true) {
      imageCroppingHook.handleImageCrop(
        mainProductImage,
        activeCapturePositionAnnotation as CapturePositionAnnotation
      );
    }

    if (activeCapturePositionAnnotation && activeCapturePositionAnnotation) {
      findCapturePositionIndex();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openCompareProductComparisonDialog,
    mainProductImage,
    activeCapturePositionAnnotation,
  ]);

  useEffect(() => {
    // Manage Updating Product Side when swapping active capture position.
    if (activeCapturePositionAnnotation?.TrainingImage?.SideNumber) {
      setProductSide(activeCapturePositionAnnotation.TrainingImage.SideNumber);
    } else {
      setProductSide(0);
    }
  }, [activeCapturePositionAnnotation]);

  useEffect(() => {
    if (imageCroppingHook.savedCroppedImageDataURL) {
      setSavedCroppedImage(imageCroppingHook.savedCroppedImageDataURL);
      if (imageCroppingHook.canvasToConvertToABlob !== null) {
        if (
          JSON.stringify(activeCapturePositionAnnotation) ===
          JSON.stringify(activeCapturePositionAnnotation)
        ) {
          imageCroppingHook.canvasToConvertToABlob.toBlob((blob) => {
            setCroppedCanvasImage(blob);
          });
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    imageCroppingHook.savedCroppedImageDataURL,
    activeCapturePositionAnnotation,
    currentCapturePositionIndexOnBayList,
  ]);

  /** handles getting all products */
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);

      // Try fetch from cache if current revision matches.
      const response =
        mostRecentRevision !== null &&
        mostRecentRevision === context?.revision.PlanogramRevisionId
          ? cachedProducts ?? (await getAdminAllProducts())
          : await getAdminAllProducts();

      setLoading(false);

      const productData: CaptureProductNoInput[] = response.map(
        (item: CaptureProduct) => ({
          CreatedAt: item.CreatedAt,
          LastModified: item.LastModified,
          ProductId: item.ProductId,
          ProductImages: item.ProductImages,
          UPC: item.UPC,
        })
      );

      setCachedProducts(response);
      setMostRecentRevision(context?.revision.PlanogramRevisionId ?? null);
      setMostRecentRevision(context?.revision.PlanogramRevisionId ?? null);
      setAllProducts(productData);
    };
    if (openCompareProductComparisonDialog === true && auth.adminRole) {
      // Get all products to prepare for train mode if the logged-in user is an admin
      fetchProducts();
    }
  }, [openCompareProductComparisonDialog, auth.adminRole]);

  /** handles getting the images for the selected product from the autocomplete */
  useEffect(() => {
    if (selectedProductId === null || selectedProductId === undefined) return;

    const getSelectedProductImage = async () => {
      const data = await getProductImage(selectedProductId);
      setSelectedProductData(data);
    };
    getSelectedProductImage();
  }, [selectedProductId]);

  /** handles getting the id of the selected product in the autocomplete */
  useEffect(() => {
    if (filteredDataForDetectedProduct) {
      let newData = filteredDataForDetectedProduct.map(
        (item) => item.ProductId
      );
      setFilteredProductId(newData[0]);
    }
  }, [filteredDataForDetectedProduct]);

  /** gets the productId and revisionId that will be used for getting the image of Detected Product based on the filteredProductId */
  useEffect(() => {
    // If we don't have a foundUpc, skip attempting to fetch Detected Product.
    if (!foundUPC || !filteredProductId) return;

    // Define func for loading detected image.
    const getDetectedProductImage = async () => {
      let data = await getProductImage(filteredProductId);

      data?.ProductImages?.sort(
        (a: any, b: any) =>
          new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
      );

      setDetectedProductId(data.ProductImages[0].ProductId);
      setDetectedProductRevisionId(data.ProductImages[0].RevisionId);

      setLoading(false);
    };

    // Invoke call for detected img load.
    getDetectedProductImage();
  }, [foundUPC, filteredProductId]);

  /** handles the initial state of autocomplete and showing the image of the reference product box */
  useEffect(() => {
    if (activeCapturePositionAnnotation?.TrainingImage?.Enabled) {
      // If training image selected prior, default to using that.
      const initialSelectedProduct = allProducts.find(
        (option) =>
          option.UPC === activeCapturePositionAnnotation?.TrainingImage?.UPC
      );
      setSelectOptionValue(initialSelectedProduct);
      setSelectedProductId(initialSelectedProduct?.ProductId);
    } else {
      // Otherwise, use foundupc.
      const initialSelectedProduct = allProducts.find(
        (option) => option.UPC === activeCapturePositionAnnotation?.FoundUpc
      );

      setSelectOptionValue(initialSelectedProduct);
      setSelectedProductId(initialSelectedProduct?.ProductId);
    }
  }, [
    allProducts,
    openCompareProductComparisonDialog,
    activeCapturePositionAnnotation?.FoundUpc,
    activeCapturePositionAnnotation?.TrainingImage?.Enabled,
    activeCapturePositionAnnotation?.TrainingImage?.UPC,
  ]);

  /** handles initial state for active training mode buttons */
  useEffect(() => {
    if (
      activeCapturePositionAnnotation?.TrainingImage?.Enabled &&
      activeCapturePositionAnnotation?.TrainingImage?.ImageId
    ) {
      if (
        activeCapturePositionAnnotation?.TrainingImage?.UseForTesting === true
      )
        setActiveTrainingModeButton(ProductComparisonTrainingButtons[0]);
      else if (
        activeCapturePositionAnnotation?.TrainingImage?.UseForTesting === false
      )
        setActiveTrainingModeButton(ProductComparisonTrainingButtons[1]);
    } else if (
      activeCapturePositionAnnotation?.TrainingImage?.Enabled === false
    ) {
      setActiveTrainingModeButton(undefined);
    }
  }, []);

  // Sort the bay items list by row index and filter by bay index
  const sortedBayItemsList = useMemo(() => {
    return (
      bayItemsList
        ?.filter((item) => item.BayIndex === bayIndex)
        .sort((a, b) => a.RowIndex - b.RowIndex) || []
    );
  }, [bayItemsList, bayIndex]);

  // Get the first and last item of the bay
  const firstItemOfBay = sortedBayItemsList?.[0];
  const lastItemOfBay =
    sortedBayItemsList.length > 0
      ? sortedBayItemsList?.[sortedBayItemsList.length - 1]
      : null;

  const compareObjects = useCallback((obj1: any, obj2: any): boolean => {
    if (!obj1 || !obj2) {
      return false;
    } else {
      return Object.keys(obj1).every((key) => obj1[key] === obj2[key]);
    }
  }, []);

  // Check if the current capture position is the first or last item of the bay
  // This is used to disable the next/previous buttons when the first or last item is selected
  const isCurrentCapturePositionFirstItem = useMemo(
    () => compareObjects(activeCapturePositionAnnotation, firstItemOfBay),
    [activeCapturePositionAnnotation, firstItemOfBay, compareObjects]
  );

  const isCurrentCapturePositionLastItem = useMemo(
    () => compareObjects(activeCapturePositionAnnotation, lastItemOfBay),
    [activeCapturePositionAnnotation, lastItemOfBay, compareObjects]
  );

  const findCapturePositionIndex = () => {
    if (bayItemsList && activeCapturePositionAnnotation) {
      const index = bayItemsList.findIndex(
        (pos) =>
          pos.PositionCardinality ===
            activeCapturePositionAnnotation.PositionCardinality &&
          pos.FoundUpc === activeCapturePositionAnnotation.FoundUpc &&
          pos.ProductImageUrl ===
            activeCapturePositionAnnotation.ProductImageUrl &&
          pos.ProductName === activeCapturePositionAnnotation.ProductName &&
          pos.Xpos === activeCapturePositionAnnotation.Xpos &&
          pos.Ypos === activeCapturePositionAnnotation.Ypos &&
          pos.Width === activeCapturePositionAnnotation.Width &&
          pos.Height === activeCapturePositionAnnotation.Height &&
          pos.MlBoxX === activeCapturePositionAnnotation.MlBoxX &&
          pos.MlBoxY === activeCapturePositionAnnotation.MlBoxY &&
          pos.MlBoxWidth === activeCapturePositionAnnotation.MlBoxWidth &&
          pos.MlBoxHeight === activeCapturePositionAnnotation.MlBoxHeight &&
          pos.ExpectedUpc === activeCapturePositionAnnotation.ExpectedUpc &&
          pos.HFacingIndex === activeCapturePositionAnnotation.HFacingIndex &&
          pos.VFacingIndex === activeCapturePositionAnnotation.VFacingIndex &&
          pos.TrayIndexX === activeCapturePositionAnnotation.TrayIndexX &&
          pos.TrayIndexY === activeCapturePositionAnnotation.TrayIndexY &&
          pos.Status === activeCapturePositionAnnotation.Status &&
          pos.TrainingImage === activeCapturePositionAnnotation.TrainingImage
      );
      setCurrentCapturePositionIndexOnBayList(index);
    }
  };

  const handleCancelButton = () => {
    setTrainingMode(false);
  };

  /** handles the logic for submitting training data and API request (Save button) */
  const setTrainingImage = async () => {
    if (croppedCanvasImage === null || selectedProductData?.UPC === undefined)
      return;

    const updatedProductTrainingDataModel: CaptureMLModel = {
      image: croppedCanvasImage,
      upc: selectedProductData?.UPC,
      captureFromCaptureId: captureId,
      packagingVariation: "0" /** is always 0 for now */,
      category: context!.revision.Planogram!.PlanogramCategory!.Name,
      captureFromFixtureCardinality: fixtureCardinality,
      captureFromPositionCardinality:
        activeCapturePositionAnnotation?.PositionCardinality ?? 0,
      captureFromHFacingIndex:
        activeCapturePositionAnnotation?.HFacingIndex ?? 0,
      captureFromVFacingIndex:
        activeCapturePositionAnnotation?.VFacingIndex ?? 0,
      captureFromTrayIndexX: activeCapturePositionAnnotation?.TrayIndexX ?? 0,
      captureFromTrayIndexY: activeCapturePositionAnnotation?.TrayIndexY ?? 0,
      useForTesting:
        capturedProductIsUsedFor === "testing"
          ? true
          : false /** if false the image will be used for training the ml model instead */,
      sideNumber: productSide,
    };

    try {
      setApiRequestLoading(true);
      const data = await postNewProductTrainingModel(
        updatedProductTrainingDataModel,
        context?.taskIndex ?? 0
      );
      if (data) {
        // Update the `trainingImage` on the active capture to keep UI updated.
        setActiveCapturePositionAnnotation({
          ...activeCapturePositionAnnotation,
          TrainingImage: data as TrainingImageTypes,
        } as CapturePositionAnnotation);

        callUpdatedData();
        setApiRequestLoading(false);
        enqueueSnackbar("Success", { variant: "success" });
      }
    } catch (err: any) {
      setApiRequestLoading(false);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: err.response.data.detail,
      });
    }
  };

  /** handles the products status (product button status - In Stock, Out of Stock, or Wrong Product) and API request */
  const handleUpdateProductStatus = async (button: ProductButtonType) => {
    setActiveProductButton(() => button);
    if (activeProductButton.status === button.status)
      return; /** does not proceed with the api call if user clicks currently active product status button */

    const updateProductStatusModel = {
      CaptureId: captureId,
      BayIndex: bayIndex,
      PositionCardinality: activeCapturePositionAnnotation?.PositionCardinality,
      FixtureCardinality: fixtureCardinality,
      HFacingIndex: activeCapturePositionAnnotation?.HFacingIndex ?? 0,
      VFacingIndex: activeCapturePositionAnnotation?.VFacingIndex ?? 0,
      Status: button.status,
      TrayIndexX: activeCapturePositionAnnotation?.TrayIndexX ?? 0,
      TrayIndexY: activeCapturePositionAnnotation?.TrayIndexY ?? 0,
    };

    try {
      setLoadingButtonId(button.id);
      await postUpdateCapturePositionStatus({
        ...updateProductStatusModel,
        PositionCardinality:
          activeCapturePositionAnnotation?.PositionCardinality ?? 0,
        TaskIndex: context?.taskIndex ?? 0,
      });
      callUpdatedData();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to update capture status. ",
      });
    } finally {
      setLoadingButtonId(null);
    }
  };

  /** This function handles the API calls for the Update button */
  const updateTrainingImage = async (status: boolean) => {
    if (activeCapturePositionAnnotation?.TrainingImage?.ImageId === undefined)
      return;
    setApiRequestLoading(true);
    const ImageId = activeCapturePositionAnnotation?.TrainingImage?.ImageId;
    const Enabled = status;
    const UseForTesting = handleUseForTesting();
    const side = productSide;

    // Will use selected option value for setting/updating, and use the current one if removing.
    const upc =
      selectOptionValue?.UPC ??
      activeCapturePositionAnnotation?.TrainingImage?.UPC;

    function handleUseForTesting() {
      if (capturedProductIsUsedFor === "testing") return true;
      else if (capturedProductIsUsedFor === "training") return false;
      else return undefined;
    }

    try {
      if (ImageId === undefined) throw new Error();

      const data = await updateProductTrainingModel({
        ImageId,
        Enabled,
        UseForTesting,
        upc,
        side,
      });

      setActiveCapturePositionAnnotation({
        ...activeCapturePositionAnnotation,
        TrainingImage: data as TrainingImageTypes,
      } as CapturePositionAnnotation);

      enqueueSnackbar("Success", { variant: "success" });
      callUpdatedData();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to save changes.",
      });
    } finally {
      setApiRequestLoading(false);
    }
  };

  /** checker for autocomplete component */
  const isOptionEqualToValue = (
    option: CaptureProduct,
    value: CaptureProduct
  ) => {
    return option.ProductId === value.ProductId;
  };

  // Returns a URL for either a training image, reference image, or expected image depending on context.
  function getTrainingMediaUrl(): string {
    // If in training mode, show the UPC in the selected box.

    if (selectOptionValue?.UPC && trainingMode) {
      // get the latest image of the selected product by finding the highest created date
      const latestProductImage = selectedProductData?.ProductImages.sort(
        (a, b) =>
          new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
      )[0];

      const productId: string = selectOptionValue.ProductId;
      const revisionId: string | undefined = latestProductImage?.RevisionId;

      return `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products/${productId}/${revisionId}`;
    }

    // If in training mode and no selection in autocomplete, use existing data if available.
    if (activeCapturePositionAnnotation?.TrainingImage && trainingMode) {
      return `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${activeCapturePositionAnnotation?.TrainingImage.ProductImageUrl}`;
    }

    // Fallthrough: If nothing else available, use capPos product info where possible.
    // This is what you will see if you aren't in training mode, usually.
    return `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${activeCapturePositionAnnotation?.ProductImageUrl}`;
  }

  function getDetectedMediaUrl(): string | null {
    if (
      (activeCapturePositionAnnotation &&
        activeCapturePositionAnnotation.FoundUpc === null) ||
      !detectedProductId ||
      !detectedProductRevisionId
    ) {
      return null;
    } else {
      return `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${detectedProductId}/${detectedProductRevisionId}`;
    }
  }

  const goToNextPosition = () => {
    if (isCurrentCapturePositionLastItem) return;
    setLoading(true);
    setActiveCapturePositionAnnotation(
      bayItemsList![
        currentCapturePositionIndexOnBayList! + 1
      ] as CapturePositionAnnotation
    );
    setLoading(false);
  };

  useKeyPress("ArrowRight", goToNextPosition);

  const goToPreviousPosition = () => {
    if (isCurrentCapturePositionFirstItem) return;
    setLoading(true);
    setActiveCapturePositionAnnotation(
      bayItemsList![
        currentCapturePositionIndexOnBayList! - 1
      ] as CapturePositionAnnotation
    );
    setLoading(false);
  };

  useKeyPress("ArrowLeft", goToPreviousPosition);

  return (
    <Modal
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
      open={openCompareProductComparisonDialog}
      onClose={(_event, _reason) => {
        closeCompareProductComparisonDialog();
        setCurrentCapturePositionIndexOnBayList(null);
      }}
    >
      <Grid
        sx={{ maxWidth: 1320, height: "100%", border: 0, outline: 0 }}
        container
        display="flex"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        {/* Icon left */}
        <Grid display="flex" justifyContent="flex-end" flexGrow={2} item xs={1}>
          <IconButton
            onClick={goToPreviousPosition}
            disabled={loading || isCurrentCapturePositionFirstItem}
            sx={{
              color: "white",
              fontSize: 50,
              marginRight: 5,
              background: "#a19f9c",
            }}
          >
            <ArrowBack fontSize="inherit" />
          </IconButton>
        </Grid>

        <Grid item xs={10}>
          <Box sx={{ background: "white", borderRadius: 3, pb: 4 }}>
            <ProductComparisonHeader
              trainingMode={trainingMode}
              loading={loading}
              capturePosition={
                activeCapturePositionAnnotation ||
                ({} as CapturePositionAnnotation)
              }
              closeCompareProductComparisonDialog={() => {
                closeCompareProductComparisonDialog();
                setActiveCapturePositionAnnotation(null);
                setTrainingMode(false);
                setCurrentCapturePositionIndexOnBayList(null);
                setSavedCroppedImage("");
              }}
            />

            <Grid
              sx={{ mx: 6 }}
              display="flex"
              direction="row"
              justifyContent="space-evenly"
              alignItems="center"
              gap={4}
            >
              {/* Captured */}
              {!loading ? (
                <Grid item sm={4}>
                  <ComparisonCard
                    media={
                      <CardMedia
                        component="img"
                        height="300"
                        image={savedCroppedImage}
                        sx={{
                          objectFit: "contain",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          width: "min-content",
                          borderRadius: 2,
                        }}
                      />
                    }
                    content={
                      <Stack
                        direction="row"
                        sx={{ width: "100%" }}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="body1" fontWeight="bold">
                          Captured
                        </Typography>
                        {activeCapturePositionAnnotation?.MlBoxX === null ? (
                          <GridOnIcon sx={{ fontSize: 18 }} />
                        ) : (
                          <AutoFixHighIcon sx={{ fontSize: 18 }} />
                        )}
                      </Stack>
                    }
                  />
                </Grid>
              ) : (
                <Skeleton
                  variant="rectangular"
                  width={330}
                  height={460}
                  animation="wave"
                />
              )}

              {/* DETECTED CARD STARTS */}
              {adminRole && !loading && (
                <Grid item sm={4}>
                  <ComparisonCard
                    media={
                      getDetectedMediaUrl() !== null ? (
                        <CardMedia
                          component="img"
                          height="300"
                          image={getDetectedMediaUrl()!}
                          sx={{
                            objectFit: "contain",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            width: "min-content",
                            p: 2,
                            borderRadius: 2,
                          }}
                        />
                      ) : (
                        <Stack
                          alignItems={"center"}
                          gap={2}
                          height={300}
                          justifyContent={"center"}
                        >
                          {activeCapturePositionAnnotation?.FoundUpc ===
                          null ? (
                            <Close
                              sx={{
                                fontSize: "4rem",
                                color: `${urStorePalette.greys.default}`,
                              }}
                            />
                          ) : (
                            <CircularProgress />
                          )}
                          <Typography
                            color={`${urStorePalette.greys.dark}`}
                            variant="subtitle2"
                          >
                            {activeCapturePositionAnnotation?.FoundUpc === null
                              ? "No product detected at this location"
                              : "Loading..."}
                          </Typography>
                        </Stack>
                      )
                    }
                    content={
                      <Stack spacing={1}>
                        <Stack
                          direction="row"
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Stack direction="row" gap={1}>
                            {/* Card Title */}
                            <Typography variant="body1" fontWeight="bold">
                              Detected
                            </Typography>
                            {/* Sim Score (Admin Only) */}
                            {activeCapturePositionAnnotation?.DetectedSimScore !==
                              null && (
                              <Tooltip title="Similarity Score">
                                <Chip
                                  icon={<JoinLeftRounded />}
                                  label={`${(
                                    activeCapturePositionAnnotation?.DetectedSimScore ??
                                    0
                                  ).toFixed(3)}`}
                                  sx={{ width: "min-content" }}
                                  size="small"
                                  color="info"
                                />
                              </Tooltip>
                            )}
                          </Stack>
                          {/* UPC Copy Button */}
                          {foundUPC && (
                            <Button
                              sx={{
                                color: urStorePalette.greys.dark,
                                height: "1rem",
                              }}
                              size="small"
                              startIcon={<ContentCopyOutlined />}
                              onClick={() => {
                                navigator.clipboard.writeText(foundUPC);
                                enqueueSnackbar("UPC Copied", {
                                  variant: "success",
                                });
                              }}
                            >
                              {foundUPC}
                            </Button>
                          )}
                        </Stack>
                      </Stack>
                    }
                  />
                </Grid>
              )}
              {adminRole && loading && (
                <Grid item>
                  <Skeleton
                    variant="rectangular"
                    width={330}
                    height={460}
                    animation="wave"
                  />
                </Grid>
              )}
              {/* DETECTED CARD ENDS */}

              <Grid item sm={4}>
                {!loading ? (
                  <ComparisonCard
                    media={
                      trainingMode &&
                      !selectOptionValue &&
                      !activeCapturePositionAnnotation?.TrainingImage
                        ?.Enabled ? (
                        <Stack
                          direction="column"
                          justifyContent={"center"}
                          alignItems={"center"}
                          height={300}
                          gap={2}
                        >
                          <InfoOutlinedIcon
                            sx={{
                              fontSize: "4rem",
                              color: `${urStorePalette.greys.default}`,
                            }}
                          />

                          <Typography
                            variant="subtitle2"
                            color={`${urStorePalette.greys.dark}`}
                          >
                            Please select a product
                          </Typography>
                        </Stack>
                      ) : (
                        <CardMedia
                          component="img"
                          height="300"
                          image={getTrainingMediaUrl()}
                          sx={{
                            objectFit: "contain",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            width: "min-content",
                            p: 2,
                            borderRadius: 2,
                          }}
                        />
                      )
                    }
                    content={
                      trainingMode ? (
                        <Stack
                          width={"100%"}
                          sx={{ mr: 4, mb: 2 }}
                          alignItems={"self-start"}
                        >
                          <Typography fontWeight={500} sx={{ mb: 2 }}>
                            <Stack
                              direction="row"
                              alignItems={"center"}
                              gap={1}
                            >
                              {/* Previously Saved Correction */}
                              {activeCapturePositionAnnotation?.TrainingImage
                                ?.Enabled &&
                                allProducts.find(
                                  (option) =>
                                    option.UPC ===
                                    activeCapturePositionAnnotation
                                      ?.TrainingImage?.UPC
                                ) && (
                                  <>
                                    <CheckCircle
                                      fontSize="small"
                                      color="success"
                                    />
                                    Saved
                                  </>
                                )}

                              {/* Previously Removed Correction */}
                              {!activeCapturePositionAnnotation?.TrainingImage
                                ?.Enabled &&
                                allProducts.find(
                                  (option) =>
                                    option.UPC ===
                                    activeCapturePositionAnnotation
                                      ?.TrainingImage?.UPC
                                ) && (
                                  <>
                                    <Cancel fontSize="small" color="error" />
                                    Removed
                                  </>
                                )}

                              {/* No Saved Correction (Default) */}
                              {activeCapturePositionAnnotation?.TrainingImage ===
                                null &&
                                allProducts.find(
                                  (option) =>
                                    option.UPC ===
                                    activeCapturePositionAnnotation?.FoundUpc
                                ) && (
                                  <>
                                    <Pending
                                      fontSize="small"
                                      sx={{
                                        color: urStorePalette.greys.default,
                                      }}
                                    />
                                    No Correction
                                  </>
                                )}
                            </Stack>
                          </Typography>

                          <Autocomplete
                            fullWidth
                            value={selectOptionValue}
                            onChange={(event, newValue) => {
                              setSelectOptionValue(newValue);
                              setSelectedProductId(newValue?.ProductId);
                            }}
                            sx={{
                              mr: 4,
                              backgroundColor: urStorePalette.greys.lightest,
                            }}
                            isOptionEqualToValue={isOptionEqualToValue}
                            size="small"
                            open={openAutoComplete}
                            onOpen={() => {
                              setOpenAutoComplete(true);
                            }}
                            onClose={() => {
                              setOpenAutoComplete(false);
                            }}
                            onInputChange={(event, value, reason) => {
                              if (reason === "clear") {
                                setSelectedProductData(undefined);
                              }
                            }}
                            getOptionLabel={(option) => option.UPC}
                            options={allProducts}
                            loading={loading}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Actual Product"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                          />

                          {/* Correction Side Dropdown */}
                          <TextField
                            select
                            fullWidth
                            label="Product Side"
                            value={productSide}
                            onChange={(event) => {
                              console.log(event.target.value);
                              setProductSide(parseInt(event.target.value));
                            }}
                            sx={{
                              mt: 2,
                              backgroundColor: urStorePalette.greys.lightest,
                            }}
                            size="small"
                          >
                            {Object.values(RevisionSides).map((rs, i) => (
                              <MenuItem key={i} value={rs.value}>
                                {`${rs.label} (${rs.mask ?? rs.value})`}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Stack>
                      ) : (
                        <Typography variant="body1" fontWeight="bold">
                          Expected
                        </Typography>
                      )
                    }
                  />
                ) : (
                  <Skeleton
                    variant="rectangular"
                    width={330}
                    height={460}
                    animation="wave"
                  />
                )}
              </Grid>
            </Grid>

            {/* Footer  */}
            <Grid
              display={"flex"}
              sx={{ margin: "35px 30px 0 30px" }}
              justifyContent={!trainingMode ? "space-between" : "end"}
            >
              {trainingMode ? (
                <Grid item xs={12}>
                  <Grid container spacing={4} justifyContent="space-between">
                    <Grid item>
                      {!loading ? (
                        <Button
                          startIcon={<ArrowBack />}
                          onClick={handleCancelButton}
                        >
                          Back
                        </Button>
                      ) : (
                        <Skeleton variant="rectangular">
                          <Button size="large" />
                        </Skeleton>
                      )}
                    </Grid>
                    <Grid item>
                      {!loading ? (
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="end"
                          alignItems={"center"}
                        >
                          <Grid item>
                            {!context?.revision?.Planogram?.PlanogramCategory
                              ?.Name && (
                              <Chip
                                icon={
                                  !context?.revision?.Planogram
                                    ?.PlanogramCategory?.Name ? (
                                    <InfoOutlined />
                                  ) : undefined
                                }
                                color={
                                  context?.revision?.Planogram
                                    ?.PlanogramCategory?.Name
                                    ? "default"
                                    : "error"
                                }
                                label={
                                  context?.revision?.Planogram
                                    ?.PlanogramCategory?.Name ? (
                                    <>
                                      <b>Product Category: </b>
                                      {
                                        context?.revision.Planogram
                                          .PlanogramCategory.Name
                                      }
                                    </>
                                  ) : (
                                    <>
                                      <b>No Category</b> | Set a Category via{" "}
                                      <b>Manage Planograms</b> before making
                                      changes.
                                    </>
                                  )
                                }
                              />
                            )}
                          </Grid>
                          <Grid item>
                            <Fade
                              in={
                                activeCapturePositionAnnotation?.TrainingImage
                                  ?.Enabled
                              }
                              unmountOnExit
                            >
                              <LoadingButton
                                disabled={Boolean(
                                  !context?.revision?.Planogram
                                    ?.PlanogramCategory?.Name
                                )}
                                startIcon={<DeleteForever />}
                                color="error"
                                variant="contained"
                                loading={apiRequestLoading}
                                onClick={() => updateTrainingImage(false)}
                              >
                                Remove Image
                              </LoadingButton>
                            </Fade>
                          </Grid>
                          <Grid item>
                            <LoadingButton
                              loading={apiRequestLoading}
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                // If we don't already have a training image, we need to generate one.
                                // Future requests can just tweak the saved UPC and the enabled state.
                                if (
                                  activeCapturePositionAnnotation?.TrainingImage
                                ) {
                                  updateTrainingImage(true);
                                } else {
                                  setTrainingImage();
                                }
                              }}
                              disabled={
                                selectOptionValue?.UPC == null ||
                                Boolean(
                                  !context?.revision?.Planogram
                                    ?.PlanogramCategory?.Name
                                )
                              }
                              startIcon={<SaveAltOutlinedIcon />}
                            >
                              Save
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      ) : (
                        <Skeleton variant="rectangular" width="18rem">
                          <Button size="large" />
                        </Skeleton>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={1}>
                    {auth.adminRole &&
                      (!loading ? (
                        <Button
                          color="primary"
                          onClick={() => setTrainingMode(!trainingMode)}
                          // if admin, Train button will be visible.
                          sx={{
                            display:
                              userCurrentRole === "admin" ? undefined : "none",
                          }}
                          startIcon={<DifferenceOutlined />}
                        >
                          Train
                        </Button>
                      ) : (
                        <Skeleton variant="rectangular">
                          <Button size="large" />
                        </Skeleton>
                      ))}
                  </Grid>
                  <Grid item>
                    {!loading ? (
                      <Stack direction="row" gap={2}>
                        {productButtonStates.map((button) => (
                          <LoadingButton
                            loading={button.id === loadingButtonId}
                            key={button.id}
                            disabled={userCurrentRole === "reporting"}
                            variant={
                              activeProductButton?.id === button.id
                                ? "contained"
                                : "outlined"
                            }
                            color={
                              activeProductButton.id === button.id
                                ? (activeProductButton.color as any)
                                : "primary"
                            }
                            onClick={() => handleUpdateProductStatus(button)}
                            startIcon={<button.icon />}
                          >
                            {button.label}
                          </LoadingButton>
                        ))}
                      </Stack>
                    ) : (
                      <Skeleton variant="rectangular" width="18rem">
                        <Button size="large" />
                      </Skeleton>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Grid>

        {/* Icon right */}
        <Grid display="flex" justifyContent="flex-start" item xs={1}>
          <IconButton
            onClick={goToNextPosition}
            disabled={loading || isCurrentCapturePositionLastItem}
            size="large"
            sx={{
              color: "white",
              fontSize: 50,
              marginLeft: 5,
              background: "#a19f9c",
            }}
          >
            <ArrowForward fontSize="inherit" />
          </IconButton>
        </Grid>
      </Grid>
    </Modal>
  );
}
