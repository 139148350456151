import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { AddCircleOutline } from "@mui/icons-material";
import ErrorDialog from "../../../components/ErrorDialog";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { ColDef } from "ag-grid-community";
import getAdminAllPlanograms from "../../../api/planograms/getAdminAllPlanograms";
import AdminCreatePlanogramDialog from "./AdminCreatePlanogramDialog";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";

class DisplayPlanogram {
  constructor(
    public PlanogramId: string,
    public Name: string,
    public DisplayName: string,
    public CreatedAt: Date,
    public LastModified: Date
  ) {}
}

const AdminPlanogramsPage = () => {
  // Util
  const navigate = useNavigate();

  // State
  const [planograms, setPlanograms] = useState<DisplayPlanogram[]>([]);
  const [addNewPlanogram, setAddNewPlanogram] = useState<boolean>(false);
  const [pageError, setPageError] = useState<String>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredRows, setFilteredRows] = useState(planograms);
  const [loading, setLoading] = useState<boolean>(false);

  // AG State
  const [colDefs] = useState<ColDef<DisplayPlanogram>[]>([
    { field: "Name", flex: 1 },
    { field: "DisplayName", flex: 1 },
    {
      field: "CreatedAt",
      flex: 1,
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "LastModified",
      sort: 'desc',
      flex: 1,
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
  ]);

  // Effects
  useEffect(() => {
    getAllAdminPlanograms();
  }, []);

  useEffect(() => {
    // handles row updates based on the search values
    if (searchValue.length > 0) {
      setFilteredRows(
        planograms.filter((row) => {
          const values = Object.values(row).join("").toLowerCase();
          return values.includes(searchValue.toLowerCase());
        })
      );
    } else {
      setFilteredRows(planograms);
    }
  }, [planograms, searchValue]);

  const getAllAdminPlanograms = async () => {
    try {
      setLoading(true);
      const data = await getAdminAllPlanograms();

      if (data) {
        setPlanograms(
          data
            .filter((p) => p.PlanogramId !== null)
            .map(
              (planogram: any) =>
                new DisplayPlanogram(
                  planogram.PlanogramId,
                  planogram.Name,
                  planogram.DisplayName,
                  planogram.CreatedAt,
                  planogram.LastModified
                )
            )
        );
      } else {
        console.error("Request failed");
      }
    } catch (error) {
      console.error("Request failed");
    } finally {
      setLoading(false);
    }
  };

  function openEditPlanogramPage(planogramID: string) {
    navigate({
      pathname: "./edit",
      search: "?planogram=" + planogramID,
    });
  }

  function closeNewPlanogramDialog(newPlanogramId: string | undefined) {
    if (newPlanogramId) {
      openEditPlanogramPage(newPlanogramId);
    } else {
      setAddNewPlanogram(false);
    }
  }

  function onCloseErrorDialog() {
    setPageError("");
  }

  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Page Content */}
      <AgGridWrapper
        id="admin-plano"
        columnDefs={colDefs}
        rowData={planograms ?? []}
        quickSearch
        export
        rememberPageNumber
        loading={loading}
        onRowClicked={(row: any) =>
          openEditPlanogramPage(row.data.PlanogramId.toString())
        }
        plugins={
          <Button
            variant="contained"
            onClick={() => setAddNewPlanogram(true)}
            startIcon={<AddCircleOutline />}
          >
            New Planogram
          </Button>
        }
      />

      {/* New Planogram Dialog */}
      <AdminCreatePlanogramDialog
        onClose={closeNewPlanogramDialog}
        open={addNewPlanogram}
        refreshPlanograms={getAllAdminPlanograms}
      />

      {/* Error Dialog */}
      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </Box>
  );
};

export default AdminPlanogramsPage;
