import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import styles from "./AppLayout.module.scss";
import MainNav from "./MainNav/MainNav";
import Header from "./Header/Header";
import { useEffect, useState } from "react";
import { useTeamStore } from "../../stores/teams/TeamStore";
import useLayoutStore from "../../stores/layout/LayoutStore";
import { adminNavItems, navItems } from "./MainNav/NavRoutes";
import { signOutChannel } from "../../features/authentication/contexts/AuthContext";
import {
  KeyboardArrowLeftRounded,
  KeyboardArrowRightRounded,
} from "@mui/icons-material";
import { urStorePalette } from "../../themes/urStoreTheme";

// Utility function that recursively searches through nav items (and their children)to find a matching path.
// Yeah, this is overkill and I don't know why I managed route changes like this. Refactor eventually. Eveeeentually...
function findNavItem(items: any[], path: string): any {
  for (const item of items) {
    if (item.path === path) {
      return item;
    }
    if (item.children) {
      const childItem = findNavItem(item.children, path);
      if (childItem) {
        return childItem;
      }
    }
  }
  return null;
}


function AppLayout() {
  // Hooks
  const theme = useTheme();
  const mq = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedTeam } = useTeamStore();
  const { setRouteMetadata, setSubRoute, setDrawerOpen, drawerOpen } =
    useLayoutStore();

  // Util Func
  const handleHotkey = (event: KeyboardEvent) => {
    if (event.key === "]") {
      // Check if any input element is currently focused
      const isInputFocused =
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement ||
        document.activeElement instanceof HTMLSelectElement;

      !isInputFocused && setDrawerOpen(true);
    }

    if (event.key === "[") {
      // Check if any input element is currently focused
      const isInputFocused =
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement ||
        document.activeElement instanceof HTMLSelectElement;

      !isInputFocused && setDrawerOpen(false);
    }
  };

  useEffect(() => {
    const handleSignoutMessage = () => {
      navigate("/login");
    };

    signOutChannel.onmessage = handleSignoutMessage;

    // Cleanup function to unsubscribe from the signOutChannel
    return () => {
      signOutChannel.onmessage = null;
    };
  }, []);

  // Hotkey listener setup + teardown
  useEffect(() => {
    window.addEventListener("keydown", handleHotkey);

    return () => {
      window.removeEventListener("keydown", handleHotkey);
    };
  }, [drawerOpen]);

  // State
  const [adminPageSelected, setAdminPageSelected] = useState<boolean>(false);

  // Effect
  useEffect(() => {
    // When route changes, try to align the header content with the <Outlet/> being rendered.
    if (location.pathname.split("/")[1] === "admin") {
      // Admin Routes
      const newRouteAlias = "/admin/" + location.pathname.split("/")[2];
      const navItem = findNavItem(adminNavItems, newRouteAlias);
      setRouteMetadata(navItem ?? null);
      setSubRoute(null);
    } else {
      // Non-Admin Routes
      const newRouteAlias = "/" + location.pathname.split("/")[1];
      const navItem = findNavItem(navItems, newRouteAlias);
      setRouteMetadata(navItem ?? null);
      setSubRoute(null);
    } 
  }, [location.pathname, setRouteMetadata]);

  useEffect(() => {
    if (!mq) {
      setDrawerOpen(false);
    }
  }, [mq]);

  // Functions
  function handlePageSelectionChanged(isAdminPage: boolean) {
    setAdminPageSelected(isAdminPage);
  }

  function getLayoutStyle(): string {
    if (mq) {
      return styles.appLayout_isDesktop;
    } else {
      return styles.appLayout_isMobile;
    }
  }

  return (
    <div
      style={{
        // TODO: This is confusing as it overrides the css module for appLayout.
        // TODO: "I'll fix it later I promise" - Dec (May 10th, 2024)
        gridTemplateColumns: `minmax(56px, ${drawerOpen ? "260" : "56"}px) 1fr`,

        transition: "all 0.2s ease",
      }}
      className={`${styles.appLayout} ${getLayoutStyle()}`}
    >
      <div
        className={styles.appLayout__sidebar}
      >
        {/* Sidebar Control, static position. */}
        <Tooltip
          title={
            drawerOpen ? (
              <>
                Close Drawer <b>[</b>
              </>
            ) : (
              <>
                Open Drawer <b>]</b>
              </>
            )
          }
        >
          <IconButton
            onClick={() => setDrawerOpen(!drawerOpen)}
            size="small"
            sx={{
              display: !mq ? "none" : "flex",
              ":hover": {
                backgroundColor: "var(--brand-tertiary)",
              },
              height: 20,
              width: 20,
              position: "fixed",
              zIndex: 5,
              left: drawerOpen ? 249 : 46,
              top: 49,
              color: urStorePalette.greys.lightest,
              backgroundColor: "var(--brand-tertiary)",
              border: "0.5px solid white",
              transition: "0.2s left ease",
            }}
          >
            {drawerOpen ? (
              <KeyboardArrowLeftRounded sx={{ height: 14, width: 14 }} />
            ) : (
              <KeyboardArrowRightRounded sx={{ height: 14, width: 14 }} />
            )}
          </IconButton>
        </Tooltip>

        {/* Force a fixed-width sidebar on desktop, and a mini-sidebar on small screens. */}
        <Box
          sx={{
            position: "fixed",
            width: drawerOpen ? { sm: "260px", xs: "52px" } : "56px",
            mt: { xs: 4, sm: 0 },
            height: '100%',
            overflowY: 'auto',
            overflowX: 'hidden',
            background: '#090038'
          }}
        >
          {/* Hide logo and env in mob view, mt to float menu down below header. */}
          <Box>
            <div className={styles.appLayout__sidebarLogo}>
              <img
                style={{
                  width: drawerOpen ? "140px" : "36px",
                  marginLeft: drawerOpen ? 0 : -8,
                  marginTop: drawerOpen ? 0 : -8,
                }}
                src={
                  drawerOpen
                    ? "/URSTORE_CROP_2024.png"
                    : "/URSTORE_CROP_2024_SMALL.png"
                }
                alt="URStore Logo"
              />
              {drawerOpen && (
                <span className={styles.appLayout__env}>{`${
                  process.env.REACT_APP_ENV === "PRODUCTION"
                    ? ""
                    : process.env.REACT_APP_ENV
                }`}</span>
              )}
            </div>
          </Box>

          <MainNav
            selectedTeam={selectedTeam}
            handlePageSelectionChanged={handlePageSelectionChanged}
          />
        </Box>
      </div>

      <main role="main" className={styles.appLayout__main}>
        <Box mb={3}>
          <Header />
        </Box>
        <Outlet />
      </main>
    </div>
  );
}

export { AppLayout };

