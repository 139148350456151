import axiosInstance from "../axiosInstanceConfig";

export interface PlanogramLayout {
  LayoutNameId: string;
  Name: string;
  CreatedAt: Date;
  LastModified: Date;
}

export const getLayoutNames = async () => {
  const response = await axiosInstance.get<PlanogramLayout[]>(
    `layoutname/AdminGetLayoutNameList`
  );

  return response.data;
};
