import { useEffect } from "react";
import {
  FormControl,
  Grid,
  IconButton,
  TextField,
  Autocomplete,
  Popover,
} from "@mui/material";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import { useMasterFilterStore } from "../../../../../stores/metrics/MasterFilterStore";
import { FilterInputStyle } from "../../../helpers/DashboardHelpers";
import { CloseOutlined } from "@mui/icons-material";
import { useMetricsFilterOptions } from "../../../../../stores/metrics/MetricsOptionsStore";
import { urStorePalette } from "../../../../../themes/urStoreTheme";
import MultiTag from "../../LocationFilter/MultiTag";

interface BayCountFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const BayCountFilterModal = (props: BayCountFilterModalProps) => {
  const setBayCount = usePlanogramFilterStore((state) => state.setBayCount);
  const bayCount = usePlanogramFilterStore((state) => state.bayCount);
  const open = Boolean(props.anchorEl);
  const id = open ? "baycount-filter" : undefined;

  // Master
  const { syncedFilters, setSyncFilters } = useMasterFilterStore();

  // Options
  const { data } = useMetricsFilterOptions();

  // Effects
  useEffect(() => {
    // TODO Workaround, centralise filters.
    setBayCount(syncedFilters?.bayCount ?? null);
  }, [syncedFilters]);

  return (
    <div>
      <Popover
        sx={{ mt: 2 }}
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid gap={4} container sx={{ p: 4, width: "280px" }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                options={data?.BayCounts || []}
                getOptionLabel={(option) => option.toString()}
                value={bayCount !== null ? (Array.isArray(bayCount) ? bayCount : [bayCount]) : []}
                renderTags={(tagValues, getTagProps) =>
                  tagValues.map((option, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <MultiTag {...getTagProps({ index })} label={option} />
                  ))
                }
                onChange={(event, value, reason) => {
                  if (reason === "clear") {
                    setBayCount(null);
                    setSyncFilters(["bayCount"], null);
                  } else {
                    // Convert any string values to numbers
                    const numericValues = value.map(v => typeof v === 'string' ? parseInt(v, 10) : v);
                    setBayCount(numericValues);
                    setSyncFilters(
                      ["bayCount"],
                      // Super fun casting nums to strings.
                      numericValues.length > 0 ? numericValues as unknown as string[] : null
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Bay Count"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...FilterInputStyle,
                        backgroundColor: params.disabled
                          ? urStorePalette.greys.light
                          : urStorePalette.greys.lightest,
                      },
                    }}
                    required
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default BayCountFilterModal;
