import axiosInstance from "../axiosInstanceConfig";

export interface ProductEmbedding {
  ProductEmbeddingId: string;
  Tag: string | null;
  CreatedAt: string;
}

// Returns all products included on planograms for a given team.
const getProductEmbeddings = async (productId: string) => {
  const params = new URLSearchParams();
  params.append("productId", productId);

  const response = await axiosInstance.get<ProductEmbedding[]>(
    `products/AdminListProductEmbeddings?${params.toString()}`
  );

  return response.data;
};

export default getProductEmbeddings;
