import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import getAdminAllPlanograms from "../../../../api/planograms/getAdminAllPlanograms";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import postAdminAssignPlanogramToStore, {
  AssignPlanogramRequest,
} from "../../../../api/planograms/postAdminAssignPlanogramToStore";
import { LoadingButton } from "@mui/lab";
import { Planogram } from "../../../../models/planograms/PlanogramType";
import { enqueueSnackbar } from "notistack";

interface PlanogramAssignMenuProps {
  open: boolean;
  storeId: string;
  handleClose: () => void;
  handleAssign: () => void;
  currentlyAssigned?: string[];
}

export const PlanogramAssignMenu = (props: PlanogramAssignMenuProps) => {
  // state
  const [planos, setPlanos] = useState<Planogram[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [assignLoading, setAssignLoading] = useState<boolean>(false);
  // TODO Future PR: Error not fully utilised yet. This component will be made generic later.
  // 23-08-24: Still not generic, lol.
  const [error, setError] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  // effects
  useEffect(() => {
    // fetch func
    const fetchAdminPlanos = async () => {
      setDataLoading(true);
      try {
        const data = await getAdminAllPlanograms();

        if (data) {
          setPlanos(data);
        }
      } catch (e) {
        setError(true);
      } finally {
        setDataLoading(false);
      }
    };

    // call fetch
    fetchAdminPlanos();
  }, []);

  // functions
  function handleClose() {
    setSelectedIds([]);
    props.handleClose();
  }

  async function handleAssign() {
    let reqBod: AssignPlanogramRequest[] = [];

    selectedIds.forEach((id) => {
      reqBod.push({
        Deactivated: false,
        Direction: false,
        Notes: "",
        PlanogramRevisionId: planos.find((p) => p.PlanogramId === id)!
          .LatestRevisionId,
        StoreId: props.storeId,
      });
    });

    try {
      setAssignLoading(true);
      const resp = await postAdminAssignPlanogramToStore(reqBod);

      if (resp || resp === "") {
        enqueueSnackbar("Assignment Added", { variant: "success" });

        // prop close
        props.handleAssign();
        handleClose();
      }
    } catch (e) {
      enqueueSnackbar("Failed to add Assignment. Please contact support.", {
        variant: "error",
      });

      setError(true);
    } finally {
      setAssignLoading(false);
    }
  }

  return (
    <>
      {/* Dialog */}
      <Dialog open={props.open} onClose={handleClose} fullWidth>
        <DialogTitle>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            Assign Planograms
            <TextField
              label="Search..."
              value={search}
              size="small"
              onChange={(e) => setSearch(e.target.value)}
            />
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true} sx={{ height: "420px" }}>
          {dataLoading ? (
            <Box
              sx={{ width: "100%", height: "100%" }}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Stack>
              <List>
                {planos
                  .filter(
                    (p) =>
                      p.Name.toLowerCase().includes(search) &&
                      p.LatestRevisionId !==
                        "00000000-0000-0000-0000-000000000000" &&
                      !props.currentlyAssigned?.includes(p.LatestRevisionId)
                  )
                  .map((p) => (
                    <ListItemButton
                      key={p.PlanogramId}
                      onClick={() => {
                        // Update Selected Ids
                        let cloneIds = new Set(selectedIds);
                        if (cloneIds.has(p.PlanogramId)) {
                          cloneIds.delete(p.PlanogramId);
                        } else {
                          cloneIds.add(p.PlanogramId);
                        }
                        setSelectedIds(Array.from(cloneIds));
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={
                            selectedIds.find((id) => id === p.PlanogramId) !==
                            undefined
                          }
                        />
                      </ListItemIcon>

                      <ListItemText>
                        {p.Name} | {formatDisplayDate(p.CreatedAt)}
                      </ListItemText>
                    </ListItemButton>
                  ))}
              </List>
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Chip
            label={
              <>
                <b>{selectedIds.length}</b> Selected
              </>
            }
          />

          {/* This is a cute bit of css to basically float stuff above it left, and stuff below right. */}
          <div style={{ flex: "1 1 0" }} />

          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            loading={assignLoading}
            onClick={handleAssign}
            variant="contained"
          >
            Assign
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
