import {
  Box,
  Button,
  Chip,
  Stack,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorDialog from "../../../components/ErrorDialog";
import {
  CircleRounded,
  DevicesRounded,
  FileCopyRounded,
  FileUploadRounded,
  WorkspacesRounded,
} from "@mui/icons-material";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { OpenInBrowserOutlined } from "@mui/icons-material";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import useTeamMemberStore, {
  TeamMember,
} from "../../../stores/teams/TeamMemberStore";
import { ColDef } from "ag-grid-community";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { ToggleUserActivationModal } from "../../team/components/ToggleUserActivationModal";
import { enqueueSnackbar } from "notistack";
import { grey } from "@mui/material/colors";
import postAdminUploadUsersViaCsv from "../../../api/teams/postAdminImportUsersViaCsv";
import { LoadingButton } from "@mui/lab";
import getAdminAllUsers, {
  UserAllTeams,
} from "../../../api/users/getAdminAllUsers";
import { formatDistance } from "date-fns";
import styled from "@emotion/styled";

interface TeamMemberColumn extends UserAllTeams {
  Actions?: null;
}

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

const AdminUsersPage = (props: any) => {
  // Store
  const { roleOnTeam } = useTeamStore();

  // Prop Destructuring
  const selectedTeam = props.selectedTeam;

  // Util
  const navigate = useNavigate();
  const colDefs: ColDef<TeamMemberColumn>[] = [
    {
      field: "Email",
      headerName: "User Details",
      cellRenderer: (params: any) => (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            variant="body2"
            color={params.data.Fullname ? "inherit" : grey[400]}
          >
            {params.data.Fullname ?? "No Name Set"}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {params.data.Email}
          </Typography>
        </Box>
      ),
    },
    {
      field: "UserId",
      headerName: "Invite Code",
      flex: 1.0,
      cellRenderer: (params: any) => (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            cursor: "pointer",
          }}
          onClick={() => {
            navigator.clipboard.writeText(params.value);
            enqueueSnackbar("Invite Code Copied", { variant: "success" });
          }}
        >
          <FileCopyRounded fontSize="small" sx={{ color: grey[700] }} />
          <Typography
            variant="overline"
            sx={{
              fontWeight: "400",
              transition: "font-weight 0.1s ease-in-out",
              ":hover": { fontWeight: "600" },
              color: grey[700],
            }}
          >
            {params.value}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Teams",
      headerName: "Enrolled Teams",
      flex: 1,
      cellRenderer: (params: any) => {
        const teams = params.data.Teams;

        return (
          <Tooltip
            title={
              <Typography
                component="div"
                variant="caption"
                style={{
                  whiteSpace: "pre",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {teams.map((team: any, index: number) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", justifyContent: "space-between" }}
                    gap={2}
                  >
                    <span>{team.TeamName}</span>
                    <Box
                      sx={{ display: "flex", alignItems: "center" }}
                      gap={1.5}
                    >
                      <Typography variant="caption" sx={{ opacity: 0.6 }}>
                        {team.Role}
                      </Typography>
                      <CircleRounded
                        sx={{ fontSize: 10 }}
                        color={team.Active ? "success" : "error"}
                      />
                    </Box>
                  </Box>
                ))}
              </Typography>
            }
            placement="top"
          >
            <Chip
              size="small"
              icon={<WorkspacesRounded />}
              label={
                <Box>
                  <b>{teams.length}</b> {teams.length === 1 ? "Team" : "Teams"}
                </Box>
              }
            />
          </Tooltip>
        );
      },
    },
    {
      field: "AppVersion",
      headerName: "Devices",
      flex: 1,
      cellRenderer: (params: any) => {
        const devices = params.data.AppVersion;

        return (
          <NoMaxWidthTooltip
            title={
              <Typography
                component="div"
                variant="caption"
                style={{
                  whiteSpace: "pre",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {devices.map((device: any, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                    gap={2}
                  >
                    <span>{device.DeviceName}</span>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {`- ${device.AppVersion} -`}
                    </Typography>
                    <Typography
                      variant="caption"
                      sx={{ opacity: 0.6, fontStyle: 'italic' }}
                      fontSize={10}
                    >
                      {`Last updated ${formatDistance(
                        new Date(device.LastDateUpdated),
                        new Date()
                      )} ago.`}
                    </Typography>
                  </Box>
                ))}
              </Typography>
            }
            placement="top"
          >
            <Chip
              size="small"
              icon={<DevicesRounded />}
              label={
                <Box>
                  <b>{devices.length}</b>{" "}
                  {devices.length === 1 ? "Device" : "Devices"}
                </Box>
              }
            />
          </NoMaxWidthTooltip>
        );
      },
    },
    {
      field: "Actions",
      sortable: false,
      flex: 0.5,
      minWidth: 230,
      cellRenderer: (row: any) => (
        <Box>
          <Button
            disabled={roleOnTeam !== "admin"}
            sx={{ mr: 2 }}
            size="small"
            variant="outlined"
            startIcon={<OpenInBrowserOutlined />}
            onClick={() => {
              openTeamMemberPage(
                row.data.UserId,
                row.data.Fullname,
                row.data.Email
              );
            }}
          >
            Open
          </Button>
        </Box>
      ),
    },
  ];

  const { fetchTeamMembers } = useTeamMemberStore();

  // State
  const [pageError, setPageError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    useState<boolean>(false);
  const [selectedTeamMember, setSelectedTeamMember] =
    useState<TeamMember | null>(null);
  const [csvUploadLoading, setCsvUploadLoading] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<UserAllTeams[]>([]);

  // Effects
  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        setLoading(true);
        const data = await getAdminAllUsers();
        setAllUsers(data);
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAllUsers();
  }, [selectedTeam]);

  // Functions
  function onCloseErrorDialog() {
    setPageError("");
  }

  // This is a pretty scuffed way of not having to make a new API call to get the full user details.
  function openTeamMemberPage(userId: string, fullname: string, email: string) {
    navigate({
      pathname: "./edit",
      search: "?userId=" + userId + "&fullname=" + fullname + "&email=" + email,
    });
  }

  // Add new function to handle CSV upload
  const handleCsvUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setCsvUploadLoading(true);
      await postAdminUploadUsersViaCsv(file);
      enqueueSnackbar("Users Uploaded Successfully", { variant: "success" });
      // Refresh the users list
      fetchTeamMembers(selectedTeam);
    } catch (error: any) {
      enqueueSnackbar(error.response.data.detail || "Import Failed", {
        variant: "error",
      });
    } finally {
      setCsvUploadLoading(false);
    }
  };

  return (
    <>
      <Box sx={ResponsiveContainerStyle}>
        <AgGridWrapper
          rowHeight={52}
          id="admin-all-users"
          rowData={allUsers}
          columnDefs={colDefs}
          quickSearch
          rememberPageNumber
          loading={loading}
          plugins={
            <Stack direction="row" spacing={2}>
              <Tooltip title="Import up to 50 users at a time. The first row must be: 'Email,FirstName,LastName' exactly.">
                <LoadingButton
                  loading={csvUploadLoading}
                  variant="contained"
                  component="label"
                  startIcon={<FileUploadRounded />}
                  disabled={roleOnTeam !== "admin"}
                >
                  Import via CSV
                  <input
                    type="file"
                    hidden
                    accept=".csv"
                    onChange={handleCsvUpload}
                  />
                </LoadingButton>
              </Tooltip>
            </Stack>
          }
        />
      </Box>

      <ToggleUserActivationModal
        user={selectedTeamMember}
        open={isDeactivateUserModalOpen}
        onClose={() => {
          setIsDeactivateUserModalOpen(false);
          setSelectedTeamMember(null);
        }}
        reloadTeamMembers={() => fetchTeamMembers(selectedTeam)}
        selectedTeam={selectedTeam}
      />

      <ErrorDialog
        errorMessage={pageError}
        open={pageError.length > 0}
        onClose={onCloseErrorDialog}
      />
    </>
  );
};

export default AdminUsersPage;
