import axiosInstance from "../axiosInstanceConfig";

export interface EmbeddingUpload {
  Base64Embedding: string;
  Tag: number;
}

const postCreateEmbedding = async (upc: string, file: File) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();
    params.append("UPC", upc);

    const reader = new FileReader();

    reader.onload = async function (event) {
      const content = event.target?.result as string;
      try {
        const parsedUpload = JSON.parse(content) as EmbeddingUpload;

        const response = await axiosInstance.post(
          `products/AdminAddProductEmbedding?${params.toString()}`,
          {
            Base64Embedding: parsedUpload?.Base64Embedding,
            Tag: parsedUpload?.Tag,
          }
        );

        // Resolver
        resolve(response.data);
      } catch (error) {
        console.error("JSON Parse Failed.");
        // Rejecter
        reject(error);
      }
    };

    reader.readAsText(file);
  });
};

export default postCreateEmbedding;
