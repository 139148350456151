import { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Autocomplete,
  Divider,
  Typography,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  Field,
  Form,
  FormikErrors,
  FormikTouched,
  useFormikContext,
} from "formik";
import getMachineLearningCategories, {
  MachineLearningCategory,
} from "../../../api/categories/getCategories";
import { australianStates } from "../../planograms/utils/strings";
import { PlanogramFormData } from "./AdminPlanogramEditFormikWrapper";
import getFixtures, { FixtureType } from "../../../api/fixtures/getFixtures";
import { grey } from "@mui/material/colors";
import {
  getLayoutNames,
  PlanogramLayout,
} from "../../../api/layouts/getLayoutNames";

interface AdminPlanogramEditFormProp {
  values: PlanogramFormData;
  touched: FormikTouched<PlanogramFormData>;
  errors: FormikErrors<PlanogramFormData>;
  isEditMode: boolean;
  onDisabledChanged: (disabled: boolean) => void;
  displayHelperText: boolean;
}

const AdminPlanogramEditForm: React.FC<AdminPlanogramEditFormProp> = ({
  values,
  errors,
  touched,
  isEditMode,
  onDisabledChanged,
  displayHelperText,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState<MachineLearningCategory[]>([]);
  const [fixtureTypes, setFixtureTypes] = useState<FixtureType[]>([]);
  const [planogramLayouts, setPlanogramLayouts] = useState<PlanogramLayout[]>(
    []
  );
  const { isValid, dirty } = useFormikContext<PlanogramFormData>();

  useEffect(() => {
    fetchCategories();
    fetchFixtureTypes();
    fetchPlanogramLayouts();
  }, []);

  useEffect(() => {
    onDisabledChanged(!isValid || !dirty);
  }, [isValid, dirty]);

  // Functions
  const fetchCategories = async () => {
    try {
      const data = await getMachineLearningCategories();
      setCategories(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load planogram Categories.",
      });
    }
  };

  const fetchPlanogramLayouts = async () => {
    try {
      const data = await getLayoutNames();
      setPlanogramLayouts(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load planogram Layouts.",
      });
    }
  };

  const fetchFixtureTypes = async () => {
    try {
      const data = await getFixtures();
      setFixtureTypes(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load Fixture Types.",
      });
    }
  };

  return (
    <Form id="planogram-form">
      <Stack
        direction={{ sm: "column", md: "row" }}
        gap={4}
        sx={{ minWidth: "400px", mt: 1 }}
      >
        <Stack
          spacing={2}
          width={{ sm: "100%", md: "50%" }}
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: 3,
            backgroundColor: "#fff",

            height: 'min-content',
            pb: 6
          }}
          p={4}
        >
          <Stack sx={{pb: 2}}>
            <Typography variant="h6">Details</Typography>
            <Typography variant="caption">
              Properties that control Planogram identification and machine learning.
            </Typography>
          </Stack>
          <Field
            disabled={!isEditMode}
            required
            size="small"
            id="Name"
            name="Name"
            label="Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.Name}
            error={touched.Name && Boolean(errors.Name)}
            helperText={
              (touched.Name && errors.Name) ??
              (displayHelperText &&
                "How the Planogram is identified to reporting managers.")
            }
          />
          <Field
            disabled={!isEditMode}
            size="small"
            id="DisplayName"
            name="DisplayName"
            label="Display Name"
            as={TextField}
            fullWidth
            variant="outlined"
            value={values.DisplayName}
            error={touched.DisplayName && Boolean(errors.DisplayName)}
            helperText={
              (touched.DisplayName && errors.DisplayName) ??
              (displayHelperText &&
                "How the Planogram will be displayed to mobile app users.")
            }
          />
          <FormControl fullWidth required disabled={!isEditMode} size="small">
            <InputLabel id="category-select-label">ML Category</InputLabel>
            <Field
              fullWidth
              as={Select}
              id="PlanogramCategoryId"
              name="PlanogramCategoryId"
              label="Category"
              value={values.PlanogramCategoryId}
            >
              {categories.map((category) => (
                <MenuItem key={category.Id} value={category.Id}>
                  {category.Name}
                </MenuItem>
              ))}
            </Field>
            <FormHelperText
              error={
                touched.PlanogramCategoryId &&
                Boolean(errors.PlanogramCategoryId)
              }
            >
              The Machine Learning dataset that will be used when creating
              captures of this Planogram.
              {touched.PlanogramCategoryId && errors.PlanogramCategoryId}
            </FormHelperText>
          </FormControl>
          <FormControl disabled={!isEditMode} size="small" fullWidth>
            <InputLabel id="demo-simple-select-label-2">State</InputLabel>
            <Field
              as={Select}
              id="State"
              name="State"
              label="State"
              value={values.State || ""}
            >
              <MenuItem key={"empty-state-selection"} value={""}>
                <em>None</em>
              </MenuItem>
              {australianStates.map((state) => (
                <MenuItem key={state.value} value={state.value}>
                  {state.label}
                </MenuItem>
              ))}
            </Field>
          </FormControl>

          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="Size"
            name="Size"
            label="Bay Count"
            value={values.Size}
            error={touched.Size && Boolean(errors.Size)}
            helperText={touched.Size && errors.Size}
          />
        </Stack>

        <Stack
          spacing={2}
          width={{ sm: "100%", md: "50%" }}
          sx={{
            border: "1px solid #e0e0e0",
            borderRadius: 3,
            backgroundColor: "#fff",
            height: 'min-content',
            pb: 6
          }}
          p={4}
        >
          <Stack sx={{pb: 2}}>
            <Typography variant="h6">Fixture & Layout</Typography>
            <Typography variant="caption">
              Properties that determine what Stores a Planogram can be assigned to.
            </Typography>
          </Stack>
          <Field name="LayoutNameId">
            {({ field, form }: any) => (
              <Autocomplete
                disabled={!isEditMode}
                options={planogramLayouts}
                getOptionLabel={(option) => option.Name}
                size="small"
                renderOption={(props, option) => (
                  <li {...props}>{option.Name}</li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...field}
                    size="small"
                    label="Layout Name"
                    error={touched.LayoutNameId && Boolean(errors.LayoutNameId)}
                    helperText={touched.LayoutNameId && errors.LayoutNameId}
                  />
                )}
                onChange={(_, value) =>
                  form.setFieldValue("LayoutNameId", value?.LayoutNameId)
                }
                onBlur={field.onBlur}
                value={planogramLayouts.find(layout => layout.LayoutNameId === field.value) || null}
                isOptionEqualToValue={(option, value) => option.LayoutNameId === value.LayoutNameId}
              />
            )}
          </Field>
          <FormControl fullWidth required disabled={!isEditMode} size="small">
            <InputLabel id="category-select-label">Fixture Type</InputLabel>
            <Field
              fullWidth
              as={Select}
              id="FixtureTypeId"
              name="FixtureTypeId"
              label="Fixture Type"
              value={values.FixtureTypeId}
            >
              {fixtureTypes.map((fixture) => (
                <MenuItem
                  key={fixture.FixtureTypeId}
                  value={fixture.FixtureTypeId}
                >
                  {fixture.Name}{" "}
                  <span
                    style={{ color: grey[500], fontSize: 14, paddingLeft: 8 }}
                  >{`${fixture.Type}`}</span>
                </MenuItem>
              ))}
            </Field>
            <FormHelperText
              error={touched.FixtureTypeId && Boolean(errors.FixtureTypeId)}
            >
              {touched.FixtureTypeId && errors.FixtureTypeId}
            </FormHelperText>
          </FormControl>
          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="FixtureHeight"
            name="FixtureHeight"
            label="Fixture Height"
            value={values.FixtureHeight}
            error={touched.FixtureHeight && Boolean(errors.FixtureHeight)}
            helperText={touched.FixtureHeight && errors.FixtureHeight}
          />
          <Field
            disabled={!isEditMode}
            size="small"
            fullWidth
            as={TextField}
            type="number"
            id="FixtureDepth"
            name="FixtureDepth"
            label="Fixture Depth"
            value={values.FixtureDepth}
            error={touched.FixtureDepth && Boolean(errors.FixtureDepth)}
            helperText={touched.FixtureDepth && errors.FixtureDepth}
          />
          <FormControl fullWidth disabled={!isEditMode} size="small">
            <FormLabel id="direction-radio-group-label">Direction</FormLabel>
            <Field
              as={RadioGroup}
              aria-labelledby="direction-radio-group-label"
              name="RightToLeft"
              value={values.RightToLeft ?? "0"}
              row
            >
              <FormControlLabel
                value="0"
                control={<Radio size="small" />}
                label="Left to Right"
                checked={values.RightToLeft === "0"}
              />
              <FormControlLabel
                value="1"
                control={<Radio size="small" />}
                label="Right to Left"
                checked={values.RightToLeft === "1"}
              />
            </Field>
          </FormControl>
        </Stack>
      </Stack>
    </Form>
  );
};

export default AdminPlanogramEditForm;
