import React, { useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import { LoadingButton } from "@mui/lab";

import { AttachFile, Delete, UploadRounded } from "@mui/icons-material";
import DragAndDropUploader from "../../../../components/DragAndDropUploader/DragAndDropUploader";

interface UploadPlanogramRevisionModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (_planogram: File) => void;
  loading: boolean;
}

const UploadPlanogramRevisionModal = (
  props: UploadPlanogramRevisionModalProps
) => {
  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const handleSubmit = async (file: File) => {
    props.onSubmit(file);
  };

  return (
    <Dialog open={props.open} fullWidth>
      <DialogTitle>Add Planogram Revision</DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginY: 2,
        }}
      >
        {!uploadFile && (
          <DragAndDropUploader
            onFileDrop={(file: File | File[]) => setUploadFile(file as File)}
            acceptedFileTypes={[".xml"]}
          />
        )}
        {uploadFile && (
          <Box mt={2} mb={3}>
            <Typography variant="body1" display="flex" alignItems="center">
              <AttachFile fontSize="small" />

              {uploadFile.name}

              <IconButton
                onClick={() => setUploadFile(null)}
                aria-label="delete"
              >
                <Delete />
              </IconButton>
            </Typography>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            props.onClose();
            setUploadFile(null);
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={props.loading}
          variant="outlined"
          disabled={uploadFile === null}
          onClick={() => uploadFile && handleSubmit(uploadFile)}
          startIcon={<UploadRounded />}
        >
          Upload Revision
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadPlanogramRevisionModal;
