import {
  Box,
  Fade,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { FixedSizeList as VirtualizedList } from "react-window";
import VirtualisedMetricRow from "./VirtualisedMetricListItem";
import {
  AggregateEntity,
  AggregateManufacturer,
  DrillMetricPaperStyle,
  LoadingBox,
  TransformToAggregateEntity,
} from "./util";
import { ReactNode, useEffect, useState } from "react";
import {
  OOPAggregate,
  OOSAggregate,
  SOSAggregate,
  useMasterFilterStore,
} from "../../../../stores/metrics/MasterFilterStore";
import { DashboardFilterRequestParams } from "../../helpers/DashboardHelpers";
import { enqueueSnackbar } from "notistack";
import DashboardCard from "../../../../components/DashboardCard";
import { ErrorRounded, SearchRounded } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

interface CoreMetricCardProps {
  // Data
  completeKey: keyof Omit<
    AggregateEntity | AggregateManufacturer,
    "LastCapturedDate"
  >;
  totalKey: keyof Omit<
    AggregateEntity | AggregateManufacturer,
    "LastCapturedDate"
  >;

  // Meta-State
  expanded: boolean;
  sortOrder?: "asc" | "desc";

  // Getters
  getAggregateEntities: (
    _filters: DashboardFilterRequestParams,
    _pageSize?: number
  ) => Promise<OOSAggregate[] | OOPAggregate[] | SOSAggregate[]>;
  aggregateSortFunction?: (
    _aggregates: AggregateEntity[],
    _asc?: boolean
  ) => AggregateEntity[];
  pageSize?: number;

  // Style
  title: string;
  color: string;
  valueDivisor?: number;
  unitSuffix?: string;
  fixedDecimalPoints?: number;
  invertValues?: boolean;

  // Plugins
  plugins?: ReactNode;
}

/**
 * Wrapper Component that combines functionality of DashboardCard and Aggregate Virtualised List components.
 * Necessary to more conveniently hold co-related.
 */
const CoreMetricCard = (props: CoreMetricCardProps) => {
  // State / Stores
  const { syncedFilters } = useMasterFilterStore();
  const [data, setData] = useState<AggregateEntity[]>([]);
  const [filteredData, setFilteredData] = useState<AggregateEntity[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [localSearch, setLocalSearch] = useState<string>("");

  // Constants
  const PAGE_SIZE = props.pageSize ?? 1000;
  const LIST_HEIGHT = 354; //px

  // Effects
  useEffect(() => {
    // Reset Localised Search when changing filters.
    setLocalSearch("");

    const fetchData = async () => {
      try {
        // Loading ON
        setLoading(true);

        // Fetch
        const result = await props.getAggregateEntities(
          syncedFilters!,
          PAGE_SIZE
        );

        // Additional Processing
        if (props.aggregateSortFunction) {
          // Sorted
          setData(
            props.aggregateSortFunction(
              await TransformToAggregateEntity(result),
              props.sortOrder
                ? props.sortOrder === "asc"
                  ? true
                  : false
                : false
            )
          );
        } else {
          // Unsorted
          setData(await TransformToAggregateEntity(result));
        }
      } catch {
        // Empty set if failure.
        setData([]);

        // Snackbar
        enqueueSnackbar("", {
          cta: `Failed to load ${props.title} data.`,
          variant: "warning",
        });
      } finally {
        // Loading OFF
        setLoading(false);
      }
    };

    fetchData();
  }, [syncedFilters]);

  useEffect(() => {
    if (props.expanded === false) {
      // Reset search text if expanded mode is exited.
      setLocalSearch("");
    }
  }, [props.expanded]);

  useEffect(() => {
    // Filter data based on localSearch
    setFilteredData(
      data.filter((item) =>
        Object.values(item)
          .join(" ")
          .toLowerCase()
          .includes(localSearch.toLowerCase())
      )
    );
  }, [data, localSearch]);

  // Functions/Constants
  const PaperStyle = {
    ...DrillMetricPaperStyle,
    height: {
      sm: props.expanded
        ? DrillMetricPaperStyle.height.sm * 1.5
        : DrillMetricPaperStyle.height.sm,
      md: props.expanded
        ? DrillMetricPaperStyle.height.sm * 1.5
        : DrillMetricPaperStyle.height.md,
      lg: props.expanded
        ? DrillMetricPaperStyle.height.lg * 1.5
        : DrillMetricPaperStyle.height.lg,
    },
  };

  return (
    <DashboardCard
      title={props.title}
      plugins={
        <Stack
          direction="row"
          alignItems={"center"}
          gap={props.expanded ? 2 : 1}
        >
          {/* Overflow Warning */}
          <Fade in={data.length === PAGE_SIZE}>
            <Tooltip
              placement="top"
              title={
                <>
                  Large amounts of data were returned for this metric.{" "}
                  <b>{`Only top ${PAGE_SIZE} results are shown on this card.`}</b>{" "}
                  Please refine your search.
                </>
              }
            >
              <ErrorRounded color="warning" />
            </Tooltip>
          </Fade>

          {/* Search Box, only shows when card is expanded. */}
          {props.expanded && (
            <TextField
              value={localSearch}
              size="small"
              variant="standard"
              placeholder="Search..."
              onChange={(e) => {
                setLocalSearch(e.target.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchRounded />
                  </InputAdornment>
                ),
              }}
            />
          )}

          {/* Other plugins provided by props. i.e. the button to expand the card. */}
          {props.plugins}
        </Stack>
      }
    >
      {loading ? (
        <LoadingBox height={`${LIST_HEIGHT}px`} />
      ) : (
        <Paper sx={PaperStyle}>
          {filteredData.length !== 0 ? (
            <VirtualizedList
              height={props.expanded ? LIST_HEIGHT * 1.5 : LIST_HEIGHT}
              itemCount={filteredData.length}
              itemSize={64} // Screenspace (px) allocated to each row.
              width={"98%"}
              itemData={filteredData}
            >
              {({ index, style, data }) => (
                <VirtualisedMetricRow
                  // Virt Props, Do Not Change
                  index={index}
                  style={style}
                  // Row Data
                  data={data}
                  // Key Lookups
                  completeKey={props.completeKey}
                  totalKey={props.totalKey}
                  // Style/Fluff
                  linearProgressColour={props.color}
                  {...(props.valueDivisor && {
                    valueDivisor: props.valueDivisor,
                  })}
                  {...(props.unitSuffix && { unitSuffix: props.unitSuffix })}
                  {...(props.fixedDecimalPoints && {
                    fixedDecimalPoints: props.fixedDecimalPoints,
                  })}
                  {...(props.invertValues && {
                    invertValues: props.invertValues,
                  })}
                />
              )}
            </VirtualizedList>
          ) : (
            <Box
              height={props.expanded ? LIST_HEIGHT * 1.5 : LIST_HEIGHT}
              display="flex"
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Typography color={grey[500]}>No Data Available.</Typography>
            </Box>
          )}
        </Paper>
      )}
    </DashboardCard>
  );
};

export default CoreMetricCard;
