import {
  Box,
  ButtonGroup,
  TextField,
  Popover,
  Button,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import { LoadingButton } from "@mui/lab";
import { AddCircleOutline } from "@mui/icons-material";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import { ResponsiveContainerStyle } from "../../../../helpers/generalUtilities";
import {
  getLayoutNames,
  PlanogramLayout,
} from "../../../../api/layouts/getLayoutNames";
import upsertLayout from "../../../../api/layouts/upsertLayoutName";
import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";

export const ListLayoutNames = () => {
  // Util
  const { enqueueSnackbar } = useSnackbar();

  // ColDefs
  const [colDefs] = useState<ColDef<PlanogramLayout>[]>([
    { field: "Name" },
    {
      field: "CreatedAt",
      cellRenderer: (_data: any) => formatDisplayDate(_data.value),
    },
    {
      field: "LastModified",
      headerName: "Actions",
      cellRenderer: (params: any) => (
        <Button
          variant="contained"
          size="small"
          startIcon={<Edit />}
          onClick={(event) => handleEditClick(event, params.data)}
        >
          Edit
        </Button>
      ),
    },
  ]);

  // Layout List State
  const [layoutNames, setLayoutNames] = useState<PlanogramLayout[]>([]);
  const [layoutNamesLoading, setLayoutNamesLoading] = useState<boolean>(false);

  // Layout Creation State
  const [newLayoutName, setNewLayoutName] = useState<string>("");
  const [newLayoutNameLoading, setNewLayoutNameLoading] =
    useState<boolean>(false);

  // Add new state for editing
  const [editingLayout, setEditingLayout] = useState<PlanogramLayout | null>(
    null
  );
  const [editAnchorEl, setEditAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );
  const [editedName, setEditedName] = useState<string>("");

  // Effects
  useEffect(() => {
    fetchLayoutNames();
  }, []);

  // Function
  const fetchLayoutNames = async () => {
    try {
      setLayoutNamesLoading(true);
      const data = await getLayoutNames();

      setLayoutNames(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load Categories.",
      });
    } finally {
      setLayoutNamesLoading(false);
    }
  };

  const handleUpsertLayoutName = async () => {
    try {
      setNewLayoutNameLoading(true);

      await upsertLayout({ Name: newLayoutName });
      enqueueSnackbar("Success", {
        variant: "success",
        cta: `'${newLayoutName}' was created.`,
      });
      setNewLayoutName("");
      await fetchLayoutNames();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create Layout.",
      });
    } finally {
      setNewLayoutNameLoading(false);
    }
  };

  // Add new functions for editing
  const handleEditClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    layout: PlanogramLayout
  ) => {
    setEditingLayout(layout);
    setEditedName(layout.Name);
    setEditAnchorEl(event.currentTarget);
  };

  const handleEditClose = () => {
    setEditingLayout(null);
    setEditAnchorEl(null);
  };

  const handleEditSave = async () => {
    if (editingLayout) {
      try {
        await upsertLayout({
          LayoutNameId: editingLayout.LayoutNameId,
          Name: editedName,
        });
        enqueueSnackbar("Success", {
          variant: "success",
          cta: `Layout name updated to '${editedName}'.`,
        });
        await fetchLayoutNames();
        handleEditClose();
      } catch {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to update Layout name.",
        });
      }
    }
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <AgGridWrapper
        id="planogram-layouts"
        rowData={layoutNames}
        columnDefs={colDefs}
        loading={layoutNamesLoading}
        quickSearch
        export
        rememberPageNumber
        plugins={
          <ButtonGroup>
            <TextField
              value={newLayoutName}
              onChange={(e) => setNewLayoutName(e.target.value)}
              InputProps={{
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                },
              }}
              label="Layout Name"
              size="small"
            />
            <LoadingButton
              loading={newLayoutNameLoading}
              onClick={handleUpsertLayoutName}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              variant="contained"
              disabled={newLayoutName === ""}
              startIcon={<AddCircleOutline />}
            >
              Add Layout
            </LoadingButton>
          </ButtonGroup>
        }
      />
      <Popover
        open={Boolean(editAnchorEl)}
        anchorEl={editAnchorEl}
        onClose={handleEditClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Stack direction={"column"} spacing={2} p={4}>
          <TextField
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            label="Edit Layout Name"
            size="small"
            sx={{ mb: 2 }}
          />

          <LoadingButton
            onClick={handleEditSave}
            variant="contained"
            disabled={editedName === "" || editedName === editingLayout?.Name}
          >
            Save
          </LoadingButton>
        </Stack>
      </Popover>
    </Box>
  );
};
