import axiosInstance from "../axiosInstanceConfig";
import { PlanogramLayout } from "./getLayoutNames";

const upsertLayout = async (fixtureType: Partial<PlanogramLayout>) => {
  const response = await axiosInstance.post(
    `layoutname/AdminUpsertLayoutName`,
    fixtureType
  );

  return response.data;
};

export default upsertLayout;
