import axiosInstance from "../axiosInstanceConfig";
import { FixtureType } from "./getFixtures";

const upsertFixture = async (fixtureType: Partial<FixtureType>) => {
  const response = await axiosInstance.post(
    `fixturetype/AdminUpsertFixtureType`,
    fixtureType
  );

  return response.data;
};

export default upsertFixture;
