import axiosInstance from "../axiosInstanceConfig";

export interface TrainingImage {
  AddedRevision: string | null;
  Category: string;
  Enabled: boolean;
  ImageId: string;
  PackagingVariation: number;
  ProductImageUrl: string | null;
  UPC: string;
  UseForTesting: boolean;
  CreatedAt: Date;
  SideNumber: number;
}

// Returns all products included on planograms for a given team.
const getAdminProductTrainingImages = async (upc: string) => {
  const params = new URLSearchParams();
  params.append("upc", upc);

  const response = await axiosInstance.get<TrainingImage[]>(
    `products/AdminGetProductTrainingImages?${params.toString()}`
  );

  return response.data;
};

export default getAdminProductTrainingImages;
