import { Box, Button, Fade, IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import getTasks, { Task } from "../../../api/jobs/getTasks";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { ColDef } from "ag-grid-community";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { AddRounded, DeleteOutline, EditRounded } from "@mui/icons-material";
import { TaskDialog } from "../components/TaskDialog";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import postSoftDeleteTask from "../../../api/jobs/postSoftDeleteTask";

interface TaskListProps {
  selectedTeam: string;
}

interface TaskRow extends Task {
  actions?: null;
}

export const TaskList = (props: TaskListProps) => {
  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const { selectedTeam, roleOnTeam } = useTeamStore();

  // Effects
  useEffect(() => {
    fetchTasks();
  }, [selectedTeam]);

  // State
  const [taskLoading, setTaskLoading] = useState<boolean>();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskDialogOpen, setTaskDialogOpen] = useState<boolean>(false);
  const [editTask, setEditTask] = useState<Task | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<Task | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [colDefs] = useState<ColDef<TaskRow>[]>([
    {
      field: "Name",
    },
    {
      field: 'JobId'
    },
    {
      field: "StartDate",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "EndDate",
      cellRenderer: (data: any) => formatDisplayDate(data.value),
    },
    {
      field: "FirstCaptureInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "MerchandiseInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "SecondCaptureInstruction",
      cellStyle: {
        "text-overflow": "ellipsis",
        "white-space": "nowrap",
        overflow: "hidden",
        padding: 0,
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      cellRenderer: (row: any) => (
        <>
          <Button
            variant="contained"
            disabled={roleOnTeam !== "admin"}
            startIcon={<EditRounded />}
            size="small"
            onClick={() => {
              setTaskDialogOpen(true);
              setEditTask(row.data as Task);
            }}
          >
            Edit
          </Button>
          <IconButton
            disabled={roleOnTeam !== "admin"}
            color="error"
            onClick={() => {
              setTaskToDelete(row.data as Task);
              setDeleteDialogOpen(true);
            }}
          >
            <DeleteOutline />
          </IconButton>
        </>
      ),
    },
  ]);

  // Functions
  const fetchTasks = async () => {
    try {
      setTaskLoading(true);
      const data = await getTasks(selectedTeam);

      setTasks(data);
    } catch {
      setTasks([]);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load tasks",
      });
    } finally {
      setTaskLoading(false);
    }
  };

  const TaskGridPlugins = (
    <Fade in={roleOnTeam === "admin"} unmountOnExit>
      <Button
        variant="contained"
        startIcon={<AddRounded />}
        onClick={() => setTaskDialogOpen(true)}
        disabled={roleOnTeam !== "admin"}
      >
        New Job
      </Button>
    </Fade>
  );

  const handleDeleteConfirm = async () => {
    if (taskToDelete) {
      setIsDeleting(true);
      try {
        await postSoftDeleteTask(taskToDelete.TaskId, selectedTeam);
        enqueueSnackbar("Task deleted successfully", { variant: "success" });
        await fetchTasks();
      } catch (error) {
        enqueueSnackbar("Failed to delete task", { variant: "error" });
      } finally {
        setIsDeleting(false);
      }
    }
    setDeleteDialogOpen(false);
    setTaskToDelete(null);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setTaskToDelete(null);
  };

  // Render
  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Dialog */}
      <TaskDialog
        open={taskDialogOpen}
        handleClose={() => {
          // Close modal and clear the edit task.
          setTaskDialogOpen(false);
          setEditTask(null);
          fetchTasks();
        }}
        editTask={editTask}
      />

      {/* Table */}
      <AgGridWrapper
        id="task-list"
        loading={taskLoading}
        columnDefs={colDefs}
        rowData={tasks}
        quickSearch
        export
        rememberPageNumber
        plugins={TaskGridPlugins}
      />

      <ConfirmationDialog
        open={deleteDialogOpen}
        title="Confirm Delete"
        content="Are you sure you want to delete this task? This action cannot be undone. Captures associated with this task will not be deleted."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        confirmText="Delete"
        isConfirming={isDeleting}
      />
    </Box>
  );
};
