import { Stack, Typography, Box, LinearProgress } from "@mui/material";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { useState, useEffect } from "react";

export interface MetricStackProps {
  // Value should be expressed as a percentage
  value: string;
  color: string;
  helperText: string;
  isLoading?: boolean;
  noData?: boolean;
}

export const MetricStack = (props: MetricStackProps) => {
  const [displayValue, setDisplayValue] = useState(Number(props.value));

  useEffect(() => {
    // Skip animation if loading or no data
    if (props.isLoading || props.noData) {
      setDisplayValue(0);
      return;
    }

    // NaN Failsafe. Can occur very briefly on firstload.
    if (isNaN(Number(props.value))) {
      setDisplayValue(0);
      return;
    }

    const targetValue = Number(props.value);
    const increment = targetValue > displayValue ? 1 : -1;

    if (displayValue !== targetValue) {
      const interval = setInterval(() => {
        setDisplayValue((prevValue) => {
          const newValue = prevValue + increment;
          if (
            (increment > 0 && newValue >= targetValue) ||
            (increment < 0 && newValue <= targetValue)
          ) {
            clearInterval(interval);
            return targetValue;
          }
          return newValue;
        });
      }, 10);
      return () => clearInterval(interval);
    }
  }, [props.value, displayValue, props.isLoading, props.noData]);

  return (
    <Stack sx={{ mt: -6 }} gap={2}>
      {/* Value */}
      <Typography
        variant="h3"
        fontWeight={600}
        sx={{
          color: props.isLoading ? urStorePalette.greys.default : props.color,
          opacity: props.isLoading ? 0.5 : 1,
        }}
      >
        {props.noData ? (
          "-"
        ) : (
          <>
            {displayValue}
            <span style={{ fontSize: 28 }}>%</span>
          </>
        )}
      </Typography>

      {/* LinearProg */}
      <Box sx={{ width: "100%", color: props.color }}>
        <LinearProgress
          color="inherit"
          value={props.isLoading ? 0 : Number(props.value)}
          variant={props.isLoading ? "indeterminate" : "determinate"}
          sx={{ opacity: props.noData ? 0.3 : 1 }}
        />
      </Box>

      {/* Helper */}
      <Typography
        sx={{
          color: urStorePalette.greys.dark,
          fontStyle: "italic",
          opacity: props.isLoading ? 0.7 : 1,
        }}
        variant="caption"
      >
        {props.isLoading
          ? "Loading metrics..."
          : props.noData
          ? "No data available"
          : props.helperText}
      </Typography>
    </Stack>
  );
};
