import axiosInstance from "../axiosInstanceConfig";

export interface AdminPlanoResponse {
  PlanogramId: string;
  Name: string;
  DisplayName: string | null;

  PlanogramCategoryId: string | null;
  FixtureTypeId: string | null;
  LayoutName: string | null;
  LayoutNameId: string | null;

  State: string | null;
  Size: number | null;

  FixtureName: string | null;
  FixtureType: string | null;
  FixtureHeight: number | null;
  FixtureDepth: number | null;

  RightToLeft: boolean | null;

  Revisions: AdminPlanoRevision[];

  CreatedAt: Date;
  LastModified: Date;
}

export interface AdminPlanoRevision {
  PlanogramRevisionId: string;
  Name: string;
  LastModified: Date;
  ActiveStoreCount: number;
  ActiveStores: {
    StoreId: string;
    StoreName: string;
  }[];

  // Utility Properties, usually used by specific vendors.
  DbKey: string | null;
  GLN: string | null;
}

const getAdminPlanogramById = async (planogramID: string) => {
  const params = new URLSearchParams();
  params.append("id", planogramID);

  const response = await axiosInstance.get<AdminPlanoResponse>(
    `planogram/AdminGetPlanogramById?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the planogram. Please contact support."
    );
  }
};

export default getAdminPlanogramById;
