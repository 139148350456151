import { CaptureAnnotation } from "../../models/planograms/AnnotationTypes";
import axiosInstance from "../axiosInstanceConfig";

const getAdminCaptureWithDetectedSimScore = async (captureId: string, teamId: string) => {
  const params = new URLSearchParams();

  params.append("captureId", captureId);
  params.append("teamId", teamId);

  const response = await axiosInstance.get<CaptureAnnotation>(
    `capture/AdminGetDetectedSimScore?${params.toString()}`
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error getting the capture data. Please contact support."
    );
  }
};

export default getAdminCaptureWithDetectedSimScore;
