import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Typography,
  Stack,
} from "@mui/material";
import getAssignmentsByStore, {
  AssignmentByStore,
} from "../../../../api/assignments/GetAssignmentsByStore";
import { enqueueSnackbar } from "notistack";
import { useTeamStore } from "../../../../stores/teams/TeamStore";
import { WarningRounded } from "@mui/icons-material";

interface StoreAssignmentStepProps {
  revisionId: string;
  onClose: () => void;
  selectedStores: Set<string>;
  setSelectedStores: React.Dispatch<React.SetStateAction<Set<string>>>;
}

const StoreAssignmentStep: React.FC<StoreAssignmentStepProps> = ({
  revisionId,
  selectedStores,
  setSelectedStores,
}) => {
  const { selectedTeam } = useTeamStore();
  const [compatibleStores, setCompatibleStores] = useState<AssignmentByStore[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAssignments();
  }, []);

  const fetchAssignments = async () => {
    try {
      const data = await getAssignmentsByStore(selectedTeam);
      const filteredData = data.filter((assignment) => {
        const planograms = assignment.PlanogramCategory.Planograms;
        // Filter out assignments that have only one planogram and that planogram has the same current and latest revision ID
        return !(
          planograms.length === 1 &&
          planograms[0].CurrentRevisionId === planograms[0].LatestRevisionId
        );
      });

      // Filter stores to only show options where the current selected planogram is present
      const filteredStores = filteredData.filter((store) =>
        store.PlanogramCategory.Planograms.some(
          (planogram) => planogram.LatestRevisionId === revisionId
        )
      );

      setCompatibleStores(filteredStores);
      setSelectedStores(new Set(filteredStores.map((store) => store.StoreId)));
    } catch (error) {
      enqueueSnackbar("Error occurred when fetching Assignment data.", {
        variant: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ height: "280px" }}>
      {isLoading ? (
        <Box
          sx={{
            minHeight: "280px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : compatibleStores.length > 0 ? (
        <List sx={{ overflowY: "auto", height: "280px" }}>
          {compatibleStores.map((store) => (
            <ListItem key={store.StoreId}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStores.has(store.StoreId)}
                    onChange={(e) => {
                      const newSelectedStores = new Set(selectedStores);
                      if (e.target.checked) {
                        newSelectedStores.add(store.StoreId);
                      } else {
                        newSelectedStores.delete(store.StoreId);
                      }
                      setSelectedStores(newSelectedStores);
                    }}
                  />
                }
                label={`${store.Name} (${store.City})`}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        // Fallback when no compatible stores are found
        <Box
          sx={{
            minHeight: "280px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Stack direction="column" spacing={1} alignItems="center">
            <WarningRounded />
            <Typography variant="body1" fontWeight={500}>
              No Eligible Stores
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              textAlign="center"
            >
              No Stores have a configured layout which matches the attributes of
              this planogram.
            </Typography>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default StoreAssignmentStep;
