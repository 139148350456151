import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import AddImageButton from "./AddImageButton";
import { ProductImg } from "../../../products/components/ProductImg";
import { green } from "@mui/material/colors";
import {
  GetRevisionSideLabels,
  GetRevisionSideValues,
} from "../RevisionSideUtils";

interface UploadImageRevisionModalProps {
  open: boolean;
  sides: number[];
  revisionId: string;
  productId: string;
  onClose: () => void;
  loading: boolean;
  uploadMultipleImagesToServer: (images: File[], order?: number[]) => void;
}

const UploadImageRevisionModal = ({
  open,
  sides,
  revisionId,
  productId,
  onClose,
  loading,
  uploadMultipleImagesToServer,
}: UploadImageRevisionModalProps) => {
  const [uploadFile, setUploadFile] = useState<(File | null)[]>([]);

  useEffect(() => {
    setUploadFile([]);
  }, [open]);

  const handleFileDrop = (file: File | null, index: number) => {
    if (file) {
      setUploadFile((prevArray) => {
        const newArray = [...prevArray];
        newArray[index] = file;
        return newArray;
      });
    }
  };

  const handleDelete = (index: number) => {
    setUploadFile((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = null;
      return newArray;
    });
  };

  const sideNumbers = GetRevisionSideValues();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
      <DialogTitle>
        {sides.length > 0 ? "Add Images" : "New Product Revision"}
      </DialogTitle>

      <DialogContent>
        <Alert
          sx={{ alignSelf: "flex-start", mb: 3, width: "100%", border: "none" }}
          severity="info"
        >
          <Stack spacing={1}>
            <Typography variant="body2">
              A revision requires{" "}
              <b>
                <span
                  style={{
                    color: green[500],
                  }}
                >
                  1 primary image
                </span>{" "}
              </b>
              , and can optionally provide secondary images for each side.
            </Typography>
          </Stack>
        </Alert>

        <Grid container spacing={2} justifyContent="center">
          {GetRevisionSideLabels().map((label, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} sx={{mb: 2}}>
              <Box>
                  <Chip
                    label={label}
                    color={index === 0 ? "success" : "default"}
                    sx={{ fontWeight: 600 }}
                  />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 200,
                    width: "100%",
                    mt: 2,
                    border: "2px solid",
                    borderColor: index === 0 ? green[500] : "#ccc",
                    backgroundColor: index === 0 ? green[50] : "#fff",
                    borderRadius: 4,
                    padding: 1,
                    pb: 2,
                  }}
                >
                  {(sides[0] === 0 || sides[0] === 1) && index === 0 ? (
                    <ProductImg
                      productId={productId}
                      revisionId={revisionId}
                      sideNumber={sides[0] === 1 ? 1 : 0}
                      clickable
                      defaultSize={100}
                      growSize={100}
                    />
                  ) : Array.isArray(uploadFile) && !uploadFile[index] ? (
                    sides.includes(sideNumbers[index]) ? (
                      <ProductImg
                        productId={productId}
                        revisionId={revisionId}
                        sideNumber={sideNumbers[index]}
                        clickable
                        defaultSize={100}
                        growSize={100}
                      />
                    ) : (
                      <AddImageButton
                        disabled={loading}
                        onFileDrop={(file) =>
                          handleFileDrop(file as File, index)
                        }
                      />
                    )
                  ) : (
                    uploadFile[index] && (
                      <>
                        <img
                          src={URL.createObjectURL(uploadFile[index] as File)}
                          alt="preview"
                          width="100px"
                          height="100px"
                          style={{
                            display: "block",
                            borderRadius: 6,
                            margin: "0 auto",
                            marginBottom: 15,
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                          }}
                        />
                        <IconButton
                          disabled={loading}
                          onClick={() => handleDelete(index)}
                        >
                          <Delete />
                        </IconButton>
                      </>
                    )
                  )}
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            onClose();
            setUploadFile([]);
          }}
        >
          Cancel
        </Button>

        <LoadingButton
          loading={loading}
          variant="outlined"
          disabled={
            (sides.length === 0 && !uploadFile[0]) ||
            (sides.length > 0 && uploadFile.length === 0) ||
            uploadFile.every((file) => file === null || file === undefined)
          }
          onClick={() => {
            uploadMultipleImagesToServer(uploadFile as File[]);
          }}
          startIcon={<AddCircleOutline />}
        >
          {sides.length > 0 ? "Add Images" : "Submit Image Revision"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadImageRevisionModal;
