import {
  CaptureAnnotation,
  CaptureBayAnnotation,
  CapturePositionAnnotation,
} from "../../../models/planograms/AnnotationTypes";

const CapturePositionStatus: Record<number, string> = {
  0: "Match",
  1: "Mismatch",
  2: "Out of Stock",
};

const getIsCorrect = (position: CapturePositionAnnotation): boolean => {
  switch (position.Status) {
    case 0: // Match
      return position.ExpectedUpc === position.FoundUpc;
    case 1: // Mismatch
      return (
        position.ExpectedUpc !== position.FoundUpc && position.FoundUpc !== null
      );
    case 2: // Out of Stock
      return position.FoundUpc === null;
    default:
      return false;
  }
};

const escapeCSVField = (field: any): string => {
  const stringValue = `${field ?? "N/A"}`;
  // If field contains quotes or commas, wrap in quotes and double any existing quotes
  if (stringValue.includes('"') || stringValue.includes(",")) {
    return `"${stringValue.replace(/"/g, '""')}"`;
  }
  return `"${stringValue}"`;
};

/**
 * Generates an accuracy report for a capture annotation.
 * @param captureAnnotation - The capture annotation to generate the report for.
 * @param beforeOrAfter - Whether to generate the report for the "before" or "after" state.
 * @returns A CSV string representing the accuracy report.
 */
export const generateAccuracyReport = (
  captureAnnotation: CaptureAnnotation | null,
  beforeOrAfter: 0 | 1
): string => {
  if (!captureAnnotation) return "";

  // CSV Header
  const csvRows = [
    "Fixture,Position,TrayIndexX,TrayIndexY,HFacingIndex,VFacingIndex,ExpectedUPC,Name,FoundUPC,Status,Correct",
  ];

  // Process Bay Data
  captureAnnotation.Captures[beforeOrAfter].forEach(
    (bay: CaptureBayAnnotation) => {
      bay.CaptureFixtures.forEach((fixture, fixtureIndex) => {
        fixture.CapturePositions.forEach(
          (position: CapturePositionAnnotation) => {
            const row = [
              fixtureIndex + 1,
              position.PositionCardinality,
              position.TrayIndexX,
              position.TrayIndexY,
              position.HFacingIndex,
              position.VFacingIndex,
              position.ExpectedUpc,
              position.ProductName,
              position.FoundUpc,
              CapturePositionStatus[position.Status],
              getIsCorrect(position).toString(),
            ]
              .map(escapeCSVField)
              .join(",");

            csvRows.push(row);
          }
        );
      });
    }
  );

  return csvRows.join("\n");
};
