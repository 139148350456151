import axiosInstance from "../axiosInstanceConfig";

export interface UpdateDbKeyRequest {
  PlanogramRevisionId: string;
  DbKey: string | null;
  GLN: string | null;
}

/**
 * Updates the DbKey and GLN for a planogram revision.
 * DbKey and GLN are usually only used by specific vendors and are not universally used.
 */
const postAdminUpdateIdentifiers = async (data: UpdateDbKeyRequest) => {
  const response = await axiosInstance.post<string>(
    `planogram/AdminUpdateDbKeyGLN`,
    data
  );

  return response.data;
};

export default postAdminUpdateIdentifiers;
