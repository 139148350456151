import { Box, Chip, Tooltip } from "@mui/material";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useState } from "react";
import getAssignmentsByStore, {
  AssignmentByStore,
} from "../../../api/assignments/GetAssignmentsByStore";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { ColDef } from "ag-grid-community";
import { InfoRounded, UpdateRounded } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import postAdminAssignPlanogramToStore, {
  AssignPlanogramRequest,
} from "../../../api/planograms/postAdminAssignPlanogramToStore";
import ResolverActions from "./components/ResolverActions";

export default function ListAssignmentsPage() {
  // Util
  const { selectedTeam } = useTeamStore();

  // ColDefs
  const [colDefs] = useState<ColDef<AssignmentByStore>[]>([
    {
      field: "Name",
      flex: 1,
    },
    {
      field: "City",
      flex: 0.5,
    },

    {
      field: "PlanogramCategory.Name",
      headerName: "Layout",
      flex: 0.5,
    },
    {
      // Baycount isn't actually used for this field, but `field` must be provided for every column.
      field: "BayCount",
      headerName: "Status",
      flex: 0.5,
      cellRenderer: (params: any) => {
        // Update if currently assigned planogram is available.
        if (
          params.data.PlanogramCategory.Planograms.length === 1 &&
          params.data.PlanogramCategory.Planograms[0].CurrentRevisionId !==
            null &&
          params.data.PlanogramCategory.Planograms[0].CurrentRevisionId !==
            params.data.PlanogramCategory.Planograms[0].LatestRevisionId
        ) {
          return (
            <Tooltip
              title="This category's assigned planogram has a new version available."
              placement="top"
            >
              <Chip
                color="info"
                label="New Revision"
                icon={<UpdateRounded />}
              />
            </Tooltip>
          );
        } else {
          // Catch-all for other cases. Likely "missing" an assignment.
          return (
            <Tooltip
              title="The configured category at this store does not have an assigned planogram."
              placement="top"
            >
              <Chip color="error" label="No Planogram" icon={<InfoRounded />} />
            </Tooltip>
          );
        }
      },
    },

    {
      field: "PlanogramCategory.Planograms",
      headerName: "Suggested Actions",
      sort: "desc",
      flex: 1,
      cellRenderer: (params: any) => (
        <ResolverActions
          planograms={params.value}
          storeId={params.data.StoreId}
          loading={loading.action}
          onAssign={handleAssign}
          // This condition checks if there is only one planogram assigned, it has a current revision, and the current revision is not the latest.
          // If these conditions are met, the mode is set to "update", otherwise it is set to "assign".
          mode={
            params.data.PlanogramCategory.Planograms.length === 1 &&
            params.data.PlanogramCategory.Planograms[0].CurrentRevisionId !==
              null &&
            params.data.PlanogramCategory.Planograms[0].CurrentRevisionId !==
              params.data.PlanogramCategory.Planograms[0].LatestRevisionId
              ? "update"
              : "assign"
          }
        />
      ),
    },
  ]);

  // State
  const [assignments, setAssignments] = useState<AssignmentByStore[]>([]);
  const [loading, setLoading] = useState<{
    assignments: boolean;
    action: boolean;
  }>({ action: false, assignments: false });

  // Effects
  useEffect(() => {
    fetchAssignments();
  }, [selectedTeam]);

  const fetchAssignments = async () => {
    try {
      setLoading((prev) => ({ ...prev, assignments: true }));
      const data = await getAssignmentsByStore(selectedTeam);
      const filteredData = data.filter((assignment) => {
        const planograms = assignment.PlanogramCategory.Planograms;
        // Filter out assignments that have only one planogram and that planogram has the same current and latest revision ID
        return !(
          planograms.length === 1 &&
          planograms[0].CurrentRevisionId === planograms[0].LatestRevisionId
        );
      });
      setAssignments(filteredData);
    } catch {
      enqueueSnackbar("", {
        variant: "error",
        cta: "Error occurred when fetching Assignment data.",
      });
    } finally {
      setLoading((prev) => ({ ...prev, assignments: false }));
    }
  };

  const handleAssign = async (
    revisionId: string,
    storeId: string,
    hideResponse: boolean
  ) => {
    let reqbody: AssignPlanogramRequest = {
      Deactivated: false,
      Direction: false,
      Notes: "",
      PlanogramRevisionId: revisionId,
      StoreId: storeId,
    };

    try {
      setLoading((prev) => ({ ...prev, action: true }));
      await postAdminAssignPlanogramToStore(reqbody);
      if (!hideResponse) {
        enqueueSnackbar("", { variant: "success", cta: "Planogram Assigned." });
      }
      fetchAssignments();
    } catch {
      if (!hideResponse) {
        enqueueSnackbar("", { variant: "error", cta: "Assignment failed." });
      }
    } finally {
      setLoading((prev) => ({ ...prev, action: false }));
    }
  };

  // Render
  return (
    <Box sx={ResponsiveContainerStyle}>
      <AgGridWrapper
        columnDefs={colDefs}
        id="assignments-by-category"
        rowData={assignments}
        loading={loading.assignments}
        quickSearch
        rememberPageNumber
        rowHeight={62}
      />
    </Box>
  );
}
