import { AddRounded, SaveRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { useEffect, useState } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { useSnackbar } from "notistack";
import postUpsertTask from "../../../api/jobs/postUpsertTask";
import { LoadingButton } from "@mui/lab";
import dayjs, { Dayjs } from "dayjs";
import { Task } from "../../../api/jobs/getTasks";
import getUniqueRevisions, {
  GetUniqueRevisionResponse,
} from "../../../api/planograms/getUniqueRevisions";
import getTaskTypes, { TaskType } from "../../../api/jobs/getTaskTypes";
import { grey } from "@mui/material/colors";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";

interface TaskDialogProps {
  open: boolean;
  handleClose: () => void;
  editTask: Task | null;
}

export interface TaskForm {
  taskId: string;
  name: string;
  startDate: Dayjs;
  endDate: Dayjs;
  taskType: string;
  firstCaptureInstruction: string;
  merchandiseInstruction: string;
  secondCaptureInstruction: string;
  planogram: string;
  jobId: string | null;
}

const INITIAL_FORM_STATE: TaskForm = {
  taskId: "",
  name: "",
  startDate: dayjs(new Date()),
  endDate: dayjs(new Date()).add(1, "day"),
  taskType: "",
  firstCaptureInstruction: "",
  merchandiseInstruction: "",
  secondCaptureInstruction: "",
  planogram: "",
  jobId: null,
};

export const TaskDialog = (props: TaskDialogProps) => {
  // Util Function
  // Transforms an incoming Task into a TaskForm.
  const transformTask = () => {
    if (!props.editTask) return null;

    const newTaskForm: TaskForm = {
      endDate: dayjs(new Date(props.editTask.EndDate)),
      startDate: dayjs(new Date(props.editTask.StartDate)),
      planogram: props.editTask.PlanogramRevisionId,
      firstCaptureInstruction: props.editTask.FirstCaptureInstruction,
      merchandiseInstruction: props.editTask.MerchandiseInstruction,
      secondCaptureInstruction: props.editTask.SecondCaptureInstruction,
      name: props.editTask.Name,
      taskType: props.editTask.TaskTypeId,
      taskId: props.editTask.TaskId,
      jobId: props.editTask.JobId,
    };

    return newTaskForm;
  };

  // Hooks
  const { selectedTeam } = useTeamStore();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [taskFormData, setTaskFormData] = useState<TaskForm>(
    props.editTask ? transformTask() ?? INITIAL_FORM_STATE : INITIAL_FORM_STATE
  );
  const [createTaskLoading, setCreateTaskLoading] = useState<boolean>(false);
  const [revisionOptions, setRevisionOptions] = useState<
    GetUniqueRevisionResponse[]
  >([]);
  const [taskTypes, setTaskTypes] = useState<TaskType[]>([]);

  // Effects
  useEffect(() => {
    // Reset form state when dialog is opened/closed.
    if (props.editTask) {
      setTaskFormData(transformTask() ?? INITIAL_FORM_STATE);
    } else {
      setTaskFormData(INITIAL_FORM_STATE);
    }

    // Get Unique Revisions
    const fetchUniqueRevisions = async () => {
      try {
        const data = await getUniqueRevisions(selectedTeam);
        setRevisionOptions(
          // Sort by createdAt desc.
          data.sort(
            (a, b) =>
              new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()
          )
        );
      } catch {
        enqueueSnackbar(
          "Failed to get available Planograms. You will be unable to create a task.",
          { variant: "warning", preventDuplicate: true }
        );
        setRevisionOptions([]);
      }
    };

    // Get Task types
    const fetchTaskTypes = async () => {
      try {
        const data = await getTaskTypes(selectedTeam);
        setTaskTypes(data);

        // If creating a new task, set the taskType to the first option by default.
        if (!props.editTask && data.length > 0) {
          setTaskFormData((prevData) => ({
            ...prevData,
            taskType: data[0].TaskTypeId,
          }));
        }
      } catch {
        enqueueSnackbar(
          "Failed to get Task Types. You will be unable to create a task.",
          { variant: "warning" }
        );
        setRevisionOptions([]);
      }
    };

    // Get form pre-reqs.
    fetchUniqueRevisions();
    fetchTaskTypes();
  }, [props.open, props.editTask]);

  // Handle create
  const handleCreateTask = async () => {
    try {
      setCreateTaskLoading(true);

      // Get ref to selected task type.
      const activeTaskType = taskTypes.find(
        (tt) => tt.TaskTypeId === taskFormData.taskType
      );

      // If Unable to get ref, throw handle.
      if (!activeTaskType) {
        throw Error;
      }

      await postUpsertTask(taskFormData, selectedTeam, activeTaskType);
      enqueueSnackbar(`Task ${props.editTask ? "Updated" : "Created"}`, {
        variant: "success",
      });
      props.handleClose();
    } catch {
      enqueueSnackbar("Failed to create Task", { variant: "error" });
    } finally {
      setCreateTaskLoading(false);
    }
  };

  // Handles any form input changes. Except for the autocomplete, because autocompletes SUCK.
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setTaskFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Checks that all fields are filled.
  // There is some finnicky logic here for what fields are required depending on task type selected.
  const isFormValid = () => {
    // Get the fully-formed current task type as a ref. We'll need this later.
    const currentTaskType = taskTypes.find(
      (tt) => tt.TaskTypeId === taskFormData.taskType
    );

    if (!currentTaskType) return false;

    // Define the fields that are conditionally required based on task type.
    const conditionalFields = {
      firstCaptureInstruction: currentTaskType.HasFirstCaptureInstructions,
      merchandiseInstruction: currentTaskType.HasMerchandiseInstructions,
      secondCaptureInstruction: currentTaskType.HasSecondCaptureInstructions,
    };

    for (const key in taskFormData) {
      // exempt taskId and jobId always
      if (key === "taskId" || key === "jobId") continue;

      // Skip validation for fields that are not required by the current task type
      if (
        (key in conditionalFields &&
          !conditionalFields[key as keyof typeof conditionalFields]) ||
        taskFormData[key as keyof typeof taskFormData] !== ""
      ) {
        continue;
      }

      // Default Check
      if (taskFormData[key as keyof typeof taskFormData] === "") {
        return false;
      }
    }
    return true;
  };

  // Render
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Dialog
        open={props.open}
        maxWidth="sm"
        fullWidth
        onClose={(e, reason) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            props.handleClose();
          }
        }}
      >
        <DialogTitle>New Job</DialogTitle>
        <DialogContent>
          <Stack gap={4} sx={{ mt: 1 }}>
            <TextField
              name="name"
              label="Name"
              placeholder="Task Name"
              size="small"
              value={taskFormData.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              name="jobId"
              label="JobId"
              placeholder="Job ID"
              size="small"
              value={taskFormData.jobId}
              onChange={handleInputChange}
            />
            <Stack direction="row" sx={{ width: "100%" }} gap={4}>
              <DesktopDatePicker
                sx={{ width: "50%" }}
                label="Start Date"
                format="DD/MM/YYYY"
                value={taskFormData.startDate}
                name="startDate"
                onChange={(value) =>
                  setTaskFormData((prevData) => ({
                    ...prevData,
                    startDate: value ?? dayjs(new Date()),
                  }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
              <DesktopDatePicker
                sx={{ width: "50%" }}
                label="End Date"
                format="DD/MM/YYYY"
                value={taskFormData.endDate}
                name="endDate"
                onChange={(value) =>
                  setTaskFormData((prevData) => ({
                    ...prevData,
                    endDate: value ?? dayjs(new Date()),
                  }))
                }
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
              />
            </Stack>
            <TextField
              name="taskType"
              label="Task Type"
              placeholder="Select Task Type"
              select
              size="small"
              value={taskFormData.taskType}
              onChange={handleInputChange}
              required
            >
              {taskTypes.map((tt) => (
                <MenuItem key={tt.TaskTypeId} value={tt.TaskTypeId}>
                  {tt.Name}
                </MenuItem>
              ))}
            </TextField>
            <Paper
              sx={{
                backgroundColor: urStorePalette.greys.light,
                p: 4,
                border: "none",
              }}
            >
              <Stack gap={3}>
                {/* Shows regardless of type. */}
                {taskTypes.find((tt) => tt.TaskTypeId === taskFormData.taskType)
                  ?.HasFirstCaptureInstructions && (
                  <TextField
                    name="firstCaptureInstruction"
                    sx={{ backgroundColor: urStorePalette.greys.lightest }}
                    label="First Capture Instruction"
                    size="small"
                    multiline
                    maxRows={2}
                    minRows={2}
                    value={taskFormData.firstCaptureInstruction}
                    onChange={handleInputChange}
                    required
                  />
                )}

                {/* Must be Correct & Capture or 2-Step Capture to show. */}
                {taskTypes.find((tt) => tt.TaskTypeId === taskFormData.taskType)
                  ?.HasMerchandiseInstructions && (
                  <TextField
                    name="merchandiseInstruction"
                    sx={{ backgroundColor: urStorePalette.greys.lightest }}
                    label="Merchandise Instruction"
                    size="small"
                    multiline
                    maxRows={2}
                    minRows={2}
                    value={taskFormData.merchandiseInstruction}
                    onChange={handleInputChange}
                    required
                  />
                )}
                {/* Must be 2-step Capture to show. */}
                {taskTypes.find((tt) => tt.TaskTypeId === taskFormData.taskType)
                  ?.HasSecondCaptureInstructions && (
                  <TextField
                    name="secondCaptureInstruction"
                    sx={{ backgroundColor: urStorePalette.greys.lightest }}
                    label="Second Capture Instruction"
                    size="small"
                    multiline
                    maxRows={2}
                    minRows={2}
                    value={taskFormData.secondCaptureInstruction}
                    onChange={handleInputChange}
                    required
                  />
                )}
              </Stack>
            </Paper>

            <Autocomplete
              value={
                revisionOptions.find(
                  (ro) => ro.PlanogramRevisionId === taskFormData.planogram
                ) ?? null
              }
              size="small"
              options={revisionOptions}
              renderInput={(params) => (
                <TextField {...params} label="Planogram" />
              )}
              getOptionLabel={(option) => {
                // Prioritise showing DisplayName where available.
                return `${option.PlanogramDisplayName ?? option.PlanogramName}`;
              }}
              renderOption={(props, option) => (
                <li {...props}>
                  <div>
                    <Typography
                      component="span"
                      variant="body2"
                      fontWeight={500}
                    >
                      {option.PlanogramDisplayName ?? "No Display Name"}
                    </Typography>

                    <br />
                    <Typography
                      component="span"
                      variant="caption"
                      sx={{ color: grey[700] }}
                    >
                      {option.PlanogramName}
                    </Typography>
                    <br />
                    <Typography
                      component="span"
                      variant="caption"
                      style={{ color: grey[600], fontStyle: "italic" }}
                    >
                      Created: {formatDisplayDate(option.CreatedAt)}
                    </Typography>
                  </div>
                </li>
              )}
              onChange={(e, nv) =>
                setTaskFormData((prev) => ({
                  ...prev,
                  planogram: nv?.PlanogramRevisionId ?? "",
                }))
              }
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={props.handleClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={createTaskLoading}
            variant="contained"
            startIcon={props.editTask ? <SaveRounded /> : <AddRounded />}
            disabled={!isFormValid()}
            onClick={handleCreateTask}
          >
            {props.editTask ? "Save Changes" : "Create"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </LocalizationProvider>
  );
};
