import axiosInstance from "../axiosInstanceConfig";

export interface FixtureType {
  FixtureTypeId: string,
  Name: string,
  Type: string,
  CreatedAt: Date,
  LastModified: Date,
}

const getFixtures = async () => {
  const response = await axiosInstance.get<FixtureType[]>(
    `fixturetype/AdminGetFixtureTypes`
  );

  return response.data;
};

export default getFixtures;
