import axiosInstance from "../axiosInstanceConfig";

/**
 * Fully delete a capture. Only superusers can do this, and it is irreversible.
 * @param captureId - The ID of the capture to admin delete.
 * @returns True if the capture was admin deleted successfully, otherwise throws an error.
 */
const postAdminDeleteCapture = async (
  captureId: string,
) => {
  const params = new URLSearchParams();
  params.append("captureId", captureId);

  const response = await axiosInstance.delete(
    `capture/AdminDeleteCapture?${params.toString()}`
  );
  if (response.status === 200) {
    return true;
  } else {
    throw new Error(
      "There was an error deleting the capture. Please contact support."
    );
  }
};

export default postAdminDeleteCapture;
