import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  Collapse,
} from "@mui/material";
import { lightBlue, purple } from "@mui/material/colors";
import { urStorePalette } from "../../../themes/urStoreTheme";
import { NavItem } from "../../../stores/layout/LayoutStoreTypes";
import { navItems, adminNavItems } from "./NavRoutes";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { CheckAdminAuth } from "../../../features/authentication/contexts/AuthContext";

function MainNav(props: any) {
  const location = useLocation();
  const selectedTeam = props.selectedTeam || null;
  const navigate = useNavigate();

  const { drawerOpen } = useLayoutStore();
  const isAdmin = CheckAdminAuth();

  const [openItems, setOpenItems] = useState<{ [key: string]: boolean }>(() => {
    const savedOpenItems = localStorage.getItem('openNavItems');
    return savedOpenItems ? JSON.parse(savedOpenItems) : {};
  });

  useEffect(() => {
    localStorage.setItem('openNavItems', JSON.stringify(openItems));
  }, [openItems]);

  function generateNavItems(
    navItems: NavItem[],
    asAdminItems: boolean,
    level = 0
  ) {
    return navItems
      .filter((item) => item.showInSidebar)
      .map((item) => {
        const isActive = item.path && location.pathname.startsWith(item.path);
        const hasChildren = item.children && item.children.length > 0;

        return (
          <React.Fragment key={item.label}>
            <Tooltip
              disableHoverListener={drawerOpen}
              title={item.label}
              arrow
              placement="right"
            >
              <ListItemButton
                sx={{
                  my: 0.5,
                  mx: 2,
                  px: 2,
                  py: 1,
                  transition: "all 0.2s ease",
                  borderRadius: "6px",
                  backgroundColor: isActive ? "rgba(255,255,255,0.2)" : "none",
                  ":hover": {
                    backgroundColor: "rgba(255,255,255,0.2)",
                  },
                  paddingLeft:
                    level > 0 && drawerOpen
                      ? `${(level + 1) * 8}px`
                      : !drawerOpen && level > 0 ? 0.5 : undefined,
                  ...(hasChildren && {
                    mb: openItems[item.label] ? 0 : 1,
                    backgroundColor: "rgba(255,255,255,0.1)",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.15)",
                    },
                    borderBottomLeftRadius: openItems[item.label] ? 0 : "6px",
                    borderBottomRightRadius: openItems[item.label] ? 0 : "6px",
                  }),
                }}
                onClick={() => {
                  if (hasChildren) {
                    setOpenItems((prev) => {
                      const newState = {
                        ...prev,
                        [item.label]: !prev[item.label],
                      };
                      localStorage.setItem('openNavItems', JSON.stringify(newState));
                      return newState;
                    });
                  } else {
                    asAdminItems ? navigate("/admin") : navigate("/");
                    item.path && navigate(item.path);
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    color: asAdminItems ? purple[300] : lightBlue[400],
                    mr: level > 0 ? -4 : -2,
                    ...(level > 0 && {
                      "& .MuiSvgIcon-root": {
                        fontSize: "1.25rem",
                      },
                    }),
                    ...(hasChildren && {
                      color: purple[200],
                    }),
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                {drawerOpen && (
                  <>
                    <ListItemText
                      sx={{
                        color: isActive
                          ? urStorePalette.greys.lightest
                          : urStorePalette.greys.light,
                        opacity: isActive ? 1 : 0.8,
                        display: { xs: "none", sm: "block" },
                        ...(hasChildren && {
                          color: `${urStorePalette.greys.light} !important`,
                        }),
                      }}
                      primary={
                        <Typography
                          sx={{
                            transition: "all 0.2s ease",
                            fontWeight: isActive ? "600" : "500",
                            fontSize: level > 0 ? "14px" : "16px",
                            ...(hasChildren && {
                              fontSize: "0.875rem",
                              fontWeight: "600",
                              textTransform: "capitalize",
                              letterSpacing: "0.05em",
                            }),
                          }}
                        >
                          {item.label}
                        </Typography>
                      }
                    />
                    {hasChildren &&
                      (openItems[item.label] ? <ExpandLess /> : <ExpandMore />)}
                  </>
                )}
              </ListItemButton>
            </Tooltip>
            {hasChildren && (
              <Collapse in={openItems[item.label]} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  sx={{
                    backgroundColor: "rgba(255,255,255,0.1)",
                    borderBottomLeftRadius: "6px",
                    borderBottomRightRadius: "6px",
                    overflow: "hidden",
                    m: drawerOpen ? 2 : 0,
                    mt: 0,
                    mx: 2,
                  }}
                >
                  {generateNavItems(item.children!, asAdminItems, level + 1)}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        );
      });
  }

  return (
    <>
      {selectedTeam !== null && (
        <List component="nav">{generateNavItems(navItems, false)}</List>
      )}
      {isAdmin && (
        <List component="nav">{generateNavItems(adminNavItems, true)}</List>
      )}
    </>
  );
}

export default MainNav;
