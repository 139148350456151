import { Box, Button, ButtonGroup, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { AgGridWrapper } from "../../../../components/AgGridWrapper/AgGridWrapper";
import { useEffect, useState } from "react";
import { ColDef } from "ag-grid-community";
import getMachineLearningCategories, {
  MachineLearningCategory,
} from "../../../../api/categories/getCategories";
import postCreateCategory from "../../../../api/categories/postCreateCategory";
import { LoadingButton } from "@mui/lab";
import { AddCircleOutline } from "@mui/icons-material";
import { formatDisplayDate } from "../../../../components/AgGridWrapper/utilities";
import { ResponsiveContainerStyle } from "../../../../helpers/generalUtilities";

export const ListCategoriesPage = () => {
  // Util
  const { enqueueSnackbar } = useSnackbar();

  // ColDefs
  const [colDefs] = useState<ColDef<MachineLearningCategory>[]>([
    {
      field: 'Name'
    },
    {
      field: 'CreatedAt',
      cellRenderer: (_data: any) => formatDisplayDate(_data.value)
    }
  ]);

  // Category List State
  const [categories, setCategories] = useState<MachineLearningCategory[]>([]);
  const [categoriesLoading, setCategoriesLoading] = useState<boolean>(false);

  // Category Creation State
  const [newCategoryName, setNewCategoryName] = useState<string>("");
  const [newCategoryLoading, setNewCategoryLoading] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    fetchCategories();
  }, []);

  // Function
  const fetchCategories = async () => {
    try {
      setCategoriesLoading(true);
      const data = await getMachineLearningCategories();

      setCategories(data);
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to load Categories.",
      });
    } finally {
      setCategoriesLoading(false);
    }
  };

  const handleCreateCategory = async () => {
    try {
      setNewCategoryLoading(true);
      await postCreateCategory(newCategoryName);
      enqueueSnackbar("Success", {
        variant: "success",
        cta: `'${newCategoryName}' was created.`,
      });
      setNewCategoryName("");
      await fetchCategories();
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to create Category.",
      });
    } finally {
      setNewCategoryLoading(false);
    }
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      <AgGridWrapper
        id="planogram-categories"
        rowData={categories}
        columnDefs={colDefs}
        loading={categoriesLoading}
        quickSearch
        export
        rememberPageNumber
        plugins={
          <ButtonGroup>
            <TextField
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              InputProps={{
                style: {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                },
              }}
              label="Category name"
              size="small"
            />
            <LoadingButton
              loading={newCategoryLoading}
              onClick={handleCreateCategory}
              sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              variant="contained"
              disabled={newCategoryName === ""}
              startIcon={<AddCircleOutline />}
            >
              Add Category
            </LoadingButton>
          </ButtonGroup>
        }
      />
    </Box>
  );
};
