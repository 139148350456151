import { useEffect } from "react";
import {
  FormControl,
  Grid,
  TextField,
  Autocomplete,
  Popover,
} from "@mui/material";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import { useMasterFilterStore } from "../../../../../stores/metrics/MasterFilterStore";
import { FilterInputStyle } from "../../../helpers/DashboardHelpers";
import { useMetricsFilterOptions } from "../../../../../stores/metrics/MetricsOptionsStore";
import { urStorePalette } from "../../../../../themes/urStoreTheme";
import MultiTag from "../../LocationFilter/MultiTag";

interface FixtureTypeFilterModalProps {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
}

const FixtureTypeFilterModal = (props: FixtureTypeFilterModalProps) => {
  const setFixtureType = usePlanogramFilterStore(
    (state) => state.setFixtureType
  );
  const fixtureType = usePlanogramFilterStore((state) => state.fixtureType);
  const open = Boolean(props.anchorEl);
  const id = open ? "fixtureType-filter" : undefined;

  // Master
  const { syncedFilters, setSyncFilters } = useMasterFilterStore();

  // Options
  const { data } = useMetricsFilterOptions();

  // Effects
  useEffect(() => {
    if (
      syncedFilters?.fixtureType !== null &&
      syncedFilters?.fixtureType !== undefined
    ) {
      setFixtureType(syncedFilters?.fixtureType);
    }
  }, [syncedFilters]);

  return (
    <div>
      <Popover
        sx={{ mt: 2 }}
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid gap={4} container sx={{ p: 4, width: "280px" }}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                options={data?.FixtureType.filter((ft) => ft !== null) || []}
                getOptionLabel={(option) => option}
                value={fixtureType ? (Array.isArray(fixtureType) ? fixtureType : [fixtureType]) : []}
                onChange={(event, values) => {
                  setFixtureType(values);
                  setSyncFilters(["fixtureType"], values.length > 0 ? values : null);
                }}
                renderTags={(tagValues, getTagProps) =>
                  tagValues.map((option, index) => (
                    //@ts-ignore key prop is provided by the spreader.
                    <MultiTag key={index} {...getTagProps({ index })} label={option} />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fixture Type"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        ...FilterInputStyle,
                        backgroundColor: params.disabled
                          ? urStorePalette.greys.light
                          : urStorePalette.greys.lightest,
                      },
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default FixtureTypeFilterModal;
