import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { AttachFile, Delete } from '@mui/icons-material';
import DragAndDropUploader from '../../../../components/DragAndDropUploader/DragAndDropUploader';

interface PlanogramXmlUploaderProps {
  onFileUpload: (file: File | null) => Promise<void>;
}

const PlanogramXmlUploader: React.FC<PlanogramXmlUploaderProps> = ({ onFileUpload }) => {
  const [uploadFile, setUploadFile] = useState<File | null>(null);

  const handleFileDrop = (file: File | File[]) => {
    const uploadedFile = file as File;
    setUploadFile(uploadedFile);
    onFileUpload(uploadedFile);
  };

  return (
    <Box>
      {!uploadFile && (
        <DragAndDropUploader
          onFileDrop={handleFileDrop}
          acceptedFileTypes={['.xml']}
        />
      )}
      {uploadFile && (
        <Box mt={2} mb={3}>
          <Typography variant="body1" display="flex" alignItems="center">
            <AttachFile fontSize="small" />
            {uploadFile.name}
            <IconButton
              onClick={() => {
                setUploadFile(null);
                onFileUpload(null);
              }}
              aria-label="delete"
            >
              <Delete />
            </IconButton>
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PlanogramXmlUploader;
