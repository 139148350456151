import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Fade,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { ReactElement, createContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CaptureBayItemCard from "../components/CaptureBayItemCard";
import {
  BugReport,
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
  Delete,
  Download,
  NavigateBeforeRounded,
  NavigateNextRounded,
} from "@mui/icons-material";
import getPlanogramCaptureAnnotations from "../../../api/planograms/getPlanogramCaptureAnnotations";
import getCaptureMetaData from "../../../api/capture/getCaptureMetadata";
import getPlanogramRevisionById from "../../../api/planograms/getPlanogramRevisionById";
import DeleteCaptureDialog from "../components/DeleteCaptureModal";
import postAdminDeleteCapture from "../../../api/capture/postAdminDeleteCapture";
import {
  CaptureAnnotation,
  CaptureBayAnnotation,
  CapturePositionWithKeys,
} from "../../../models/planograms/AnnotationTypes";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import CaptureBayItemCroppingDialog from "../components/CaptureBayItemCroppingDialog";
import { useAuthContext } from "../../authentication/contexts/AuthContext";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { useSessionStore } from "../../../stores/auth/SessionStore";
import {
  Capture,
  PlanogramRevision,
} from "../../../models/planograms/CaptureTypes";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { usePlanogramStore } from "../../../stores/snapshots/PlanogramStore";
import { LoadingButton } from "@mui/lab";
import getAdminCaptureWithDetectedSimScore from "../../../api/capture/getAdminSimiliarityScore";
import { generateAccuracyReport } from "../utils/generateAccuracyReport";

class DisplayCapture {
  constructor(
    public id: string,
    public bayCount: number,
    public captureId: string
  ) {}
}

interface ProductOnPlanogram {
  UPC: string;
  Name: string;
}

// Context necessary for propagating revision data down the tree.
export const RevisionContext = createContext<{
  revision: PlanogramRevision;
  skuMap: ProductOnPlanogram[];
  taskIndex: 0 | 1;
} | null>(null);

const PlanogramCapturesIndex = (props: any) => {
  // util
  const navigate = useNavigate();
  const auth = useAuthContext();
  const teamId = props.selectedTeam;
  const { roleOnTeam } = useTeamStore();
  const { showCaptureTooltip, setShowCaptureTooltip } = useSessionStore();
  const { setBayItemsList } = usePlanogramStore();
  const { setSubRoute } = useLayoutStore();
  const { user } = useSessionStore();

  // Data State
  const [revision, setRevision] = useState<any>(null);
  const [planograms, setPlanograms] = useState<DisplayCapture[]>([]);
  const [cardIndex, setCardIndex] = useState<number>(1);
  const [captureId, setCaptureId] = useState<string | null>(null);

  const [metadata, setMetadata] = useState<{
    revisionId: string | null;
    createdDate: Date;
    taskName: string | null;
  }>({ revisionId: null, createdDate: new Date(), taskName: null });
  const [params, setParams] = useSearchParams();
  const [skuMap, setSkuMap] = useState<ProductOnPlanogram[]>([]);
  const [annotations, setAnnotations] = useState<Capture | null>(null);

  // Component State
  const [planogramCards, setPlanogramCards] = useState<ReactElement[]>([]);
  const [pageError, setPageError] = useState<string>("");
  const [captureAnnotation, setCaptureAnnotation] =
    useState<CaptureAnnotation | null>(null);
  const [isDeleteCaptureDialogOpen, setIsDeleteCaptureDialogOpen] =
    useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState<string | null>(
    null
  );
  const [showAnnotationFailedAlert, setShowAnnotationFailedAlert] =
    useState<boolean>(false);
  const [openCroppedImageDialog, setOpenCroppedImageDialog] =
    useState<boolean>(false);
  const [beforeOrAfter, setBeforeOrAfter] = useState<0 | 1>(0); // These numbers correspond to the index being used on the Captures field.

  // Loading State
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingPlanograms, setLoadingPlanograms] = useState<boolean>(false);

  useEffect(() => {
    let captureID = params.get("capture");
    if (captureID) {
      setCaptureId(captureID);
    } else {
      navigate("../planograms");
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [teamId, captureId]);

  useEffect(() => {
    // This effect triggers whenever the before/after button is clicked.
    // It just swaps the currently loaded cards/bays between the two task indexes.
    if (annotations) {
      setPlanograms(
        annotations.CaptureBays[beforeOrAfter]
          .filter((bay) => bay.PhotoId !== null)
          .map((bay) => {
            return new DisplayCapture(bay.PhotoId, bay.BayIndex, bay.CaptureId);
          })
      );

      setPlanogramCards(
        planograms.map((planogram) => (
          <CaptureBayItemCard
            captureBayData={captureAnnotation?.Captures[beforeOrAfter].find(
              (bay) => bay.BayIndex === cardIndex - 1
            )}
            imageUrl={`${
              process.env.REACT_APP_URSTORE_CONTENT_PREFIX
            }assets/captures/${encodeURIComponent(
              planogram.captureId
            )}/${encodeURIComponent(planogram.id)}`}
            key={planogram.id}
            captureId={captureId}
            callUpdatedData={fetchProducts}
          />
        ))
      );
    }
  }, [beforeOrAfter]);

  const fetchCaptureMetadata = async () => {
    if (captureId === null) {
      setPlanograms([]);
      return;
    }

    try {
      setLoadingPlanograms(true);
      const data = await getCaptureMetaData(captureId);
      if (data) {
        setMetadata({
          taskName: data.TaskName,
          revisionId: data.PlanogramAssignment.PlanogramRevisionId,
          createdDate: data.CreatedAt,
        });

        // If an 'after' capture is available, default to that instead of 'before'.
        if (data.CaptureBays[1]) {
          setBeforeOrAfter(1);
        }

        setPlanograms(
          data.CaptureBays[beforeOrAfter]
            .filter((bay) => bay.PhotoId !== null)
            .map((bay) => {
              return new DisplayCapture(
                bay.PhotoId,
                bay.BayIndex,
                bay.CaptureId
              );
            })
        );
      }
    } catch (error: any) {
      setPageError(error.message);
      console.log(error.message);
    } finally {
      setLoadingPlanograms(false);
    }
  };

  useEffect(() => {
    fetchCaptureMetadata();
  }, [captureId]);

  useEffect(() => {
    const fetchPlanogramRevision = async () => {
      setIsLoading(true);
      if (metadata.revisionId === null) {
        return;
      }
      try {
        const data = await getPlanogramRevisionById(
          metadata.revisionId,
          teamId
        );
        // Map a list of UPCs with custom SKU Codes.
        const productsOnPlanogram: ProductOnPlanogram[] =
          data.PlanogramFixtures.flatMap((pf) =>
            pf.PlanogramPositions.filter(
              (pp) => pp.Name !== null && pp.Name !== ""
            ).map((pp) => {
              return { UPC: pp.UPC, Name: pp.Name };
            })
          );

        setSkuMap(productsOnPlanogram);

        if (data) {
          setRevision(data);
          setSubRoute({
            title: data.Name,
          });
        }
      } catch (error: any) {
        setPageError(error.message);
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPlanogramRevision();
  }, [metadata.revisionId]);

  useEffect(() => {
    if (planograms.length === 0) {
      return;
    }

    setPlanogramCards(
      planograms.map((planogram) => (
        <CaptureBayItemCard
          captureBayData={captureAnnotation?.Captures[beforeOrAfter].find(
            (bay) => bay.BayIndex === cardIndex - 1
          )}
          imageUrl={`${
            process.env.REACT_APP_URSTORE_CONTENT_PREFIX
          }assets/captures/${encodeURIComponent(
            planogram.captureId
          )}/${encodeURIComponent(planogram.id)}`}
          key={planogram.id}
          captureId={captureId}
          callUpdatedData={fetchProducts}
        />
      ))
    );
  }, [planograms, captureAnnotation, cardIndex, captureId]);

  useEffect(() => {
    if (captureAnnotation) {
      const positions = extractCapturePositions(
        captureAnnotation.Captures[beforeOrAfter]
      );
      setBayItemsList(positions);
    }
  }, [captureAnnotation]);

  const cancelEdit = (e?: any) => {
    // 2 is used here because new tab + copy-paste link === 2 length.
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate("../planograms");
    }
  };

  const handleDownload = () => {
    window.open(`/captures/${captureId}`, "_blank");
  };

  const extractCapturePositions = (
    annotations: CaptureBayAnnotation[]
  ): CapturePositionWithKeys[] => {
    const result: CapturePositionWithKeys[] = [];

    annotations.forEach((bay) => {
      bay.CaptureFixtures.forEach((fixture, rowIndex) => {
        fixture.CapturePositions.forEach((position) => {
          const positionWithKeys: CapturePositionWithKeys = {
            ...position,
            BayIndex: bay.BayIndex,
            RowIndex: rowIndex,
          };
          result.push(positionWithKeys);
        });
      });
    });
    return result;
  };

  const fetchProducts = async () => {
    // Util for checking if the user is a superadmin.
    function isUserAdmin(): boolean {
      let acceptedAdminRoles = ["admin", "adminreadonly"];
      process.env.REACT_APP_ENV === "DEV" &&
        acceptedAdminRoles.push("devadmin");

      const userIsAdmin =
        user?.roles?.some((el: string) => acceptedAdminRoles.includes(el)) ??
        false;

      return userIsAdmin;
    }

    if (teamId && captureId) {
      try {
        const data = isUserAdmin()
          ? await getAdminCaptureWithDetectedSimScore(captureId, teamId)
          : await getPlanogramCaptureAnnotations(captureId, teamId);

        if (data) {
          setCaptureAnnotation(data);
        }
      } catch (error) {
        // If Plano annotations fails to load, show an appropriate error message.
        // This endpoint will usually fail if the snapshot is not in a state where it is ready for review.
        setShowAnnotationFailedAlert(true);
        console.log(error);
      }
    }
  };

  const handleDeleteCapture = async () => {
    if (captureId === null) return;
    setIsLoading(true);
    try {
      const response = await postAdminDeleteCapture(captureId);
      if (response) {
        setIsLoading(false);

        // Redir to prior plano list page.
        cancelEdit();
      }
      setIsDeleteCaptureDialogOpen(false);
    } catch (error: any) {
      console.log("error", error.response.data);
      setDeleteErrorMessage(error.response.data);
      setIsLoading(false);
    }
  };

  const handleGenerateAccuracyReport = () => {
    const csvContent = generateAccuracyReport(captureAnnotation, beforeOrAfter);
    
    // Create blob and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `accuracy-report-${captureId}.csv`);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Context Controls */}
      <Box gap={2} sx={{ display: "flex" }} justifyContent={"space-between"}>
        <Stack>
          <Typography
            variant="h6"
            sx={{ mb: -1 }}
            color={metadata.taskName ? "default" : "gray"}
          >
            {isLoading && <Skeleton variant="rounded" />}
            {!isLoading && (metadata.taskName ?? "No Linked Task")}
          </Typography>
          <Fade in={!isLoading}>
            <Typography variant="caption">
              <b>{revision?.Name.split(":")[0]}:</b>
              {revision?.Name.split(":")[1]}
            </Typography>
          </Fade>
          <Fade in={!isLoading}>
            <Typography variant="caption">
              <b style={{ textDecoration: "none" }}>Created: </b>
              {metadata.createdDate && formatDisplayDate(metadata.createdDate)}
            </Typography>
          </Fade>
        </Stack>

        <Stack direction="row" gap={2} alignItems={"center"}>
          {/* Delete Snapshot Control */}
          {roleOnTeam === "admin" && (
            <Button
              sx={{ height: "min-content" }}
              variant="contained"
              color="error"
              startIcon={<Delete />}
              onClick={() => setIsDeleteCaptureDialogOpen(true)}
              disabled={
                isLoading ||
                loadingPlanograms ||
                showAnnotationFailedAlert ||
                roleOnTeam !== "admin"
              }
            >
              Delete
            </Button>
          )}
          {/* Download Snapshot Control */}
          {roleOnTeam === "admin" && (
            <Button
              sx={{ height: "min-content" }}
              variant="contained"
              startIcon={<Download />}
              onClick={handleDownload}
              disabled={isLoading || loadingPlanograms}
            >
              Download
            </Button>
          )}
        </Stack>
      </Box>

      {/* Content Container */}
      <Box sx={{ mt: 2 }}>
        {/* Bay Loading Display */}
        <Fade in={isLoading} unmountOnExit>
          <Box>
            {/* Represents Capture Bays and Bay Name */}
            <Skeleton
              variant="rounded"
              height={40}
              width={320}
              sx={{ mb: 2, mt: 4 }}
            />
            <Skeleton
              variant="rounded"
              height={30}
              width={240}
              sx={{ mb: 4 }}
            />

            {/* Represents the 2 content chunks in the bay display. */}
            <Stack direction="row" gap={4}>
              <Skeleton variant="rounded" height={400} width={"50%"} />
              <Skeleton variant="rounded" height={400} width={"50%"} />
            </Stack>
          </Box>
        </Fade>

        {/* Bay Displays */}
        {!isLoading && !showAnnotationFailedAlert && (
          <>
            <Stack direction="row" alignItems={"center"} gap={2}>
              {/* Before / After Controls */}
              {captureAnnotation?.Captures[1] && (
                <LoadingButton
                  loading={loadingPlanograms && beforeOrAfter === 0}
                  disabled={loadingPlanograms}
                  sx={{ mb: 2 }}
                  variant={beforeOrAfter === 0 ? "contained" : "outlined"}
                  size="small"
                  onClick={() => setBeforeOrAfter(0)}
                >
                  Before
                </LoadingButton>
              )}
              {captureAnnotation?.Captures[1] && (
                <LoadingButton
                  loading={loadingPlanograms && beforeOrAfter === 1}
                  sx={{ mb: 2 }}
                  variant={beforeOrAfter === 1 ? "contained" : "outlined"}
                  size="small"
                  disabled={
                    !captureAnnotation?.Captures[1] || loadingPlanograms
                  }
                  onClick={() => setBeforeOrAfter(1)}
                >
                  After
                </LoadingButton>
              )}

              <Chip
                label={
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <b>
                      Bay {cardIndex} / {planograms.length}
                    </b>
                    <IconButton
                      size="small"
                      onClick={() => setCardIndex(cardIndex - 1)}
                      disabled={cardIndex === 1}
                    >
                      <NavigateBeforeRounded />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => setCardIndex(cardIndex + 1)}
                      disabled={cardIndex === planogramCards.length}
                    >
                      <NavigateNextRounded />
                    </IconButton>
                  </Box>
                }
                sx={{ fontSize: 16, mb: 2 }}
              />

              {auth.adminRole && (
                // Admin / Debug Controls
                <>
                  <Button
                    sx={{ mb: 2 }}
                    variant="outlined"
                    startIcon={<BugReport />}
                    onClick={() => setOpenCroppedImageDialog(true)}
                  >
                    <Chip
                      label="Debug"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    Show Image Crops
                  </Button>
                  <Button
                    sx={{ mb: 2 }}
                    variant="outlined"
                    onClick={() => setShowCaptureTooltip(!showCaptureTooltip)}
                    startIcon={
                      showCaptureTooltip ? (
                        <CheckBoxRounded />
                      ) : (
                        <CheckBoxOutlineBlankRounded />
                      )
                    }
                  >
                    <Chip
                      label="Debug"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    Show Hover Tooltips
                  </Button>
                  <Button
                    sx={{ mb: 2 }}
                    variant="outlined"
                    onClick={handleGenerateAccuracyReport}
                    startIcon={<Download />}
                  >
                    <Chip
                      label="Debug"
                      color="warning"
                      size="small"
                      sx={{ mr: 1 }}
                    />
                    Accuracy Report (.csv)
                  </Button>
                </>
              )}
            </Stack>

            {/* Bay Contents */}
            <RevisionContext.Provider
              value={{
                revision: revision,
                skuMap: skuMap,
                taskIndex: beforeOrAfter,
              }}
            >
              <Box sx={{ maxWidth: { xs: "100%", md: `calc(100vw - 292px)` } }}>
                {planogramCards[cardIndex - 1]}
              </Box>
            </RevisionContext.Provider>
          </>
        )}

        {/* Delete Modal */}
        <DeleteCaptureDialog
          handleClose={() => {
            setIsDeleteCaptureDialogOpen(false);
            setDeleteErrorMessage(null);
          }}
          handleDelete={handleDeleteCapture}
          open={isDeleteCaptureDialogOpen}
          captureName={revision?.Name || null}
          captureId={captureId || null}
          loading={isLoading}
          errorMessage={deleteErrorMessage}
        />

        {auth.adminRole && planograms.length > 0 && (
          <CaptureBayItemCroppingDialog
            open={openCroppedImageDialog}
            onClose={() => setOpenCroppedImageDialog(false)}
            captureBayData={captureAnnotation?.Captures[beforeOrAfter].find(
              (bay) => bay.BayIndex === cardIndex - 1
            )}
            imageUrl={`${
              process.env.REACT_APP_URSTORE_CONTENT_PREFIX
            }assets/captures/${encodeURIComponent(
              planograms[cardIndex - 1].captureId
            )}/${encodeURIComponent(planograms[cardIndex - 1].id)}`}
          />
        )}

        {/* Invalid Plano alert. */}
        <Fade in={showAnnotationFailedAlert}>
          <Alert color="error">
            <AlertTitle>Full Capture data not available.</AlertTitle>
            This capture is not ready for review or you do not have permission
            to view it. It cannot be viewed or adjusted. If you are seeing this
            message in error, please contact support.
          </Alert>
        </Fade>
      </Box>
    </Box>
  );
};

export default PlanogramCapturesIndex;
