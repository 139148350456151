import React, { useState, useEffect } from "react";
import { Popover, Box, TextField, Button, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import upsertFixture from "../../../../api/fixtures/upsertFixture";
import { FixtureType } from "../../../../api/fixtures/getFixtures";

interface FixtureFormPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  fixture: Partial<FixtureType> | null; // Should be null when an existing fixture is not being edited.
  onSuccess: () => void;
}

const FixtureFormPopover = (props: FixtureFormPopoverProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>(props.fixture?.Name || "");
  const [type, setType] = useState<string>(props.fixture?.Type || "");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.fixture) {
      setName(props.fixture.Name || "");
      setType(props.fixture.Type || "");
    }
  }, [props.fixture]);

  useEffect(() => {
    if (!props.open) {
      setName("");
      setType("");
    }
  }, [props.open]);

  const handleSubmit = async () => {
    if (!name || !type) {
      enqueueSnackbar("Please fill out both fields.", { variant: "warning" });
      return;
    }

    try {
      setLoading(true);

      let payload: Partial<FixtureType> = {};
      // If editing, set a fixture ID too.
      if (props.fixture?.FixtureTypeId)
        payload.FixtureTypeId = props.fixture.FixtureTypeId;

      payload.Name = name;
      payload.Type = type;

      await upsertFixture(payload);

      enqueueSnackbar("Fixture saved successfully!", { variant: "success" });
      props.onSuccess(); // Trigger a reload or update in the parent component
      props.onClose();
    } catch (error) {
      enqueueSnackbar("Failed to save fixture.", { variant: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Box p={4} sx={{ width: "300px" }}>
        <TextField
          size="small"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          margin="dense"
        />
        <TextField
          size="small"
          label="Type"
          value={type}
          onChange={(e) => setType(e.target.value)}
          fullWidth
          margin="dense"
        />
        <Stack
          direction="row"
          gap={1}
          sx={{ width: "100%", mt: 1 }}
          justifyContent={"flex-end"}
        >
          <Button onClick={props.onClose} color="error" sx={{ mr: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading
              ? "Saving..."
              : props.fixture?.FixtureTypeId
              ? "Save Changes"
              : "Create"}
          </Button>
        </Stack>
      </Box>
    </Popover>
  );
};

export default FixtureFormPopover;
