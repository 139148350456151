import axiosInstance from "../axiosInstanceConfig";

export interface MachineLearningCategory {
  Id: string;
  Name: string;
  CreatedAt: Date;
  LastModified: Date;
}

const getMachineLearningCategories = async () => {
  const response = await axiosInstance.get<MachineLearningCategory[]>(
    `planogram/ListPlanogramCategories`
  );

  return response.data;
};

export default getMachineLearningCategories;
