import axiosInstance from "../axiosInstanceConfig";
import { PlanogramFormData } from "../../features/admin/planograms/AdminPlanogramEditFormikWrapper";

export interface UpdatePlanogramRequest {
  PlanogramId: string;
  Name: string;
  DisplayName?: string | null;
  PlanogramCategoryId?: string | null;
  LayoutName?: string | null;
  LayoutNameId?: string | null;
  State?: string | null;
  Size?: number | null;
  FixtureTypeId?: string | null;
  FixtureHeight?: number | null;
  FixtureDepth?: number | null;
  RightToLeft?: boolean | null;
}

export const convertFormDataToUpdateRequestData = (
  planogramID: string,
  formData: PlanogramFormData
) => {
  const request: UpdatePlanogramRequest = {
    PlanogramId: planogramID,
    Name: formData.Name,
    DisplayName: formData.DisplayName,
    LayoutName: formData.LayoutName,
    LayoutNameId: formData.LayoutNameId,
    PlanogramCategoryId: formData.PlanogramCategoryId,
    FixtureTypeId: formData.FixtureTypeId,
    State: formData.State === "" ? null : formData.State,
    Size: formData.Size ? parseInt(formData.Size) : undefined,
    FixtureDepth: formData.FixtureDepth
      ? parseInt(formData.FixtureDepth)
      : undefined,
    FixtureHeight: formData.FixtureHeight
      ? parseInt(formData.FixtureHeight)
      : undefined,
    RightToLeft: formData.RightToLeft
      ? formData.RightToLeft === "1"
      : undefined,
  };
  return request;
};

// Updates human-readable fields of a planogram, note that this does not trigger a new revision afaik.
// This function will reject non-admin auths, so should only be used in admin contexts.
// Interestingly, this doesn't include the pID as a param, but inside the POST body.
const postAdminUpdatePlanogram = async (
  updatedPlanogramFields: UpdatePlanogramRequest
) => {
  try {
    const response = await axiosInstance.post<string>(
      `planogram/AdminUpdatePlanogram`,
      updatedPlanogramFields
    );

    return response.data;
  } catch (error: any) {
    throw new Error(
      "There was an error updating planogram fields. Please contact support."
    );
  }
};

export default postAdminUpdatePlanogram;
