import axiosInstance from "../axiosInstanceConfig";

export interface Task {
  TaskId: string;
  JobId: string | null;
  TaskTypeId: string;
  PlanogramRevisionId: string;
  Name: string;
  EndDate: string;
  StartDate: string;
  FirstCaptureInstruction: string;
  SecondCaptureInstruction: string;
  MerchandiseInstruction: string;
}

const getTasks = async (_teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", _teamId);

  const response = await axiosInstance.get<Task[]>(
    `task/TaskList?${params.toString()}`
  );

  return response.data;
};

export default getTasks;
