import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Chip,
  Fade,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import getAssignmentsByStore, {
  AssignmentByStore,
} from "../../../../api/assignments/GetAssignmentsByStore";
import { enqueueSnackbar } from "notistack";
import { useTeamStore } from "../../../../stores/teams/TeamStore";

interface PossibleAssignmentPlanogram {
  Name: string;
  DisplayName: string | null;
  LatestRevisionName: string | null;
  id: string;
  LatestRevisionId: string;
  CurrentRevisionId: string;
  StoreId: string;
}

interface ResolverActionProps {
  planograms: PossibleAssignmentPlanogram[];
  storeId: string;
  loading: boolean;
  mode: "assign" | "update";
  onAssign: (
    _revisionId: string,
    _storeId: string,
    _hideResponse: boolean
  ) => Promise<void>;
}

const ResolverActions: React.FC<ResolverActionProps> = (props) => {
  // Util & Constants
  const { selectedTeam } = useTeamStore();
  const filteredPlanograms = props.planograms.filter(
    (p) =>
      p.LatestRevisionId !== null && p.LatestRevisionId !== p.CurrentRevisionId
  );

  // State
  const [selectedPlanogram, setSelectedPlanogram] =
    useState<PossibleAssignmentPlanogram | null>(filteredPlanograms[0] ?? null);
  const [openDialog, setOpenDialog] = useState(false);
  const [compatibleStores, setCompatibleStores] = useState<AssignmentByStore[]>(
    []
  );
  const [selectedStores, setSelectedStores] = useState<Set<string>>(new Set());
  const [isAssigning, setIsAssigning] = useState(false);
  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);

  // Effects
  useEffect(() => {
    if (selectedPlanogram && openDialog) {
      fetchAssignments();
    }
  }, [selectedPlanogram, openDialog]);

  // Functions
  const fetchAssignments = async () => {
    setIsLoadingAssignments(true);
    try {
      const data = await getAssignmentsByStore(selectedTeam);
      const filteredData = data.filter((assignment) => {
        const planograms = assignment.PlanogramCategory.Planograms;
        // Filter out assignments that have only one planogram and that planogram has the same current and latest revision ID
        return !(
          planograms.length === 1 &&
          planograms[0].CurrentRevisionId === planograms[0].LatestRevisionId
        );
      });

      // Filter stores to only show options where the current selected planogram is present
      const filteredStores = filteredData.filter((store) =>
        store.PlanogramCategory.Planograms.some(
          (planogram) =>
            planogram.LatestRevisionId === selectedPlanogram?.LatestRevisionId
        )
      );

      setCompatibleStores(filteredStores);
      setSelectedStores(new Set(filteredStores.map((store) => store.StoreId)));
    } catch {
      enqueueSnackbar("Error occurred when fetching Assignment data.", {
        variant: "error",
      });
    } finally {
      setIsLoadingAssignments(false);
    }
  };

  const openBulkActionDialog = () => {
    if (selectedPlanogram) {
      setOpenDialog(true);
    }
  };

  // Render
  return (
    <Stack direction="column" sx={{ width: "100%" }}>
      <Stack
        direction="row"
        gap={2}
        alignItems={"center"}
        sx={{ width: "100%" }}
      >
        {/* Planogram Autocomplete */}
        {props.mode === "assign" && (
          <Autocomplete
            value={selectedPlanogram}
            size="small"
            fullWidth
            options={filteredPlanograms || []}
            getOptionLabel={(option) => `${option.Name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Planogram"
                variant="outlined"
                size="small"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Stack direction={"column"}>
                  <Typography>{option.Name}</Typography>
                  <Typography variant="caption" sx={{ fontStyle: "italic" }}>
                    {option.DisplayName}
                  </Typography>
                </Stack>
              </li>
            )}
            onChange={(event, value) => {
              setSelectedPlanogram(value);
            }}
          />
        )}

        {/* Assign Button */}
        {props.mode === "assign" && (
          <LoadingButton
            sx={{ height: "40px" }}
            variant="contained"
            loading={props.loading}
            disabled={selectedPlanogram === null}
            onClick={() => {
              props.onAssign(
                selectedPlanogram?.LatestRevisionId ?? "",
                props.storeId,
                false
              );
            }}
          >
            Assign
          </LoadingButton>
        )}

        {/* Update Button */}
        {props.mode === "update" && (
          <LoadingButton
            sx={{ height: "40px" }}
            variant="contained"
            loading={props.loading}
            onClick={() => {
              props.onAssign(
                selectedPlanogram?.LatestRevisionId ?? "",
                props.storeId,
                false
              );
            }}
          >
            Update
          </LoadingButton>
        )}

        {/* Bulk Action Button */}
        <Button
          sx={{ height: "40px" }}
          onClick={openBulkActionDialog}
          disabled={selectedPlanogram === null}
        >
          Bulk...
        </Button>
      </Stack>

      {/* Bulk Action Dialog */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          <Stack direction="column">
            <Typography variant="h6">
              Bulk {props.mode === "assign" ? "Assignment" : "Update"}
            </Typography>
            <Typography variant="caption">{selectedPlanogram?.Name}</Typography>
          </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
          {isLoadingAssignments ? (
            <Stack alignItems="center" justifyContent="center" height={300}>
              <CircularProgress />
            </Stack>
          ) : (
            <Fade in={compatibleStores.length > 0}>
              <List sx={{ height: "300px", overflow: "auto" }}>
                {compatibleStores.map((store, i) => (
                  <ListItem key={i} dense>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedStores.has(store.StoreId)}
                          onChange={(e) => {
                            const newSelectedStores = new Set(selectedStores);
                            if (e.target.checked) {
                              newSelectedStores.add(store.StoreId);
                            } else {
                              newSelectedStores.delete(store.StoreId);
                            }
                            setSelectedStores(newSelectedStores);
                          }}
                        />
                      }
                      label={
                        <ListItemText
                          primary={store.Name}
                          secondary={store.City}
                        />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Fade>
          )}
        </DialogContent>
        <DialogActions sx={{ width: "100%" }}>
          <Fade in={compatibleStores.length > 0}>
            <Chip
              label={`${selectedStores.size} / ${compatibleStores.length} Eligible Store(s)`}
              style={{ fontWeight: "bold", marginLeft: "auto" }}
            />
          </Fade>
          {/* This is a cute bit of css to basically float stuff above it left, and stuff below right. */}
          <div style={{ flex: "1 1 0" }} />
          <Button color="error" onClick={() => setOpenDialog(false)}>
            Cancel
          </Button>
          <LoadingButton
            disabled={
              selectedStores.size === 0 || isAssigning || isLoadingAssignments
            }
            variant="contained"
            color="primary"
            loading={isAssigning}
            onClick={async () => {
              if (selectedPlanogram) {
                setIsAssigning(true);
                try {
                  const assignmentPromises = compatibleStores
                    .filter((store) => selectedStores.has(store.StoreId))
                    .map((store) =>
                      props.onAssign(
                        selectedPlanogram.LatestRevisionId,
                        store.StoreId,
                        true
                      )
                    );

                  const results = await Promise.allSettled(assignmentPromises);

                  // Count the number of successful and failed assignments.
                  const successCount = results.filter(
                    (result) => result.status === "fulfilled"
                  ).length;
                  const failureCount = results.length - successCount;

                  // Display a snackbar conditionally based on the results.
                  if (failureCount === 0) {
                    enqueueSnackbar("Success", {
                      variant: "success",
                      cta:
                        props.mode === "assign"
                          ? "All assignments completed successfully."
                          : "All updates completed successfully.",
                    });
                  } else if (successCount === 0) {
                    enqueueSnackbar("Error", {
                      variant: "error",
                      cta:
                        props.mode === "assign"
                          ? "All assignments failed. Please contact support."
                          : "All updates failed. Please contact support.",
                    });
                  } else {
                    enqueueSnackbar("Partial Success", {
                      variant: "warning",
                      cta: `${
                        props.mode === "assign"
                          ? "Partial success: "
                          : "Partial update: "
                      }${successCount} assigned, ${failureCount} failed.`,
                    });
                  }
                } catch (error) {
                  console.error("Error during bulk assignment:", error);
                  enqueueSnackbar("An error occurred during bulk assignment", {
                    variant: "error",
                  });
                } finally {
                  setIsAssigning(false);
                  setOpenDialog(false);
                }
              }
            }}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ResolverActions;
