import { Button, Divider, Grid, Menu, MenuItem, Stack } from "@mui/material";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../../features/authentication/contexts/AuthContext";
import { ChangePasswordDialog } from "../../../features/authentication/components/ChangePasswordDialog";
import { ArrowForwardRounded, Person } from "@mui/icons-material";
import TeamSelector from "../../../components/TeamSelector";
import UserProfileDialog from "../../../features/authentication/components/UserProfileDialog";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import { lightBlue, purple } from "@mui/material/colors";
import { iconLookup } from "../../../stores/layout/LayoutStoreTypes";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { VERSION } from "../../../version";

// Subroute titles longer than this will be ellipsis-truncated.
const SUBROUTE_STR_LIMIT = 50;

function Header() {
  // Util
  const auth = useAuthContext();
  const navigate = useNavigate();

  // Store
  const { routeMetadata, subRoute } = useLayoutStore();
  const { setTeams, setSelectedTeam } = useTeamStore();

  // State
  const [userMenuEl, setUserMenuEl] = useState<null | HTMLElement>(null);
  const [changePassDialogOpen, setChangePassDialogOpen] =
    useState<boolean>(false);
  const [userProfileDialogOpen, setUserProfileDialogOpen] =
    useState<boolean>(false);

  function openUserMenu(event: any) {
    setUserMenuEl(event.currentTarget);
  }
  function closeUserMenu() {
    setUserMenuEl(null);
  }

  return (
    <Grid
      container
      justifyContent={"space-between"}
      p={4}
      alignItems={"center"}
      sx={{
        position: "sticky",
        top: 0,
        mb: -4,
      }}
    >
      {/* Route Title */}
      <Grid item>
        {routeMetadata && (
          <Button
            variant="contained"
            sx={{
              transition: "all 0.5s ease-in-out",
              backgroundColor: "var(--brand-tertiary)",
              ":hover": { backgroundColor: "var(--brand-tertiary)" },
              cursor: "pointer",
              "& .MuiSvgIcon-root": {
                color: routeMetadata.admin ? purple[300] : lightBlue[400], // Example color
              },
            }}
            startIcon={
              iconLookup[routeMetadata.iconTag as keyof typeof iconLookup]
            }
            onClick={() => navigate(routeMetadata.path ?? "")}
          >
            {routeMetadata.label}

            {subRoute && <ArrowForwardRounded sx={{ mx: 2 }} />}

            {subRoute
              ? subRoute.title.length > SUBROUTE_STR_LIMIT
                ? `${subRoute.title.slice(0, SUBROUTE_STR_LIMIT)}...`
                : subRoute.title
              : null}
          </Button>
        )}
      </Grid>

      {/* Right-float items. */}
      <Grid item>
        <Stack direction="row" spacing={2}>
          {/* Team Selector */}
          <TeamSelector />

          <Divider variant="middle" orientation="vertical" flexItem />

          {/* Pword change modal (position-agnostic) */}
          <ChangePasswordDialog
            open={changePassDialogOpen}
            handleClose={() => setChangePassDialogOpen(false)}
          />

          <UserProfileDialog
            open={userProfileDialogOpen}
            onClose={() => setUserProfileDialogOpen(false)}
          />

          <Button startIcon={<Person />} onClick={openUserMenu}>
            {auth.user}
          </Button>

          <Menu
            open={Boolean(userMenuEl)}
            onClose={closeUserMenu}
            anchorEl={userMenuEl}
          >
            {/* Update Profile (Currently only Fullname is changeable) */}
            <MenuItem
              onClick={() => {
                setUserProfileDialogOpen(!changePassDialogOpen);
                closeUserMenu();
              }}
            >
              Update Profile
            </MenuItem>

            {/* Change Password */}
            <MenuItem
              onClick={() => {
                setChangePassDialogOpen(!changePassDialogOpen);
                closeUserMenu();
              }}
            >
              Change Password
            </MenuItem>

            <Divider />

            {/* Sign Out */}
            <MenuItem
              sx={{ color: "royalblue", fontWeight: 500 }}
              onClick={() => {
                // Purge cached teams when signing out.
                setTeams([]);
                // Sign out user.
                auth.signout(() => navigate("/"));
                closeUserMenu();
              }}
            >
              Sign Out
            </MenuItem>
            <Divider />
            <MenuItem disabled sx={{ fontSize: '0.8rem' }}>
              {VERSION} / {process.env.REACT_APP_ENV?.toLowerCase()}
            </MenuItem>
          </Menu>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default memo(Header);
