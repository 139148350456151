import { create } from "zustand";

type PlanogramFilterState = {
  bayCount: number[] | null;
  category: string[] | null;
  subcategory: string[] | null;
  manufacturer: string[] | null;
  brand: string[] | null;
  upc: string[] | null;
  fixtureType: string[] | null;
};

type PlanogramFilterActions = {
  setBayCount: (_arg0: number[] | null) => void;
  setCategory: (_arg0: string[] | null) => void;
  setSubCategory: (_arg0: string[]) => void;
  setManufacturer: (_arg0: string[]) => void;
  setBrand: (_arg0: string[]) => void;
  setUpc: (_arg0: string[]) => void;
  setFixtureType: (_arg0: string[]) => void;
  resetPlanogramFilter: () => void;
  resetCategory: () => void;
  resetBayCount: () => void;
  resetFixtureType: () => void;
};

const initialPlanogramState: PlanogramFilterState = {
  bayCount: null,
  category: null,
  subcategory: null,
  manufacturer: null,
  brand: null,
  upc: null,
  fixtureType: null,
};

export const usePlanogramFilterStore = create<
  PlanogramFilterState & PlanogramFilterActions
>((set) => ({
  ...initialPlanogramState,
  setBayCount: (newBayCount: number[] | null) => set(() => ({ bayCount: newBayCount })),
  setCategory: (newCategory: string[] | null) => set(() => ({ category: newCategory })),
  setSubCategory: (newSubcategory: string[]) =>
    set(() => ({ subcategory: newSubcategory })),
  setManufacturer: (newManufacturer: string[]) =>
    set(() => ({ manufacturer: newManufacturer })),
  setBrand: (newBrand: string[]) => set(() => ({ brand: newBrand })),
  setUpc: (newUpc: string[]) => set(() => ({ upc: newUpc })),
  setFixtureType: (newFixtureType: string[]) => set(() => ({ fixtureType: newFixtureType })),
  resetPlanogramFilter: () => set(() => ({ ...initialPlanogramState })),
  resetCategory: () => set(() => ({ category: null, subcategory: null, manufacturer: null, brand: null})),
  resetBayCount: () => set(() => ({ bayCount: null })),
  resetFixtureType: () => set(() => ({ fixtureType: null })),
}));
