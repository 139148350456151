import axiosInstance from "../axiosInstanceConfig";

export interface UserAllTeams {
  UserId: string;
  Email: string;
  Fullname: string | null;
  Teams: {
    TeamId: string;
    TeamName: string | null;
    Role: string;
    Active: boolean;
  }[];
  AppVersion: {
    AppVersion: string;
    DeviceName: string;
    DeviceId: string;
    BuildNumber: string;
    LastDateUpdated: Date;
  }[];
}

// Admin-locked endpoint that gets all users across all teams.
const getAdminAllUsers = async () => {
  const response = await axiosInstance.get<UserAllTeams[]>(
    `user/AdminAllUsers`
  );

  return response.data;
};

export default getAdminAllUsers;
