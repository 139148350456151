import React, { useState } from "react";
import { TextField, MenuItem, CircularProgress } from "@mui/material";
import { RevisionSides } from "../RevisionSideUtils";
import { urStorePalette } from "../../../../themes/urStoreTheme";

interface ProductSideSelectorProps {
  initialValue: number;
  onSideChange: (_newSide: number) => Promise<void>;
}

const ProductSideSelector: React.FC<ProductSideSelectorProps> = ({
  initialValue,
  onSideChange,
}) => {
  const [productSide, setProductSide] = useState<number>(initialValue);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = Number(event.target.value);
    setProductSide(newValue);
    setIsLoading(true);
    try {
      await onSideChange(newValue);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <TextField
      select
      fullWidth
      label="Product Side"
      value={productSide}
      onChange={handleChange}
      sx={{
        backgroundColor: urStorePalette.greys.lightest,
      }}
      size="small"
      disabled={isLoading}
      InputProps={{
        // Needs manual restyling because MUI doesn't support this type of adornment very well.
        endAdornment: isLoading && (
          <CircularProgress
            color="inherit"
            size={"20px"}
            sx={{ position: "absolute", right: 9 }}
          />
        ),
      }}
    >
      {Object.values(RevisionSides).map((rs, i) => (
        <MenuItem key={i} value={rs.value}>
          {`${rs.label} (${rs.mask ?? rs.value})`}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ProductSideSelector;
