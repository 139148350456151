import axiosInstance from "../axiosInstanceConfig";
import { PlanogramFormData } from "../../features/admin/planograms/AdminPlanogramEditFormikWrapper";

export interface CreatePlanogramRequest {
  Name: string;
  DisplayName: string | null;
  PlanogramCategoryId: string | null;
  
  LayoutNameId: string | null;
  State: string | null;
  Size: number | null;
  FixtureTypeId: string | null;
  FixtureHeight: number | null;
  FixtureDepth: number | null;
  RightToLeft: boolean | null;
}

export const convertFormDataToCreateRequestData = (
  formData: PlanogramFormData
) => {
  const request: CreatePlanogramRequest = {
    Name: formData.Name,
    DisplayName: formData.DisplayName ?? null,
    LayoutNameId: formData.LayoutNameId ?? null,
    PlanogramCategoryId: formData.PlanogramCategoryId ?? null,
    FixtureTypeId: formData.FixtureTypeId ?? null,
    State: formData.State === "" ? null : formData.State ?? null,
    Size: formData.Size ? parseInt(formData.Size) : null,
    FixtureDepth: formData.FixtureDepth
      ? parseInt(formData.FixtureDepth)
      : null,
    FixtureHeight: formData.FixtureHeight
      ? parseInt(formData.FixtureHeight)
      : null,
    RightToLeft: formData.RightToLeft ? formData.RightToLeft === "1" : null,
  };
  return request;
};

const postAdminCreatePlanogram = async (request: CreatePlanogramRequest) => {
  const response = await axiosInstance.post<string>(
    `planogram/AdminCreatePlanogram`,
    request
  );
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(
      "There was an error creating the planogram. Please contact support."
    );
  }
};

export default postAdminCreatePlanogram;
