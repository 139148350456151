import { CaptureMLModel } from "../../../models/planograms/ProductComparisonTypes";
import axiosInstance from "../../axiosInstanceConfig";

export const postNewProductTrainingModel = async (
  data: CaptureMLModel,
  taskIndex: 0 | 1
) => {
  const formData = new FormData();

  function appendFormData(formData: FormData, data: any) {
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        formData.append(key, data[key]);
      }
    }
  }

//  TODO: Implement product class features by sending the selected value here.
//  /** TODO: This will be updated in a future change to accept non-default values. */
//  // These values are consistent across environments, this ID represents "Standard".
//  formData.append("productClassId", "4b4b346f-592f-4a37-aed5-96883f26ea33");
  
  formData.append("taskIndex", taskIndex.toString());

  appendFormData(formData, data);

  const response = await axiosInstance.post(
    `trainingImage/AdminSetTrainingImageForCapture`,
    formData
  );

  if (response.status === 200) return response.data;
  else {
    throw new Error(
      "There was an error updating the training data. Please contact support."
    );
  }
};
