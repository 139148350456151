import React from "react";
import { usePlanogramFilterStore } from "../../../../../stores/metrics/PlanogramFilterStore";
import ClassIcon from "@mui/icons-material/Class";
import FilterHeaderValue from "../../FIlterHeaderValue";
import DashboardBoxContainer from "../../DashboardBoxContainer";
import { East } from "@mui/icons-material";
import { urStorePalette } from "../../../../../themes/urStoreTheme";

interface CategoryFilterProps {
  handleFilterClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  active: boolean;
}

const CategoryFilter = (props: CategoryFilterProps) => {
  const { category, subcategory, manufacturer, brand } =
    usePlanogramFilterStore();

  const renderFilterValues = (
    values: string | string[] | null,
    active: boolean,
    pluralLabel: string
  ) => {
    if (!values) return null;

    const valueArray = Array.isArray(values) ? values : [values];

    return valueArray.map((value, index) => (
      <React.Fragment key={value}>
        {valueArray.length === 1 ? (
          <FilterHeaderValue value={value} active={active} />
        ) : (
          <>
            {index === 0 && (
              <FilterHeaderValue
                value={`${valueArray.length} ${pluralLabel}`}
                active={active}
              />
            )}
          </>
        )}
      </React.Fragment>
    ));
  };

  return (
    <DashboardBoxContainer
      onClick={(e) => props.handleFilterClick(e)}
      active={props.active}
    >
      <ClassIcon
        sx={{
          color: props.active
            ? urStorePalette.greys.light
            : urStorePalette.greys.default,
        }}
      />
      {renderFilterValues(
        category && category?.length > 0 ? category : "All Products",
        props.active,
        "Categories"
      )}

      {subcategory && subcategory.length > 0 && (
        <>
          <East
            color="disabled"
            sx={{
              color: props.active
                ? urStorePalette.greys.light
                : urStorePalette.greys.default,
            }}
          />{" "}
          {renderFilterValues(subcategory, props.active, "Sub Categories")}
        </>
      )}

      {manufacturer && manufacturer.length > 0 && (
        <>
          <East
            color="disabled"
            sx={{
              color: props.active
                ? urStorePalette.greys.light
                : urStorePalette.greys.default,
            }}
          />{" "}
          {renderFilterValues(manufacturer, props.active, "Manufacturers")}
        </>
      )}

      {brand && brand.length > 0 && (
        <>
          <East
            color="disabled"
            sx={{
              color: props.active
                ? urStorePalette.greys.light
                : urStorePalette.greys.default,
            }}
          />{" "}
          {renderFilterValues(brand, props.active, "Brands")}
        </>
      )}
    </DashboardBoxContainer>
  );
};

export default CategoryFilter;
