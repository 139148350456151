import axiosInstance from "../axiosInstanceConfig";

const postCreateCategory = async (categoryName: string) => {
  const response = await axiosInstance.post(
    `planogram/AdminCreatePlanogramCategory`,
    { Name: categoryName }
  );

  return response.data;
};

export default postCreateCategory;
