import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem } from "@mui/material";
import {
  MoreVert,
  FileUploadOutlined,
  FileDownloadOutlined,
  DeleteOutline,
} from "@mui/icons-material";
import exportCaptureDataByCaptureId from "../../../api/planograms/exportCaptureDataByCaptureId";
import useCSVDownloader from "../../../helpers/hooks/useCsvDownloader";
import { useTeamStore } from "../../../stores/teams/TeamStore";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import postSoftDeleteCapture from "../../../api/capture/postSoftDeleteCapture";
import { enqueueSnackbar } from "notistack";

const ActionsMenu = ({
  rowData,
  handlePlanogramSelected,
  fetchPlanograms,
}: any) => {
  // Util
  const { roleOnTeam, selectedTeam } = useTeamStore();
  const { downloadCSV } = useCSVDownloader();

  // State
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // Const
  const open = Boolean(anchorEl);

  // Functions
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExport = async () => {
    const captureData = await exportCaptureDataByCaptureId(rowData.CaptureId);
    if (captureData) {
      downloadCSV({
        csvData: captureData,
        type: "planogram",
        id: rowData.CaptureId,
      });
    }
    handleClose();
  };

  const handleDownload = () => {
    window.open(
      `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}captures/${rowData.CaptureId}`,
      "_blank"
    );
    handleClose();
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
    handleClose();
  };

  const handleDeleteConfirm = async () => {
    setIsDeleting(true);
    try {
      await postSoftDeleteCapture(rowData.CaptureId, selectedTeam);
      enqueueSnackbar("Capture deleted successfully", { variant: "success" });
      setDeleteDialogOpen(false);
      fetchPlanograms();
    } catch {
      enqueueSnackbar("Failed to delete capture", { variant: "error" });
    } finally {
      setIsDeleting(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  return (
    <>
      <Button
        onClick={() => {
          handlePlanogramSelected(rowData.CaptureId);
          handleClose();
        }}
        variant="contained"
        size="small"
        disabled={rowData.Status !== "complete"}
      >
        Open
      </Button>
      <Box>
        <IconButton
          aria-controls={open ? "actions-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "actions-button",
          }}
        >
          <MenuItem
            onClick={handleExport}
            disabled={rowData.Status !== "complete"}
          >
            <FileUploadOutlined sx={{ mr: 1 }} />
            Capture Data (.csv)
          </MenuItem>
          <MenuItem onClick={handleDownload}>
            <FileDownloadOutlined sx={{ mr: 1 }} />
            Download
          </MenuItem>
          <MenuItem
            onClick={handleDeleteClick}
            disabled={rowData.Status !== "pending" || roleOnTeam !== "admin"}
            sx={{ color: "error.main" }}
          >
            <DeleteOutline sx={{ mr: 1, color: "inherit" }} />
            Delete
          </MenuItem>
        </Menu>
      </Box>

      <ConfirmationDialog
        open={deleteDialogOpen}
        title="Confirm Delete"
        content="Are you sure you want to delete this capture? This action cannot be undone."
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        confirmText="Delete"
        isConfirming={isDeleting}
      />
    </>
  );
};

export default ActionsMenu;
