import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import getAllStores from "../../../api/stores/getAllStores";
import { useSnackbar } from "notistack";
import { StoreData } from "../../stores/types/StoreResponseData";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { TeamMemberStore } from "../types/TeamMemberStore";
import { LoadingButton } from "@mui/lab";
import { AddCircleOutline, CheckOutlined } from "@mui/icons-material";

interface AssignStoreToUserModalProps {
  open: boolean;
  handleClose: () => void;
  selectedTeam: string;
  userId: string;
  handleAssignStore: (storeId: string) => void;
  teamMemberStores: TeamMemberStore[];
  selectedId: string;
  loading: boolean;
}

const AssignStoreToUserModal = (props: AssignStoreToUserModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [stores, setStores] = React.useState<StoreData[]>([]);
  const [assignedStoreIds, setAssignedStoreIds] = React.useState<string[]>([]);

  useEffect(() => {
    const fetchAllStores = async () => {
      try {
        const data = await getAllStores(props.selectedTeam);
        if (data) {
          console.log(data);
          setStores(data);
        }
      } catch (error: any) {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: `Failed to fetch stores.`,
        });
        console.log(error.message);
      }
    };
    fetchAllStores();
  }, []);

  useEffect(() => {
    if (props.teamMemberStores) {
      setAssignedStoreIds(props.teamMemberStores.map((store) => store.StoreId));
    }
  }, [props.teamMemberStores]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={props.handleClose}
      open={props.open}
    >
      <DialogTitle>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          Assign Store to User
        </Stack>
      </DialogTitle>
      <DialogContent>
        <AgGridWrapper
          height="450px"
          id="store-assign-grid"
          quickSearch
          columnDefs={[
            { headerName: "Store Name", field: "Name", flex: 1 },
            { headerName: "Store Code", field: "StoreCode", flex: 1 },
            {
              headerName: "Actions",
              field: "StoreId",
              cellStyle: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              cellRenderer: (row: any) => {
                return assignedStoreIds.includes(row.data.StoreId) ? (
                  <Stack direction="row" gap={2} alignItems={"center"}>
                    <CheckOutlined color="success" fontSize={"small"} />
                    <Typography
                      display="flex"
                      alignContent="center"
                      justifyContent="center"
                      variant="body2"
                      fontWeight={500}
                    >
                      Assigned
                    </Typography>
                  </Stack>
                ) : (
                  <LoadingButton
                    loading={
                      props.selectedId === row.data.StoreId && props.loading
                    }
                    sx={{ margin: "0 auto" }}
                    variant="contained"
                    size="small"
                    startIcon={<AddCircleOutline />}
                    onClick={() => {
                      props.handleAssignStore(row.data.StoreId);
                    }}
                    disabled={assignedStoreIds.includes(row.data.StoreId)}
                  >
                    Assign
                  </LoadingButton>
                );
              },
            },
          ]}
          rowData={stores}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => props.handleClose()}
          color="primary"
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignStoreToUserModal;
