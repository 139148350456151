import axiosInstance from "../axiosInstanceConfig";

export interface AssignmentByStore {
  Name: string;
  City: string;
  BayCount: number;
  StoreId: string;
  PlanogramCategory: PlanogramCategory;
}

// Define the structure of a PlanogramCategory
export interface PlanogramCategory {
  PlanogramCategoryId: string;
  Name: string;
  Planograms: Planogram[];
}

// DO NOT EXPORT THIS. It will be confusing due to multiple "planogram" types.
interface Planogram {
  PlanogramId: string;
  Name: string;
  DisplayName: string;
  LayoutName: string;
  State: string;
  Size: number;
  FixtureType: string;
  FixtureName: string;
  FixtureHeight: number;
  FixtureDepth: number;

  CurrentRevisionId: string,
  CurrentRevisionName: string | null,
  LatestRevisionId: string,
  LatestRevisionName: string
}

const getAssignmentsByStore = async (teamId: string) => {
  const params = new URLSearchParams();

  params.append("teamId", teamId);

  const response = await axiosInstance.get<AssignmentByStore[]>(
    `storecategory/AdminStoreCategoryPlanogramList?${params.toString()}`
  );

  return response.data;
};

export default getAssignmentsByStore;
